import React, { useState, useEffect } from "react";
import Card from "@mui/material/Card";

// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";

// Soft UI Dashboard PRO React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import InvoicesApi from "api/invoices";
import { CircularProgress, Pagination, Stack } from "@mui/material";
import DataTable from "examples/Tables/DataTable";
import ActionCell from "../components/ActionCell";
import { useTranslation } from "react-i18next";

function InvoiceStatus() {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(true);
  const [invoices, setInvoices] = useState([]);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);

  useEffect(() => {
    const token = localStorage.getItem("token");
    if (!token) {
      console.error("No token found in localStorage.");
      return;
    }

    setLoading(true);
    InvoicesApi.getInvoices(token, page)
      .then((response) => {
        setInvoices(response.data.results);
        setTotalPages(Math.ceil(response.data.count / 10));
      })
      .catch((error) => {
        console.error("Error fetching products:", error);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [page]);

  const handlePageChange = (event, value) => {
    setPage(value);
  };

  const columns = [
    { Header: `${t("Numéro de facture")}`, accessor: "number" },
    { Header: `${t("Client")}`, accessor: "client" },
    { Header: `${t("Date de création")}`, accessor: "date_created" },
    // { Header: "Commandes", accessor: "orders" },
    { Header: t("action"), accessor: "action" },
  ];

  const rows = invoices.map((invoice) => ({
    id: invoice.id,
    number: invoice.number,
    client: invoice.client_name,
    date_created: new Date(invoice.date_created).toLocaleString(),
    // orders: invoice.orders.join(", "),
    action: <ActionCell id={invoice.id} />,
  }));

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <SoftBox
        display="flex"
        flexDirection="column"
        justifyContent="space-between"
        minHeight="85vh"
      >
        <SoftBox my={3}>
          <Card>
            {loading ? (
              <SoftBox
                display="flex"
                justifyContent="center"
                alignItems="center"
                p={35}
              >
                <CircularProgress sx={{ color: "#1F51FF" }} />
              </SoftBox>
            ) : (
              <>
                <DataTable
                  table={{ columns, rows }}
                  entriesPerPage={false}
                  columnMinWidth={70}
                />
                {totalPages > 1 && (
                  <Stack
                    spacing={2}
                    sx={{
                      mt: 2,
                      position: "absolute",
                      bottom: 7,
                      left: 100,
                      transform: "translateX(-50%)",
                    }}
                  >
                    <Pagination
                      count={totalPages}
                      page={page}
                      onChange={handlePageChange}
                      color="secondary"
                    />
                  </Stack>
                )}
              </>
            )}
          </Card>
        </SoftBox>
        <Footer />
      </SoftBox>
    </DashboardLayout>
  );
}

export default InvoiceStatus;
