import React, { useState } from "react";
import Icon from "@mui/material/Icon";
import Tooltip from "@mui/material/Tooltip";
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import TextField from "@mui/material/TextField";
import CircularProgress from "@mui/material/CircularProgress";
import OrdersApi from "api/orders";
import SoftButton from "components/SoftButton";
import { useTranslation } from "react-i18next";

function PaymentCell({ orderId, disabled }) {
  const [open, setOpen] = useState(false);
  const [amount, setAmount] = useState("");
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setAmount("");
  };

  const handleSave = () => {
    const token = localStorage.getItem("token");

    if (!token) {
      console.error("No token found in localStorage.");
      return;
    }

    const numericAmount = Number(amount);

    if (isNaN(numericAmount) || numericAmount <= 0) {
      console.error("Invalid amount value.");
      return;
    }

    setLoading(true);
    OrdersApi.Payment(orderId, { amount: numericAmount }, token)
      .then((response) => {
        console.log("Payment successful:", response);
        // Optionally, you could refetch payments here to update the state
      })
      .catch((error) => {
        console.error("Payment failed:", error);
      })
      .finally(() => {
        setLoading(false);
        setOpen(false);
        setAmount("");
      });
  };

  return (
    <SoftBox display="flex" alignItems="center">
      <SoftTypography
        variant="body1"
        color="grey-400"
        sx={{ cursor: "pointer", lineHeight: 0, marginRight: 2 }}
      ></SoftTypography>
      <SoftTypography
        variant="body1"
        color="grey-400"
        sx={{ cursor: "pointer", lineHeight: 0 }}
      >
        <Tooltip
          title={
            disabled
              ? t("Cette commande est entièrement payée")
              : t("Définir le montant du paiement")
          }
          placement="top"
        >
          <span>
            <Icon
              onClick={disabled ? undefined : handleClickOpen}
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                width: "40px",
                height: "40px",
                borderRadius: "50%",
                transition: "background-color 0.3s ease",
                cursor: disabled ? "not-allowed" : "pointer",
                opacity: disabled ? 0.5 : 1,
                "&:hover": !disabled && {
                  backgroundColor: "rgba(0, 0, 0, 0.1)",
                },
              }}
            >
              payment
            </Icon>
          </span>
        </Tooltip>
      </SoftTypography>

      <Dialog open={open} onClose={handleClose} sx={{ padding: 5 }}>
        <DialogTitle sx={{ paddingX: 5 }}>
          {t("Définir le montant du paiement")}
        </DialogTitle>
        <DialogContent sx={{ paddingX: 5 }}>
          <SoftTypography>{t("Montant")}</SoftTypography>

          <TextField
            autoFocus
            margin="dense"
            id="amount"
            type="number"
            fullWidth
            value={amount}
            onChange={(e) => setAmount(e.target.value)}
          />
        </DialogContent>
        <DialogActions sx={{ paddingX: 5 }}>
          <SoftButton onClick={handleClose} disabled={loading} color="info">
            {t("Annuler")}
          </SoftButton>
          <SoftButton
            onClick={handleSave}
            disabled={loading}
            color="info"
            endIcon={
              loading ? <CircularProgress size={20} color="inherit" /> : null
            }
          >
            {loading ? `${t("Enregistrement")}...` : `${t("Enregistrer")}`}
          </SoftButton>
        </DialogActions>
      </Dialog>
    </SoftBox>
  );
}

export default PaymentCell;
