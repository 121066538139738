import { initializeApp } from "firebase/app";
import { getStorage } from "firebase/storage";

const firebaseConfig = {
  apiKey: "AIzaSyAxoxrh8_cGktOQI8kXk_FJBrPfLQ878Fs",
  authDomain: "kamioun-421517.firebaseapp.com",
  projectId: "kamioun-421517",
  storageBucket: "products-dev-bucket",
  appId: "1:965495490819:android:f77d3d2177816a7f054136",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Initialize Firebase Storage
const storage = getStorage(app);

export { storage };
