import axios from "./index";

// CompanyApi class handles fetching and updating company information
class CompanyApi {
  /**
   * Fetches the company information by sending a GET request.
   * The request includes an Authorization header with the Bearer token.
   * @param {string} token - The authentication token for the user.
   * @returns {Promise} - The response containing the company information.
   */
  static GetCompanyInfos = (token) => {
    return axios.get(`${base}/company_infos`, {
      headers: { Authorization: `Bearer ${token}` },
    });
  };

  /**
   * Updates the company information by sending a PUT request.
   * The request includes an Authorization header with the Bearer token.
   * @param {string} token - The authentication token for the user.
   * @param {string|number} id - The ID of the company to be updated.
   * @param {Object} data - The updated company information.
   * @returns {Promise} - The response after updating the company information.
   */
  static UpdateCompanyInfos = (token, id, data) => {
    return axios.put(`${base}/company_infos/${id}`, data, {
      headers: { Authorization: `Bearer ${token}` },
    });
  };
}

// Base URL for the company-related API
let base = "accounts";

export default CompanyApi;
