import React, { useEffect, useState, useCallback } from "react";
import Card from "@mui/material/Card";
import SoftBox from "components/SoftBox";
import SoftButton from "components/SoftButton";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DataTable from "examples/Tables/DataTable";
import CustomersApi from "api/customers";
import { format } from "date-fns";
import { CircularProgress, Menu, MenuItem } from "@mui/material";
import { Link } from "react-router-dom";
import ActionCell from "./components/ActionCell";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";
import { exportDataToCSV } from "utils/csvUtils";
import { useTranslation } from "react-i18next";

function UsersList() {
  const [customers, setCustomers] = useState([]);
  const [customersToExtract, setCustomersToExtract] = useState([]);
  const [loading, setLoading] = useState(true);
  const [loadingExtract, setLoadingExtract] = useState(true);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [anchorEl, setAnchorEl] = useState(null);
  const { t } = useTranslation();

  const fetchCustomers = async (page) => {
    try {
      setLoading(true);
      const token = localStorage.getItem("token");
      const response = await CustomersApi.GetCustomers(token, page);
      setCustomers(response.data.results);
      setTotalPages(Math.ceil(response.data.count / 10));
    } catch (error) {
      console.error("Failed to fetch customers:", error);
    } finally {
      setLoading(false);
    }
  };

  const fetchCustomersToExtract = async () => {
    try {
      setLoadingExtract(true);
      const token = localStorage.getItem("token");
      const response = await CustomersApi.GetCustomersoExtract(token);
      setCustomersToExtract(response.data);
    } catch (error) {
      console.error("Failed to fetch customers:", error);
    } finally {
      setLoadingExtract(false);
    }
  };

  useEffect(() => {
    fetchCustomersToExtract();
  }, []);

  useEffect(() => {
    fetchCustomers(page);
  }, [page]);

  const formatDate = (dateString) => {
    return dateString
      ? format(new Date(dateString), "yyyy-MM-dd HH:mm:ss")
      : "";
  };

  const handleDataUpdate = useCallback(() => {
    fetchCustomers(page);
  }, [page]);

  const handlePageChange = (event, value) => {
    setPage(value);
  };

  const columns = [
    { Header: `${t("Client")}`, accessor: "username" },
    { Header: `${t("Prénom")}`, accessor: "firstname" },
    { Header: `${t("Nom de famille")}`, accessor: "lastname" },
    { Header: `${t("Téléphone")}`, accessor: "phone" },
    { Header: `${t("Code Client")}`, accessor: "client_code" },
    { Header: `${t("Ville")}`, accessor: "city" },
    { Header: `${t("Adresse")}`, accessor: "address_detail" },
    {
      Header: `${t("Créé le")}`,
      accessor: "created_at",
      Cell: ({ value }) => formatDate(value),
    },
    {
      Header: `${t("Mis à jour le")}`,
      accessor: "updated_at",
      Cell: ({ value }) => formatDate(value),
    },
    { Header: `${t("Rôle d'affaires")}`, accessor: "business_role_id" },
    { Header: `${t("Mat Fiscale")}`, accessor: "mat_fiscale" },
    { Header: `${t("Type de Magasin")}`, accessor: "store_type" },
    { Header: `${t("Première Activité")}`, accessor: "first_activity" },
    { Header: `${t("Deuxième Activité")}`, accessor: "second_activity" },
    { Header: t("action"), accessor: "action" },
  ];

  const rows = customers.map((customer) => ({
    username: customer.username,
    firstname: customer.firstname,
    lastname: customer.lastname,
    phone: customer.phone,
    client_code: customer.client_code,
    city: customer.city,
    address_detail: customer.address_detail,
    created_at: customer.created_at,
    updated_at: customer.updated_at,
    business_role_id: customer.business_role_id,
    mat_fiscale: customer.mat_fiscale,
    store_type: customer.store_type,
    first_activity: customer.first_activity,
    second_activity: customer.second_activity,
    action: (
      <ActionCell customerId={customer.id} onDataUpdate={handleDataUpdate} />
    ),
  }));

  const columnsCSV = [
    { Header: `${t("Client")}`, accessor: "username" },
    { Header: `${t("Code Client")}`, accessor: "client_code" },
    { Header: `${t("Prénom")}`, accessor: "firstname" },
    { Header: `${t("Nom de famille")}`, accessor: "lastname" },
    { Header: `${t("Téléphone")}`, accessor: "phone" },
    { Header: `${t("Ville")}`, accessor: "city" },
    { Header: `${t("Adresse")}`, accessor: "address_detail" },
    {
      Header: `${t("Créé le")}`,
      accessor: "created_at",
    },
    {
      Header: `${t("Mis à jour le")}`,
      accessor: "updated_at",
    },
    { Header: `${t("Rôle d'affaires")}`, accessor: "business_role_id" },
    { Header: `${t("Mat Fiscale")}`, accessor: "mat_fiscale" },
    { Header: `${t("Type de Magasin")}`, accessor: "store_type" },
    { Header: `${t("Première Activité")}`, accessor: "first_activity" },
    { Header: `${t("Deuxième Activité")}`, accessor: "second_activity" },
    { Header: t("action"), accessor: "action" },
  ];

  const rowsCSV = customersToExtract.map((customer) => ({
    username: customer.username,
    client_code: customer.client_code,
    firstname: customer.firstname,
    lastname: customer.lastname,
    phone: customer.phone,
    city: customer.city,
    address_detail: customer.address_detail,
    created_at: new Date(customer.created_at).toLocaleString(),
    updated_at: new Date(customer.updated_at).toLocaleString(),
    business_role_id: customer.business_role_id,
    mat_fiscale: customer.mat_fiscale,
    store_type: customer.store_type,
    first_activity: customer.first_activity,
    second_activity: customer.second_activity,
    action: (
      <ActionCell customerId={customer.id} onDataUpdate={handleDataUpdate} />
    ),
  }));

  const handleExportToCSV = () => {
    const exportColumns = columnsCSV.filter(
      (column) => column.accessor !== "action"
    );
    const exportRows = rowsCSV.map((row) => {
      const { action, ...exportRow } = row;
      return exportRow;
    });
    exportDataToCSV(exportColumns, exportRows, "clients.csv");
  };

  const handleUploadButtonClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <SoftBox
        display="flex"
        flexDirection="column"
        justifyContent="space-between"
        minHeight="85vh"
      >
        <SoftBox my={3}>
          {!loading && !loadingExtract && (
            <SoftBox
              display="flex"
              justifyContent="space-between"
              alignItems="flex-start"
              mb={2}
            >
              <Link to="/gestion-des-ventes/nouveau-client">
                <SoftButton variant="gradient" color="info">
                  {t("Nouveau client")}
                </SoftButton>
              </Link>
              <SoftButton
                variant="gradient"
                color="info"
                onClick={handleUploadButtonClick}
              >
                {t("Importer")} / {t("Exporter")}
              </SoftButton>
              <Menu
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleMenuClose}
              >
                <MenuItem>{t("Importez la liste des clients")}</MenuItem>
                <MenuItem onClick={handleExportToCSV}>
                  {t("Exportez la liste des clients")}
                </MenuItem>
              </Menu>
            </SoftBox>
          )}
          <Card>
            {loading || loadingExtract ? (
              <SoftBox
                display="flex"
                justifyContent="center"
                alignItems="center"
                p={35}
              >
                <CircularProgress sx={{ color: "#1F51FF" }} />
              </SoftBox>
            ) : (
              <>
                <DataTable
                  table={{ columns, rows }}
                  entriesPerPage={false}
                  canSearch
                />
                <Stack
                  spacing={2}
                  sx={{
                    mt: 2,
                    position: "absolute",
                    bottom: 20,
                    left: 100,
                    transform: "translateX(-50%)",
                  }}
                >
                  <Pagination
                    count={totalPages}
                    page={page}
                    onChange={handlePageChange}
                    color="secondary"
                  />
                </Stack>
              </>
            )}
          </Card>
        </SoftBox>
        <Footer />
      </SoftBox>
    </DashboardLayout>
  );
}

export default UsersList;
