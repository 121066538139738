const appBar = {
  color: "transparent",

  styleOverrides: {
    root: {
      boxShadow: "none",
    },
  },
};

export default appBar;
