import { useRef, useState } from "react";
import Grid from "@mui/material/Grid";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Card from "@mui/material/Card";
import CircularProgress from "@mui/material/CircularProgress";
import SoftBox from "components/SoftBox";
import SoftButton from "components/SoftButton";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import Footer from "examples/Footer";
import ProductsApi from "api/products";
import { useNavigate } from "react-router-dom";
import ProductInfo from "./components/ProductInfo";
import Media from "./components/Media";
import Pricing from "./components/Pricing";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import { useTranslation } from "react-i18next";

function getSteps() {
  return ["1. Product Info", "2. Média", "3. Pricing"];
}

function NewProduct() {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const [activeStep, setActiveStep] = useState(0);
  const [productInfo, setProductInfo] = useState({});
  const [mediaInfo, setMediaInfo] = useState({
    product_image: "",
  });
  const [pricingInfo, setPricingInfo] = useState({});
  const [isSaving, setIsSaving] = useState(false);
  const steps = getSteps();
  const isLastStep = activeStep === steps.length - 1;
  // eslint-disable-next-line
  const [isFormValid, setIsFormValid] = useState(true);

  const productInfoRef = useRef();
  const mediaInfoRef = useRef();
  const pricingInfoRef = useRef();
  // console.log(pricingInfoRef?.current?.validateForm());
  // Handle next step
  const handleNext = () => {
    let isValid = true;

    if (activeStep === 0 && productInfoRef.current) {
      isValid = productInfoRef.current.validateForm();
    } else if (activeStep === 1 && mediaInfoRef.current) {
      isValid = mediaInfoRef.current.validateForm();
    } else {
      isValid = pricingInfoRef.current.validateForm();
    }

    if (isValid) {
      setIsFormValid(true);
      setActiveStep((prevStep) => prevStep + 1);
    } else {
      setIsFormValid(false);
    }
  };

  // Handle previous step
  const handleBack = () => setActiveStep(activeStep - 1);

  // Update form data
  const handleProductInfoChange = (newInfo) => {
    setProductInfo((prev) => ({ ...prev, ...newInfo }));
  };

  const handleMediaChange = (newInfo) => {
    setMediaInfo((prev) => ({ ...prev, ...newInfo }));
  };

  const handlePricingChange = (newInfo) => {
    setPricingInfo((prev) => ({ ...prev, ...newInfo }));
  };

  // Save product
  const handleSave = () => {
    if (!pricingInfoRef?.current?.validateForm()) {
      console.error("Form is not valid.");
      return;
    }
    setIsSaving(true);
    const token = localStorage.getItem("token");
    if (!token) {
      console.error("No token found in localStorage.");
      setIsSaving(false);
      return;
    }

    const payload = {
      product_name: productInfo.productName,
      description: productInfo.description,
      unit_price: pricingInfo.unitPrice,
      buying_price: pricingInfo.buyingPrice,
      status: productInfo.status?.value,
      category_id: productInfo.category?.value,
      is_taxed: productInfo.isTaxed,
      product_image: mediaInfo.product_image,
      max_order: pricingInfo.maxItemsToOrder,
      box: pricingInfo.isBox,
      box_items_quantity: productInfo.itemsPerBox,
      reference_code: productInfo.referenceCode,
      product_min_stock_quantity: pricingInfo.minStockQuantity,
      stock_keeping_unit: pricingInfo.buyingPrice,
    };

    ProductsApi.CreateNewProduct(payload, token)
      .then((response) => {
        console.log("Product created successfully:", response.data);
        navigate("/gestion-de-stock/liste-des-articles");
      })
      .catch((error) => {
        console.error(error.response?.data?.msg || error.message);
      })
      .finally(() => {
        setIsSaving(false);
      });
  };

  const getStepContent = (stepIndex) => {
    switch (stepIndex) {
      case 0:
        return (
          <ProductInfo
            ref={productInfoRef}
            onChange={handleProductInfoChange}
            onValidationChange={setIsFormValid}
          />
        );
      case 1:
        return (
          <Media
            ref={mediaInfoRef}
            onChange={handleMediaChange}
            onValidationChange={setIsFormValid}
          />
        );
      case 2:
        return (
          <Pricing
            ref={pricingInfoRef}
            onChange={handlePricingChange}
            onValidationChange={setIsFormValid}
          />
        );
      default:
        return null;
    }
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <SoftBox mt={1} mb={5}>
        <Grid container justifyContent="center">
          <Grid item xs={12} lg={8}>
            <Stepper activeStep={activeStep} alternativeLabel>
              {steps.map((label) => (
                <Step key={label}>
                  <StepLabel>{label}</StepLabel>
                </Step>
              ))}
            </Stepper>
            <Card sx={{ overflow: "visible" }}>
              <SoftBox p={2}>
                <SoftBox>
                  {getStepContent(activeStep)}
                  <SoftBox
                    mt={3}
                    width="100%"
                    display="flex"
                    justifyContent="space-between"
                  >
                    {activeStep === 0 ? (
                      <SoftBox />
                    ) : (
                      <SoftButton
                        variant="gradient"
                        color="secondary"
                        onClick={handleBack}
                      >
                        {t("Retour")}
                      </SoftButton>
                    )}
                    <SoftButton
                      variant="gradient"
                      color="dark"
                      onClick={!isLastStep ? handleNext : handleSave}
                      disabled={isSaving}
                    >
                      {isSaving ? (
                        <CircularProgress size={24} color="inherit" />
                      ) : isLastStep ? (
                        `${t("Enregistrer")}`
                      ) : (
                        `${t("Suivant")}`
                      )}
                    </SoftButton>
                  </SoftBox>
                </SoftBox>
              </SoftBox>
            </Card>
          </Grid>
        </Grid>
      </SoftBox>
      <Footer />
    </DashboardLayout>
  );
}

export default NewProduct;
