function numberToWords(num) {
  const unites1 = [
    "",
    "un",
    "deux",
    "trois",
    "quatre",
    "cinq",
    "six",
    "sept",
    "huit",
    "neuf",
  ];

  const unites2 = [
    "dix",
    "onze",
    "douze",
    "treize",
    "quatorze",
    "quinze",
    "seize",
    "dix-sept",
    "dix-huit",
    "dix-neuf",
  ];

  const dizaines = [
    "",
    "",
    "vingt",
    "trente",
    "quarante",
    "cinquante",
    "soixante",
    "soixante-dix",
    "quatre-vingt",
    "quatre-vingt-dix",
  ];

  const scales = ["", "mille", "million", "milliard"];

  const chunkify = (n) => {
    let chunks = [];
    let numStr = String(n);
    while (numStr.length > 0) {
      chunks.unshift(numStr.slice(-3));
      numStr = numStr.slice(0, -3);
    }
    return chunks;
  };

  const convertChunk = (chunk) => {
    let number = parseInt(chunk, 10);
    if (number === 0) return "";

    let words = [];
    if (number >= 100) {
      let hundreds = Math.floor(number / 100);
      if (hundreds === 1) words.push("cent");
      else words.push(unites1[hundreds] + " cent");
      number = number % 100;
    }

    if (number >= 20) {
      let tens = Math.floor(number / 10);
      words.push(dizaines[tens]);
      number = number % 10;
      if (number > 0 && tens !== 7 && tens !== 9) {
        words.push(unites1[number]);
      } else if (tens === 7 || tens === 9) {
        words.push(unites2[number]); // Special case for 70-79, 90-99
      }
    } else if (number > 0) {
      words.push(unites2[number - 10] || unites1[number]);
    }

    return words.join(" ");
  };

  // Handle integer part
  let [integerPart, decimalPart] = String(num).split(".");

  let chunks = chunkify(integerPart);
  let words = [];

  for (let i = 0; i < chunks.length; i++) {
    const chunkWords = convertChunk(chunks[i]);
    if (chunkWords) {
      let scale = scales[chunks.length - i - 1];
      if (scale === "mille" && chunkWords === "un") {
        words.push("mille");
      } else {
        words.push(chunkWords + (scale ? " " + scale : ""));
      }
    }
  }

  // Add "dinars" for the integer part
  words.push("dinars");

  // Handle decimal part (millimes)
  if (decimalPart) {
    words.push("et");
    let decimalWords = convertChunk(decimalPart);

    // Special handling for millimes (decimal part)
    if (decimalPart.length === 3) {
      let hundreds = parseInt(decimalPart[0]);
      let tens = parseInt(decimalPart[1]);
      let ones = parseInt(decimalPart[2]);

      // Special handling for 80-89, 90-99 in millimes (quatre-vingt and quatre-vingt-dix)
      if (tens === 8 && ones > 0) {
        decimalWords = "quatre-vingt-" + unites1[ones];
      } else if (tens === 9 && ones === 0) {
        decimalWords = "quatre-vingt-dix";
      } else if (tens === 9 && ones > 0) {
        decimalWords = "quatre-vingt-" + unites2[ones];
      } else {
        decimalWords =
          unites1[hundreds] +
          " cent " +
          dizaines[tens] +
          (ones > 0 ? " " + unites1[ones] : "");
      }
    }

    words.push(decimalWords + " millimes");
  }

  // Capitalize the first letter and return the sentence
  let result = words.join(" ").trim();
  return result.charAt(0).toUpperCase() + result.slice(1);
}

export default numberToWords;
