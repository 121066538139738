// @mui material components
import Avatar from "@mui/material/Avatar"; // Import Avatar component from MUI
import { styled } from "@mui/material/styles"; // Import styled utility from MUI

// Create a styled Avatar component using MUI's styled API
export default styled(Avatar)(({ theme, ownerState }) => {
  // Destructure theme properties for easier access
  const { palette, functions, typography, boxShadows } = theme; // Access theme settings
  const { shadow, bgColor, size } = ownerState; // Get the shadow, background color, and size from the component's owner state

  // Extract specific colors and functions from the theme
  const { gradients, transparent } = palette; // Access gradients and transparent colors from the palette
  const { pxToRem, linearGradient } = functions; // Utility functions for pixel to rem conversion and gradients
  const { size: fontSize, fontWeightBold } = typography; // Access font size and bold font weight from typography

  // Define the background value based on bgColor
  const backgroundValue =
    bgColor === "transparent"
      ? transparent.main // Use transparent color if specified
      : linearGradient(gradients[bgColor].main, gradients[bgColor].state); // Use linear gradient for specified color

  // Initialize sizeValue variable to hold size-related styles
  let sizeValue;

  // Determine size-related styles based on the size property
  switch (size) {
    case "xs":
      sizeValue = {
        width: pxToRem(24), // Set width for extra small size
        height: pxToRem(24), // Set height for extra small size
        fontSize: fontSize.xs, // Set font size for extra small size
      };
      break;
    case "sm":
      sizeValue = {
        width: pxToRem(36), // Set width for small size
        height: pxToRem(36), // Set height for small size
        fontSize: fontSize.sm, // Set font size for small size
      };
      break;
    case "lg":
      sizeValue = {
        width: pxToRem(58), // Set width for large size
        height: pxToRem(58), // Set height for large size
        fontSize: fontSize.sm, // Set font size for large size
      };
      break;
    case "xl":
      sizeValue = {
        width: pxToRem(74), // Set width for extra large size
        height: pxToRem(74), // Set height for extra large size
        fontSize: fontSize.md, // Set font size for extra large size
      };
      break;
    case "xxl":
      sizeValue = {
        width: pxToRem(110), // Set width for double extra large size
        height: pxToRem(110), // Set height for double extra large size
        fontSize: fontSize.md, // Set font size for double extra large size
      };
      break;
    default: {
      sizeValue = {
        width: pxToRem(48), // Set default width
        height: pxToRem(48), // Set default height
        fontSize: fontSize.md, // Set default font size
      };
    }
  }

  // Return the style object for the styled Avatar component
  return {
    background: backgroundValue, // Set background property
    fontWeight: fontWeightBold, // Set font weight to bold
    boxShadow: boxShadows[shadow], // Set box shadow based on the specified shadow
    ...sizeValue, // Spread size-related styles
  };
});
