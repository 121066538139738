import React, { useState } from "react";
import Icon from "@mui/material/Icon";
import Tooltip from "@mui/material/Tooltip";
import { Link } from "react-router-dom";
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import PaymentApi from "api/payment";
import { useTranslation } from "react-i18next";

function ActionCell({ bankId, onDataUpdate }) {
  const [open, setOpen] = useState(false);
  const { t } = useTranslation();

  const handleDeleteBank = () => {
    const token = localStorage.getItem("token");
    if (!token) {
      console.error("No token found in localStorage.");
      return;
    }

    PaymentApi.DeleteBank(token, bankId)
      .then((response) => {
        setOpen(false);
        onDataUpdate();
      })
      .catch((error) => {
        console.error(error.response?.data?.msg || error.message);
        setOpen(false);
      });
  };

  return (
    <SoftBox
      display="flex"
      flexDirection="column"
      justifyContent="center"
      rowGap={2}
    >
      <SoftTypography
        variant="body1"
        color="grey-400"
        sx={{ cursor: "pointer", lineHeight: 0 }}
      >
        <Tooltip title={t("Aperçu de la banque")} placement="top">
          <Link
            to={`/gestion-financiere/banque-details`}
            style={{ color: "inherit" }}
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              width: "40px",
              height: "40px",
              borderRadius: "50%",
              transition: "background-color 0.3s ease",
              "&:hover": {
                backgroundColor: "rgba(0, 0, 0, 0.1)",
              },
            }}
          >
            <Icon>visibility</Icon>
          </Link>
        </Tooltip>
      </SoftTypography>
      <SoftTypography
        variant="body1"
        color="grey-400"
        sx={{ cursor: "pointer", lineHeight: 0 }}
      >
        <Tooltip title={t("Modifier la banque")} placement="top">
          <Link
            to={`/gestion-financiere/modifier-banque`}
            style={{ color: "inherit" }}
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              width: "40px",
              height: "40px",
              borderRadius: "50%",
              transition: "background-color 0.3s ease",
              "&:hover": {
                backgroundColor: "rgba(0, 0, 0, 0.1)",
              },
            }}
          >
            <Icon>edit</Icon>
          </Link>
        </Tooltip>
      </SoftTypography>
      <SoftTypography
        variant="body1"
        color="error"
        sx={{ cursor: "pointer", lineHeight: 0 }}
        onClick={() => setOpen(true)}
      >
        <Tooltip title={t("Supprimer le produit")} placement="left">
          <Icon>delete</Icon>
        </Tooltip>
      </SoftTypography>

      <Dialog
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="confirm-dialog-title"
        aria-describedby="confirm-dialog-description"
      >
        <DialogTitle id="confirm-dialog-title">
          {t("Supprimer la banque")}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="confirm-dialog-description">
            {t("Êtes-vous sûr de vouloir supprimer cette banque ?")}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpen(false)} color="primary">
            {t("Non")}
          </Button>
          <Button onClick={handleDeleteBank} color="primary" autoFocus>
            {t("Oui")}
          </Button>
        </DialogActions>
      </Dialog>
    </SoftBox>
  );
}

export default ActionCell;
