import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import React, { forwardRef, useImperativeHandle, useState } from "react";
import { useTranslation } from "react-i18next";
import { handleUpload } from "utils/ImageUploader";

const Media = forwardRef(({ onChange }, ref) => {
    const { t } = useTranslation();

  const [image, setImage] = useState("");
  const [error, setError] = useState("");

  useImperativeHandle(ref, () => ({
    validateForm,
  }));

  const handleImageUpload = async (event) => {
    setError("");
    const file = event.target.files[0];

    if (!file) {
      setError(`${t("Veuillez sélectionner une image")}`);
      return;
    }

    try {
      const imageUrl = await handleUpload(file);
      setImage(imageUrl);
      onChange({ product_image: imageUrl });
    } catch (error) {
      console.error("Error uploading image:", error);
      setError("Error uploading image. Please try again.");
    }
  };

  // Validation function
  const validateForm = () => {
    if (!image) {
      setError(`${t("L'image est requise")}`);
      return false;
    }
    return true;
  };

  return (
    <SoftBox>
      <SoftTypography variant="h5">{t("Média")}</SoftTypography>
      <SoftBox mt={3}>
        <SoftBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
          <SoftTypography component="label" variant="caption" fontWeight="bold">
            {t("Image du produit")}
          </SoftTypography>
        </SoftBox>
        <input
          type="file"
          onChange={handleImageUpload}
          accept="image/*"
          style={{ marginBottom: 10 }}
        />
        {image && (
          <img
            src={image}
            alt="Uploaded"
            style={{ maxWidth: "100%", marginTop: 10 }}
          />
        )}
      </SoftBox>
      <SoftTypography component="label" variant="caption" color="error">
        {error}
      </SoftTypography>
    </SoftBox>
  );
});

export default Media;
