import { forwardRef, useEffect, useImperativeHandle, useState } from "react";
import Grid from "@mui/material/Grid";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import FormField from "../FormField";
import { useTranslation } from "react-i18next";

const Pricing = forwardRef(({ onChange }, ref) => {
  const { t } = useTranslation();

  const [unitPrice, setUnitPrice] = useState("");
  const [buyingPrice, setBuyingPrice] = useState("");
  const [minStockQuantity, setMinStockQuantity] = useState("");
  const [maxItemsToOrder, setMaxItemsToOrder] = useState("");
  const [itemsPerBox, setItemsPerBox] = useState("");
  const [isBox, setIsBox] = useState(false);
  const [errors, setErrors] = useState({});

  useImperativeHandle(ref, () => ({
    validateForm,
  }));

  useEffect(() => {
    onChange({
      unitPrice,
      buyingPrice,
      minStockQuantity,
      maxItemsToOrder,
      itemsPerBox,
      isBox,
    });
    // eslint-disable-next-line
  }, [
    unitPrice,
    buyingPrice,
    minStockQuantity,
    maxItemsToOrder,
    itemsPerBox,
    isBox,
  ]);

  const handleInputChange = (field, value) => {
    switch (field) {
      case "unitPrice":
        setUnitPrice(value);
        break;
      case "buyingPrice":
        setBuyingPrice(value);
        break;
      case "minStockQuantity":
        setMinStockQuantity(value);
        break;
      case "maxItemsToOrder":
        setMaxItemsToOrder(value);
        break;
      case "itemsPerBox":
        setItemsPerBox(value);
        break;
      case "isBox":
        setIsBox((prev) => !prev);
        break;
      default:
        break;
    }

    // // Update parent component
    // onChange({
    //   unitPrice,
    //   buyingPrice,
    //   minStockQuantity,
    //   maxItemsToOrder,
    //   itemsPerBox,
    //   isBox,
    // });
  };

  const validateForm = () => {
    const newErrors = {};
    let isValid = true;

    if (!unitPrice || isNaN(unitPrice) || parseFloat(unitPrice) <= 0) {
      newErrors.unitPrice = `${t(
        "Le prix unitaire doit être un nombre positif."
      )}`;
      isValid = false;
    }
    if (!buyingPrice || isNaN(buyingPrice) || parseFloat(buyingPrice) <= 0) {
      newErrors.buyingPrice = `${t(
        "Le prix d'achat doit être un nombre positif."
      )}`;
      isValid = false;
    }
    if (
      !minStockQuantity ||
      isNaN(minStockQuantity) ||
      parseInt(minStockQuantity) < 0
    ) {
      newErrors.minStockQuantity = `${t(
        "La quantité minimale en stock doit être un entier non négatif."
      )}`;
      isValid = false;
    }
    if (
      !maxItemsToOrder ||
      isNaN(maxItemsToOrder) ||
      parseInt(maxItemsToOrder) <= 0
    ) {
      newErrors.maxItemsToOrder = `${t(
        "Le nombre maximum d'articles à commander doit être un entier positif."
      )}`;
      isValid = false;
    }
    if (!itemsPerBox || isNaN(itemsPerBox) || parseInt(itemsPerBox) <= 0) {
      newErrors.itemsPerBox = `${t(
        "La quantité d'articles par boîte doit être un entier positif."
      )}`;
      isValid = false;
    }

    setErrors(newErrors);
    return isValid;
  };
  return (
    <SoftBox>
      <SoftTypography variant="h5">Pricing</SoftTypography>
      <SoftBox mt={3}>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={4}>
            <FormField
              type="text"
              label={t("Prix unitaire")}
              placeholder="99.00"
              value={unitPrice}
              onChange={(e) => handleInputChange("unitPrice", e.target.value)}
              error={errors.unitPrice}
            />
            <SoftTypography component="label" variant="caption" color="error">
              {errors.unitPrice}
            </SoftTypography>
          </Grid>
          <Grid item xs={12} sm={4}>
            <FormField
              type="text"
              label={t("Prix d'achat")}
              placeholder="50.00"
              value={buyingPrice}
              onChange={(e) => handleInputChange("buyingPrice", e.target.value)}
              error={errors.buyingPrice}
            />
            <SoftTypography component="label" variant="caption" color="error">
              {errors.buyingPrice}
            </SoftTypography>
          </Grid>
          <Grid item xs={12} sm={4}>
            <FormField
              type="number"
              label={t("Quantité minimale en stock")}
              placeholder="5"
              value={minStockQuantity}
              onChange={(e) =>
                handleInputChange("minStockQuantity", e.target.value)
              }
              error={errors.minStockQuantity}
            />
            <SoftTypography component="label" variant="caption" color="error">
              {errors.minStockQuantity}
            </SoftTypography>
          </Grid>
        </Grid>
      </SoftBox>
      <SoftBox mt={2}>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={4}>
            <FormField
              type="number"
              label={t("Nombre maximum d'articles à commander")}
              placeholder="100"
              value={maxItemsToOrder}
              onChange={(e) =>
                handleInputChange("maxItemsToOrder", e.target.value)
              }
              error={errors.maxItemsToOrder}
            />
            <SoftTypography component="label" variant="caption" color="error">
              {errors.maxItemsToOrder}
            </SoftTypography>
          </Grid>
          <Grid item xs={12} sm={4}>
            <FormField
              type="number"
              label={t("Quantité d'articles par boîte")}
              placeholder="10"
              value={itemsPerBox}
              onChange={(e) => handleInputChange("itemsPerBox", e.target.value)}
              error={errors.itemsPerBox}
            />
            <SoftTypography component="label" variant="caption" color="error">
              {errors.itemsPerBox}
            </SoftTypography>
          </Grid>
          <Grid item mt={5} ml={2}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={isBox}
                  onChange={() => handleInputChange("isBox")}
                />
              }
              label={t("Est-ce une boîte")}
            />
          </Grid>
        </Grid>
      </SoftBox>
    </SoftBox>
  );
});

export default Pricing;
