import { saveAs } from "file-saver";

export const exportDataToCSV = (columns, data, filename) => {
  // Extract headers from the columns array
  const headers = columns.map((column) => column.Header).join(",");

  // Extract rows from the data array
  const rows = data.map((item) =>
    columns
      .map((column) => {
        const value =
          item[column.accessor] !== undefined
            ? item[column.accessor]?.toString()
            : "";
        // Surround the value with double quotes if it contains a comma
        return value?.includes(",") ? `"${value}"` : value;
      })
      .join(",")
  );

  // Combine headers and rows into a single CSV string
  const csvContent = [headers, ...rows].join("\n");

  // Create a blob from the CSV string and trigger a download
  const blob = new Blob([csvContent], { type: "text/csv" });
  saveAs(blob, filename);
};
