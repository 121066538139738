import React, { useEffect, useState, useMemo } from "react";
import { useParams } from "react-router-dom";
import SoftBox from "components/SoftBox";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import { CircularProgress } from "@mui/material";
import BalanceApi from "api/balance";
import DataTable from "examples/Tables/DataTable";
import { useTranslation } from "react-i18next";

function UserHistory() {
  const { userId } = useParams();
  const [orderDetails, setOrderDetails] = useState([]);
  const [loading, setLoading] = useState(true);
  const [userTotalDebit, setUserTotalDebit] = useState(null);
  const [userTotalCredit, setUserTotalCredit] = useState(null);
  const [currentBalance, setCurrentBalance] = useState(null);
  const { t } = useTranslation();

  const handleGetOrders = async () => {
    const token = localStorage.getItem("token");

    if (!token) {
      console.error("No token found in localStorage.");

      setLoading(false);
      return;
    }

    try {
      const response = await BalanceApi.GetHistoryByClientID(token, userId);
      const { client_lines, total_mouvement, solde } = response.data;

      setOrderDetails(client_lines);
      setUserTotalDebit(total_mouvement.debit);
      setUserTotalCredit(total_mouvement.credit);
      setCurrentBalance(solde);
    } catch (error) {
      console.log(error.response?.data?.msg || "There has been an error.");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    handleGetOrders();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userId]);

  const columns = [
    { Header: "Date", accessor: "date", width: "20%" },
    {
      Header: `${t("Type operation")}`,
      accessor: "operation_type",
      width: "20%",
    },
    { Header: `${t("Client")}`, accessor: "client", width: "20%" },
    {
      Header: `${t("Montant Débit")}`,
      accessor: "montant_debit",
      width: "20%",
    },
    {
      Header: `${t("Montant Crédit")}`,
      accessor: "montant_credit",
      width: "20%",
    },
    { Header: `${t("Solde")}`, accessor: "solde", width: "20%" },
  ];

  const rows = useMemo(
    () => [
      ...orderDetails.map((order) => ({
        date: new Date(order.date).toLocaleString("fr-FR", {
          year: "numeric",
          month: "2-digit",
          day: "2-digit",
          hour: "2-digit",
          minute: "2-digit",
          second: "2-digit",
        }),
        operation_type: order.operation_type || "-",
        client: order.client || "-",
        montant_debit: order.montant_debit.toFixed(3) + " TND",
        montant_credit: order.montant_credit.toFixed(3) + " TND",
        solde: order.solde.toFixed(3) + " TND",
      })),
      {
        date: "",
        operation_type: <strong>{t("Total Mouvements")}</strong>,
        client: "",
        montant_debit: userTotalDebit ? (
          <strong>{`${userTotalDebit.toFixed(3)} TND`}</strong>
        ) : (
          "-"
        ),
        montant_credit: userTotalCredit ? (
          <strong>{`${userTotalCredit.toFixed(3)} TND`}</strong>
        ) : (
          "-"
        ),
        solde: "",
      },
      {
        date: "",
        operation_type: "",
        client: <strong>{t("Solde")}</strong>,
        montant_debit: "",
        montant_credit: "",
        solde: currentBalance ? (
          <strong>{`${currentBalance.toFixed(3)} TND`}</strong>
        ) : (
          "-"
        ),
      },
    ],
    [orderDetails, userTotalDebit, userTotalCredit, currentBalance, t]
  );

  if (loading) {
    return (
      <DashboardLayout>
        <DashboardNavbar />
        <SoftBox
          display="flex"
          justifyContent="center"
          alignItems="center"
          p={35}
        >
          <CircularProgress sx={{ color: "#1F51FF" }} />
        </SoftBox>
        <Footer />
      </DashboardLayout>
    );
  }

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <SoftBox
        display="flex"
        flexDirection="column"
        justifyContent="space-between"
        minHeight="85vh"
      >
        <SoftBox
          sx={{
            maxWidth: { xl: "70%" },
            width: "100%",
            margin: "auto",
            marginTop: "5%",
            marginBottom: "5%",
          }}
        >
          <DataTable
            table={{ columns, rows }}
            entriesPerPage={false}
            columnMinWidth={70}
          />
        </SoftBox>
        <Footer />
      </SoftBox>
    </DashboardLayout>
  );
}

export default UserHistory;
