import React, { useState } from "react";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import { useNavigate } from "react-router-dom";
import FormField from "layouts/pages/account/components/FormField";
import SoftButton from "components/SoftButton";
import SoftSelect from "components/SoftSelect";
import { useTranslation } from "react-i18next";

function CreateCaisse() {
  const { t } = useTranslation();

  const [caisse, setCaisse] = useState({
    name: "",
    address: "",
    warehouse: "",
    createdAt: "",
  });

  const navigate = useNavigate();

  const handleChange = (e) => {
    setCaisse({ ...caisse, [e.target.name]: e.target.value });
  };

  const handleSelectChange = (value) => {
    setCaisse({ ...caisse, warehouse: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    navigate("/gestion-financiere/liste-des-caisse");
  };

  const warehouseOptions = [
    { value: "entrepot_1", label: "Entrepôt 1" },
    { value: "entrepot_2", label: "Entrepôt 2" },
    { value: "entrepot_3", label: "Entrepôt 3" },
  ];

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <SoftBox
        display="flex"
        flexDirection="column"
        justifyContent="space-between"
        minHeight="85vh"
      >
        <SoftBox py={2}>
          <Grid container justifyContent="center">
            <Grid item xs={12} md={6}>
              <Card>
                <CardContent>
                  <SoftTypography variant="h5" gutterBottom pb={5} pt={2}>
                    {t("Créer une caisse")}
                  </SoftTypography>
                  <form onSubmit={handleSubmit}>
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <FormField
                          fullWidth
                          label={t("Nom de la caisse")}
                          name="name"
                          value={caisse.name}
                          onChange={handleChange}
                          required
                        />
                      </Grid>

                      <Grid item xs={12}>
                        <FormField
                          fullWidth
                          label={t("Adresse")}
                          name="address"
                          value={caisse.address}
                          onChange={handleChange}
                          required
                        />
                      </Grid>

                      <Grid item xs={12}>
                        <SoftBox mt={-1.5} pb={0.5}>
                          <SoftTypography variant="caption" fontWeight="bold">
                            {t("Entrepôt")}
                          </SoftTypography>
                        </SoftBox>
                        <SoftSelect
                          fullWidth
                          options={warehouseOptions}
                          value={caisse.warehouse}
                          onChange={handleSelectChange}
                          placeholder={t("Sélectionnez un entrepôt")}
                          required
                        />
                      </Grid>

                      <Grid item xs={12}>
                        <FormField
                          fullWidth
                          label={t("Date de création")}
                          name="createdAt"
                          type="date"
                          value={caisse.createdAt}
                          onChange={handleChange}
                          InputLabelProps={{
                            shrink: true,
                          }}
                          required
                        />
                      </Grid>

                      <Grid
                        item
                        xs={12}
                        display="flex"
                        justifyContent="flex-end"
                      >
                        <SoftButton
                          type="submit"
                          variant="contained"
                          color="info"
                        >
                          {t("Créer la caisse")}
                        </SoftButton>
                      </Grid>
                    </Grid>
                  </form>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </SoftBox>
        <Footer />
      </SoftBox>
    </DashboardLayout>
  );
}

export default CreateCaisse;
