import axios from "./index";

// AuthApi class handles login, registration, and logout requests
class AuthApi {
  /**
   * Logs in a user by sending a POST request with the phone number and password.
   * @param {Object} data - The login data (e.g., { phone: '12345678', password: 'password123' }).
   * @returns {Promise} - The response of the login request.
   */
  static Login = (data) => {
    return axios.post(`${base}/login`, data);
  };

  /**
   * Registers a new user by sending a POST request with registration details.
   * @param {Object} data - The registration data (e.g., { phone: '12345678', password: 'password123' }).
   * @returns {Promise} - The response of the registration request.
   */
  static Register = (data) => {
    return axios.post(`${base}/register`, data);
  };

  /**
   * Logs out a user by sending a POST request with the user's token.
   * The token is included in the Authorization header.
   * @param {Object} data - An object containing the user's token (e.g., { token: 'user-token' }).
   * @returns {Promise} - The response of the logout request.
   */
  static Logout = (data) => {
    return axios.post(`${base}/logout`, data, {
      headers: { Authorization: `${data.token}` },
    });
  };
}

// Base URL for the authentication API
let base = "accounts";

export default AuthApi;
