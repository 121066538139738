import React, { useEffect, useState } from "react";
import { CircularProgress, Card, Grid, Typography, Box } from "@mui/material";
import SoftBox from "components/SoftBox";
import Footer from "examples/Footer";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import CompanyApi from "api/companyInfo";
import SoftButton from "components/SoftButton";
import { useNavigate } from "react-router-dom";
import SoftTypography from "components/SoftTypography";
import SoftInput from "components/SoftInput";
import { useTranslation } from "react-i18next";

function ModifierEntrepriseInfo() {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [formData, setFormData] = useState({
    company_name: "",
    location: "",
    contact_email: "",
    contact_phone: "",
    address: "",
    stamp_value: "",
    tax_value: "",
    code_tva: "",
    code_bic: "",
    bank_details: "",
    date_created: "",
    last_updated: "",
  });
  const [logo, setLogo] = useState(null);
  const [logoPreview, setLogoPreview] = useState("");

  useEffect(() => {
    const fetchCompanyInfo = async () => {
      try {
        const token = localStorage.getItem("token");
        if (!token) {
          console.error("No token found in localStorage.");
          return;
        }
        const response = await CompanyApi.GetCompanyInfos(token);
        const companyData = response.data.results[0];
        setData(companyData);
        setFormData({
          company_name: companyData.company_name,
          location: companyData.location,
          contact_email: companyData.contact_email,
          contact_phone: companyData.contact_phone,
          address: companyData.address,
          stamp_value: companyData.stamp_value,
          tax_value: companyData.tax_value,
          code_tva: companyData.code_tva,
          code_bic: companyData.code_bic,
          bank_details: companyData.bank_details,
          date_created: new Date(companyData.date_created).toLocaleDateString(
            "fr-FR"
          ),
          last_updated: new Date(companyData.last_updated).toLocaleDateString(
            "fr-FR"
          ),
        });
        setLogoPreview(companyData.logo_url || "");
      } catch (err) {
        console.log(err);
      } finally {
        setLoading(false);
      }
    };

    fetchCompanyInfo();
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleLogoChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setLogo(file);
      setLogoPreview(URL.createObjectURL(file));
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const token = localStorage.getItem("token");
      if (!token) {
        console.error("No token found in localStorage.");
        return;
      }
      const companyId = data?.id;
      if (!companyId) {
        console.error("Company ID not found.");
        return;
      }
      const updatedFormData = new FormData();
      Object.keys(formData).forEach((key) => {
        updatedFormData.append(key, formData[key]);
      });
      if (logo) {
        updatedFormData.append("logo", logo);
      }
      await CompanyApi.UpdateCompanyInfos(token, companyId, updatedFormData);
      navigate("/entreprise/informations-entreprise");
    } catch (err) {
      console.log(err);
    }
  };

  if (loading)
    return (
      <SoftBox
        display="flex"
        justifyContent="center"
        alignItems="center"
        p={5}
        sx={{ height: "80vh" }}
      >
        <CircularProgress sx={{ color: "#1F51FF" }} />
      </SoftBox>
    );

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <SoftBox
        display="flex"
        flexDirection="column"
        justifyContent="space-between"
        minHeight="85vh"
      >
        <SoftBox py={3}>
          <Card>
            <SoftBox py={3} px={5}>
              <Typography variant="h6" fontWeight="bold" mb={4}>
                {t("Modifier les informations de l'entreprise")}
              </Typography>
              <form onSubmit={handleSubmit}>
                <Grid container spacing={3}>
                  {/* Form fields */}
                  <Grid item xs={12} sm={6} lg={4}>
                    <SoftTypography variant="h6">{t("Nom")}</SoftTypography>
                    <SoftInput
                      style={{ marginBottom: "1rem" }}
                      name="company_name"
                      value={formData.company_name}
                      onChange={handleChange}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} lg={4}>
                    <SoftTypography variant="h6">
                      {t("Location")}
                    </SoftTypography>
                    <SoftInput
                      style={{ marginBottom: "1rem" }}
                      name="location"
                      value={formData.location}
                      onChange={handleChange}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} lg={4}>
                    <SoftTypography variant="h6">{t("Adresse")}</SoftTypography>
                    <SoftInput
                      style={{ marginBottom: "1rem" }}
                      name="address"
                      value={formData.address}
                      onChange={handleChange}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} lg={4}>
                    <SoftTypography variant="h6">{t("Email")}</SoftTypography>
                    <SoftInput
                      style={{ marginBottom: "1rem" }}
                      name="contact_email"
                      value={formData.contact_email}
                      onChange={handleChange}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} lg={4}>
                    <SoftTypography variant="h6">
                      {t("Téléphone")}
                    </SoftTypography>
                    <SoftInput
                      style={{ marginBottom: "1rem" }}
                      name="contact_phone"
                      value={formData.contact_phone}
                      onChange={handleChange}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} lg={4}>
                    <SoftTypography variant="h6">
                      {t("Détails bancaires")}
                    </SoftTypography>
                    <SoftInput
                      name="bank_details"
                      value={formData.bank_details}
                      onChange={handleChange}
                    />
                  </Grid>
                </Grid>

                <Grid container spacing={3}>
                  <Grid item xs={12} lg={6}>
                    <Grid container columnSpacing={3} rowSpacing={1}>
                      <Grid item xs={12} sm={6}>
                        <SoftTypography variant="h6">
                          {t("Valeur du timbre")}
                        </SoftTypography>
                        <SoftInput
                          style={{ marginBottom: "1rem" }}
                          name="stamp_value"
                          value={formData.stamp_value}
                          onChange={handleChange}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <SoftTypography variant="h6">
                          {t("Valeur de la taxe")}
                        </SoftTypography>
                        <SoftInput
                          style={{ marginBottom: "1rem" }}
                          name="tax_value"
                          value={formData.tax_value}
                          onChange={handleChange}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <SoftTypography variant="h6">
                          {t("Code TVA")}
                        </SoftTypography>
                        <SoftInput
                          style={{ marginBottom: "1rem" }}
                          name="code_tva"
                          value={formData.code_tva}
                          onChange={handleChange}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <SoftTypography variant="h6">
                          {t("Code BIC")}
                        </SoftTypography>
                        <SoftInput
                          style={{ marginBottom: "1rem" }}
                          name="code_bic"
                          value={formData.code_bic}
                          onChange={handleChange}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={4} sx={{ ml: { lg: 10 } }}>
                    <SoftTypography variant="h6">Logo</SoftTypography>
                    <Box display="flex" alignItems="center" mb={2} py={2}>
                      <Box
                        component="img"
                        src={
                          logoPreview ||
                          "https://res.cloudinary.com/dcrzf4pw1/image/upload/v1717085677/logo_hqrui4.png"
                        }
                        alt="Logo Preview"
                        sx={{
                          width: "100%",
                          maxWidth: "150px",
                          maxHeight: "150px",
                          objectFit: "contain",
                          marginRight: "1rem",
                        }}
                      />
                      <Box
                        display="flex"
                        justifyContent="center"
                        alignContent="center"
                        columnGap={4}
                      >
                        <label htmlFor="logo-upload">
                          <SoftButton
                            variant="contained"
                            color="info"
                            component="span"
                          >
                            {t("Modifier")}
                          </SoftButton>
                        </label>
                        <input
                          id="logo-upload"
                          type="file"
                          accept="image/*"
                          onChange={handleLogoChange}
                          style={{ display: "none" }}
                        />
                        <SoftButton
                          variant="contained"
                          color="error"
                          onClick={() => setLogo(null)}
                        >
                          {t("Supprimer")}
                        </SoftButton>
                      </Box>
                    </Box>
                  </Grid>
                </Grid>
              </form>
              <Box display="flex" justifyContent="flex-end">
                <SoftButton variant="contained" color="info" type="submit">
                  {t("Enregistrer les modifications")}
                </SoftButton>
              </Box>
            </SoftBox>
          </Card>
        </SoftBox>
        <Footer />
      </SoftBox>
    </DashboardLayout>
  );
}

export default ModifierEntrepriseInfo;
