import { forwardRef } from "react"; // Import forwardRef from React to allow ref forwarding

// prop-types is a library for typechecking of props
import PropTypes from "prop-types"; // Import PropTypes for prop type validation

// Custom styles for the SoftBadge
import SoftBadgeRoot from "components/SoftBadge/SoftBadgeRoot"; // Import the styled SoftBadgeRoot component

// Define the SoftBadge component using forwardRef for ref handling
const SoftBadge = forwardRef(
  (
    {
      color = "info", // Badge color
      variant = "gradient", // Badge variant
      size = "sm", // Size of the badge
      circular = false, // Whether the badge is circular
      indicator = false, // Indicator for notifications
      border = false, // Whether the badge has a border
      container = false, // Whether to wrap the badge in a container
      children = false, // Children elements to be rendered inside the badge
      ...rest // Any other props
    },
    ref // Ref for the badge component
  ) => (
    <SoftBadgeRoot
      {...rest} // Spread any additional props onto SoftBadgeRoot
      ownerState={{
        color,
        variant,
        size,
        circular,
        indicator,
        border,
        container,
        children,
      }} // Pass ownerState to SoftBadgeRoot
      ref={ref} // Assign the ref to SoftBadgeRoot
      color="default" // Set default color for SoftBadgeRoot
    >
      {children} {/*   Render children elements inside the badge */}
    </SoftBadgeRoot>
  )
);

// Typechecking props of the SoftBadge
SoftBadge.propTypes = {
  color: PropTypes.oneOf([
    // Validate color prop
    "primary",
    "secondary",
    "info",
    "success",
    "warning",
    "error",
    "light",
    "dark",
  ]),
  variant: PropTypes.oneOf(["gradient", "contained"]), // Validate variant prop
  size: PropTypes.oneOf(["xs", "sm", "md", "lg"]), // Validate size prop
  circular: PropTypes.bool, // Validate circular prop as boolean
  indicator: PropTypes.bool, // Validate indicator prop as boolean
  border: PropTypes.bool, // Validate border prop as boolean
  children: PropTypes.node, // Validate children prop as node
  container: PropTypes.bool, // Validate container prop as boolean
};

export default SoftBadge; // Export the SoftBadge component
