import React, { useEffect, useState } from "react";
import { Card, CircularProgress } from "@mui/material";
import SoftBox from "components/SoftBox";
import Footer from "examples/Footer";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import DataTable from "examples/Tables/DataTable";
import SoftButton from "components/SoftButton";
import { Link } from "react-router-dom";
import ActionCell from "./components/ActionCell";
import SupplierApi from "api/supplier";
import { useTranslation } from "react-i18next";

function SuppliersList() {
  const { t } = useTranslation();

  const [suppliers, setSuppliers] = useState([]);
  const [loading, setLoading] = useState(true);

  const columns = [
    { Header: `${t("Nom")}`, accessor: "name" },
    { Header: `${t("Téléphone")}`, accessor: "phone" },
    { Header: "Email", accessor: "email" },
    { Header: t("action"), accessor: "action" },
  ];

  // Fetch suppliers from the API
  useEffect(() => {
    const fetchSuppliers = async () => {
      const token = localStorage.getItem("token");
      try {
        const supplierData = await SupplierApi.GetSuppliersList(token);
        setSuppliers(supplierData.data.results);
      } catch (error) {
        console.error("Failed to fetch suppliers:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchSuppliers();
  }, []);

  // Map suppliers to table rows format
  const rows = suppliers?.map((supplier) => ({
    name: supplier.name,
    phone: supplier.phone,
    email: supplier.email,
    action: <ActionCell supplierID={supplier.id} />,
  }));

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <SoftBox
        display="flex"
        flexDirection="column"
        justifyContent="space-between"
        minHeight="85vh"
      >
        <Card sx={{ my: 5 }}>
          <SoftBox mb={{ lg: -7 }} pt={3} px={3}>
            {/* Button to navigate to the "Nouveau Fournisseur" page */}
            <Link to="/gestion-de-stock/nouveau-fournisseur">
              <SoftButton variant="gradient" color="info">
                + {t("Nouveau fournisseur")}
              </SoftButton>
            </Link>
          </SoftBox>

          <SoftBox>
            {!loading ? (
              <DataTable
                table={{ columns, rows }} // Passing columns and rows to the DataTable
                entriesPerPage={false} // Disabling entries per page feature
                canSearch={rows?.length > 0} // Enable search if there are rows
                columnMinWidth={70} // Minimum width for columns
              />
            ) : (
              <SoftBox
                display="flex"
                justifyContent="center"
                alignItems="center"
                p={35}
              >
                <CircularProgress sx={{ color: "#1F51FF" }} />
              </SoftBox>
            )}
          </SoftBox>
        </Card>

        <Footer />
      </SoftBox>
    </DashboardLayout>
  );
}

export default SuppliersList;
