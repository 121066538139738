import axios from "./index";

class PaymentApi {
  static GetBanksList = (token) => {
    return axios.get(`${base}/banks`, {
      headers: { Authorization: `Bearer ${token}` },
    });
  };
  static GetBankById = (token, bankId) => {
    return axios.get(`${base}/banks/${bankId}`, {
      headers: { Authorization: `Bearer ${token}` },
    });
  };
  static CreateBank = (token, data) => {
    return axios.post(`${base}/banks/`, data, {
      headers: { Authorization: `Bearer ${token}` },
    });
  };
  static DeleteBank = (token, bankId) => {
    return axios.delete(`${base}/banks/${bankId}`, {
      headers: { Authorization: `Bearer ${token}` },
    });
  };
}

let base = "payment";

export default PaymentApi;
