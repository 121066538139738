import React from "react";
import {
  AppBar,
  Toolbar,
  Typography,
  Button,
  Box,
  Link,
  Grid,
} from "@mui/material";
import { QRCodeSVG } from "qrcode.react";
import { useInView } from "react-intersection-observer";
import { motion } from "framer-motion";
import PageLayout from "examples/LayoutContainers/PageLayout";
import {
  LocalGroceryStore as GroceryIcon,
  QrCode as QrCodeIcon,
  ShoppingCart as ShoppingCartIcon,
  Payment as PaymentIcon,
  LocalShipping as LocalShippingIcon,
  Receipt as ReceiptIcon,
} from "@mui/icons-material";
import brand from "assets/images/logo.png";
import EmailIcon from "@mui/icons-material/Email";
import PhoneIcon from "@mui/icons-material/Phone";
import SoftButton from "components/SoftButton";
import { useTranslation } from "react-i18next";
import LanguageSwitcher from "components/LanguageSwitcher.js";

const FadeInSection = ({ children }) => {
  const { ref, inView } = useInView({
    triggerOnce: false,
    threshold: 0.1,
  });

  return (
    <motion.div
      ref={ref}
      initial={{ opacity: 0, y: 50 }}
      animate={{ opacity: inView ? 1 : 0, y: inView ? 0 : 50 }}
      transition={{ duration: 0.8 }}
    >
      {children}
    </motion.div>
  );
};

function HomePage() {
  const { t } = useTranslation();

  return (
    <>
      <AppBar
        position="fixed"
        sx={{
          backgroundColor: "white !important",
          boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
          paddingX: { xs: "0.5rem", md: "1rem" },
        }}
      >
        <Toolbar>
          <Box sx={{ display: "flex", alignItems: "center", flexGrow: 1 }}>
            <Box
              component="img"
              src={brand}
              alt="Logo Livrili"
              sx={{ height: { xs: 30, md: 40 }, marginLeft: 2 }}
            />
            <Typography
              variant="h3"
              sx={{ ml: 2, fontSize: { xs: "1.5rem", md: "2rem" } }}
            >
              Livrili
            </Typography>
            <Typography
              variant="h3"
              sx={{ ml: 2, display: { xs: "none", md: "block" } }}
            >
              <LanguageSwitcher title={true} isDropdown />
            </Typography>
          </Box>
          <Box sx={{ display: { xs: "none", md: "flex" }, mr: 2 }}>
            <Button sx={{ color: "#627594" }} href="#home">
              {t("Accueil")}
            </Button>
            <Button sx={{ color: "#627594" }} href="#about">
              {t("À propos")}
            </Button>
            <Button sx={{ color: "#627594" }} href="#contact">
              {t("Contact")}
            </Button>
          </Box>
          <SoftButton color="info" href="/authentication/connexion">
            {t("login")}
          </SoftButton>
        </Toolbar>
      </AppBar>

      <PageLayout>
        {/* Home Section */}
        <Box
          id="home"
          sx={{
            display: "flex",
            flexDirection: { xs: "column-reverse", lg: "row" },
            justifyContent: "space-between",
            alignItems: "center",
            width: "100vw",
            height: { xs: "70vh", md: "60vh", lg: "100vh" },
            p: { xs: 3, md: 20 },
            boxSizing: "border-box",
            columnGap: 10,
            marginTop: {xs: 10, md: 31, lg: 0},
          }}
        >
          <Box
            sx={{
              flex: 1,
              height: "100%",
              backgroundImage:
                "url('https://res.cloudinary.com/dcrzf4pw1/image/upload/v1724944675/Group_44_1_a9ndji.png')",
              backgroundSize: "contain",
              backgroundPosition: "center",
              backgroundRepeat: "no-repeat",
              display: { xs: "none", lg: "block" }, // Hide on xs to md, show on lg
              marginTop: {lg: 10}
            }}
          />
          <Box
            sx={{
              flex: 1,
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              textAlign: "center",
              mb: { xs: 4, md: 0 },
            }}
          >
            <FadeInSection>
              <Typography variant="h2" sx={{ mb: 3 }}>
                {t("Welcome to Livrili")}
              </Typography>
              <Typography variant="body1" sx={{ mb: 4 }}>
                {t("landingPageText")}
              </Typography>
            </FadeInSection>

            <Box sx={{ display: "flex", alignItems: "center", mb: 4 }}>
              <QRCodeSVG
                value="https://play.google.com/store/apps/details?id=com.kamioun.kamioun"
                size={128}
                style={{ marginBottom: "16px" }}
              />
              <Link
                href="https://play.google.com/store/apps/details?id=com.kamioun.kamioun"
                target="_blank"
                rel="noopener noreferrer"
                sx={{
                  display: "flex",
                  alignItems: "center",
                  textDecoration: "none",
                  color: "inherit",
                }}
              >
                <Box
                  sx={{
                    width: { xs: 250 },
                    height: { xs: 200 },
                    backgroundImage:
                      "url('https://res.cloudinary.com/dcrzf4pw1/image/upload/v1724946312/google-play_cozg6k.png')",
                    backgroundSize: "contain",
                    backgroundPosition: "center",
                    backgroundRepeat: "no-repeat",
                  }}
                />
              </Link>
            </Box>
          </Box>
        </Box>
        {/* About Section */}
        <Box
          id="about"
          sx={{
            width: "100vw",
            paddingY: { lg: 10 },
            boxSizing: "border-box",
          }}
        >
          <FadeInSection>
            <Typography variant="h2" sx={{ mb: 4, textAlign: "center" }}>
              {t("Les Fonctionnalités")}
            </Typography>
            <Typography
              variant="h5"
              sx={{
                mb: 4,
                textAlign: "center",
                color: "text.secondary",
                paddingX: { xs: 2, md: 20 },
              }}
            >
              {t("aboutHeader")}
            </Typography>
          </FadeInSection>

          <Grid container display="flex" justifyContent="center" spacing={4}>
            {/* First Column: Points with icons */}
            <Grid
              item
              xs={12}
              md={4}
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  p: 2,
                  textAlign: "center",
                  maxWidth: { xs: "100%", lg: "80%" },
                }}
              >
                {[
                  {
                    icon: <GroceryIcon />,
                    text: t("L'avenir de l'épicerie & Shop"),
                  },
                  { icon: <QrCodeIcon />, text: t("Scanner les codes QR") },
                  { icon: <ShoppingCartIcon />, text: t("Ajouter au panier") },
                ].map((feature, index) => (
                  <FadeInSection key={index}>
                    <Box
                      sx={{
                        mb: 2,
                        display: "flex",
                        alignItems: "center",
                        columnGap: 1,
                        justifyContent: "center",
                      }}
                    >
                      {React.cloneElement(feature.icon, {
                        sx: { fontSize: 40, color: "primary.main" },
                      })}
                      <Typography
                        variant="h6"
                        sx={{
                          fontWeight: 500,
                          fontSize: { xs: "1rem", md: "1.2rem" },
                        }}
                      >
                        {feature.text}
                      </Typography>
                    </Box>
                  </FadeInSection>
                ))}
              </Box>
            </Grid>

            {/* Second Column: Responsive Image */}
            <Grid item xs={12} md={4}>
              <Box
                sx={{
                  width: "100%",
                  height: { xs: "25rem", md: "35rem" },
                  backgroundImage:
                    "url('https://res.cloudinary.com/dcrzf4pw1/image/upload/v1724947432/Group_51_nsvmjd.png')",
                  backgroundSize: "contain",
                  backgroundPosition: "center",
                  backgroundRepeat: "no-repeat",
                }}
              />
            </Grid>

            {/* Third Column: Points with icons */}
            <Grid
              item
              xs={12}
              md={4}
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  p: 2,
                  textAlign: "center",
                  maxWidth: { xs: "100%", lg: "80%" },
                }}
              >
                {[
                  { icon: <PaymentIcon />, text: t("Passation de commandes") },
                  {
                    icon: <LocalShippingIcon />,
                    text: t("Suivi de commandes"),
                  },
                  { icon: <ReceiptIcon />, text: t("Réception de commandes") },
                ].map((feature, index) => (
                  <FadeInSection key={index}>
                    <Box
                      sx={{
                        mb: 2,
                        display: "flex",
                        alignItems: "center",
                        columnGap: 1,
                        justifyContent: "center",
                      }}
                    >
                      {React.cloneElement(feature.icon, {
                        sx: { fontSize: 40, color: "primary.main" },
                      })}
                      <Typography
                        variant="h6"
                        sx={{
                          fontWeight: 500,
                          fontSize: { xs: "1rem", md: "1.2rem" },
                        }}
                      >
                        {feature.text}
                      </Typography>
                    </Box>
                  </FadeInSection>
                ))}
              </Box>
            </Grid>
          </Grid>
        </Box>
        <Box
          component="footer"
          gradient="info"
          sx={{
            backgroundColor: "#2D86C2",
            color: "#ECF0F1",
            padding: "2rem 0",
            marginTop: { xs: "3rem" },
          }}
        >
          <Grid container spacing={5} justifyContent="center">
            {/* Logo and Navigation */}
            <Grid item xs={12} md={3} sx={{ textAlign: "center" }}>
              <Box
                component="img"
                src="https://res.cloudinary.com/dcrzf4pw1/image/upload/v1724954029/mdi--cart_xgws77.svg"
                alt="Logo Livrili"
                sx={{ height: 60 }}
              />
              <Typography variant="h5" sx={{ color: "#ECF0F1" }}>
                Livrili
              </Typography>
            </Grid>
            <Grid
              item
              xs={12}
              lg={4}
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                columnGap: 4,
                flexDirection: { xs: "column", sm: "row" },
                textAlign: { xs: "center" },
              }}
            >
              <Link
                href="#home"
                sx={{
                  color: "#ECF0F1",
                  textDecoration: "none",
                  fontWeight: 500,
                  fontSize: "1.2rem",
                  "&:hover": {
                    textDecoration: "underline",
                  },
                }}
              >
                {t("Accueil")}
              </Link>
              <Link
                href="#about"
                sx={{
                  color: "#ECF0F1",
                  textDecoration: "none",
                  fontWeight: 500,
                  fontSize: "1.2rem",
                  "&:hover": {
                    textDecoration: "underline",
                  },
                }}
              >
                {t("À propos")}
              </Link>
              <Link
                href="#contact"
                sx={{
                  color: "#ECF0F1",
                  textDecoration: "none",
                  fontWeight: 500,
                  fontSize: "1.2rem",
                  "&:hover": {
                    textDecoration: "underline",
                  },
                }}
              >
                {t("Contact")}
              </Link>
            </Grid>
            {/* Contact Information */}
            <Grid
              id="contact"
              item
              xs={12}
              md={4}
              sx={{ textAlign: "center", mt: { xs: 0, lg: 5 } }} 
            >
              <Typography variant="h5" sx={{ mb: 2, fontWeight: 600 }}>
                {t("Nous Contacter")}
              </Typography>
              <Typography
                variant="body1"
                sx={{
                  mb: 1,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <EmailIcon sx={{ mr: 1 }} />
                <Link
                  href="mailto:rejeb.chaalia@gmail.com"
                  sx={{ color: "#ECF0F1", textDecoration: "none" }}
                >
                  rejeb.chaalia@gmail.com
                </Link>
              </Typography>
              <Typography
                variant="body1"
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <PhoneIcon sx={{ mr: 1 }} />
                <Link
                  href="tel:+21652187320"
                  sx={{ color: "#ECF0F1", textDecoration: "none" }}
                >
                  +216 52 187 320
                </Link>
              </Typography>
            </Grid>
          </Grid>
        </Box>
        <Box
          sx={{
            textAlign: "center",
            backgroundColor: "#ffffff",
            paddingY: "1rem",
          }}
        >
          <Typography variant="body2" sx={{ fontWeight: 400 }}>
            © {new Date().getFullYear()} Livrili. {t("Tous droits réservés.")}
          </Typography>
        </Box>
      </PageLayout>
    </>
  );
}

export default HomePage;
