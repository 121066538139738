// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// Soft UI Dashboard PRO React base styles
import colors from "assets/theme/base/colors";

function FinancialDocument({ color = "dark", size = "24px" }) {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <title>financial-document</title>
      <g
        id="Basic-Elements"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g
          id="Rounded-Icons"
          fill={colors[color] ? colors[color].main : colors.dark.main}
          fillRule="nonzero"
        >
          <path d="M14 2H6c-1.1 0-2 .9-2 2v16c0 1.1.9 2 2 2h12c1.1 0 2-.9 2-2V8l-4-6zM8 20H7v-2h1v2zm4 0h-3v-2h3v2zm4 0h-3v-2h3v2zM16 8V3.5L20.5 8H16zM8 6h8v2H8V6zm0 4h8v2H8v-2zm0 4h8v2H8v-2zm0 4h8v2H8v-2z" />
        </g>
      </g>
    </svg>
  );
}

// Typechecking props for the FinancialDocument
FinancialDocument.propTypes = {
  color: PropTypes.oneOf([
    "primary",
    "secondary",
    "info",
    "success",
    "warning",
    "error",
    "dark",
    "light",
    "white",
  ]),
  size: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

export default FinancialDocument;
