import React, { useEffect, useState } from "react";
import {
  CircularProgress,
  Grid,
  Typography,
  Divider,
  Card,
} from "@mui/material";
import SoftBox from "components/SoftBox";
import Footer from "examples/Footer";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import CompanyApi from "api/companyInfo";
import SoftButton from "components/SoftButton";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

function EntrepriseInfo() {
  const navigate = useNavigate();
  const { t } = useTranslation();

  // State hooks for data, loading state, and error handling
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  // Fetch company information on component mount
  useEffect(() => {
    const fetchCompanyInfo = async () => {
      try {
        const token = localStorage.getItem("token");
        if (!token) {
          console.error("No token found in localStorage.");
          return;
        }
        const response = await CompanyApi.GetCompanyInfos(token);
        setData(response.data.results[0]); // Set the company data
      } catch (err) {
        setError(err); // Set error if request fails
      } finally {
        setLoading(false); // Set loading to false after request completes
      }
    };

    fetchCompanyInfo();
  }, []);

  // Navigate to edit company information page
  const handleClick = () => {
    navigate("/entreprise/modifer-informations-entreprise");
  };

  // Render loading spinner while data is being fetched
  if (loading)
    return (
      <SoftBox
        display="flex"
        justifyContent="center"
        alignItems="center"
        p={5}
        sx={{ height: "80vh" }}
      >
        <CircularProgress sx={{ color: "#1F51FF" }} />
      </SoftBox>
    );

  // Render error message if there's an issue fetching data
  if (error)
    return (
      <SoftBox
        display="flex"
        justifyContent="center"
        alignItems="center"
        p={5}
        sx={{ height: "80vh" }}
      >
        <Typography variant="h6" color="error">
          {t("Erreur lors du chargement des informations de l'entreprise.")}
        </Typography>
      </SoftBox>
    );

  return (
    <DashboardLayout>
      <DashboardNavbar />

      <SoftBox mb={3} mt={1}>
        {/* Button to navigate to the edit page for company information */}
        <SoftButton variant="contained" color="info" onClick={handleClick}>
          {t("Modifier")}
        </SoftButton>
      </SoftBox>

      <SoftBox
        display="flex"
        flexDirection="column"
        justifyContent="space-between"
        minHeight="78vh"
      >
        <Card>
          <SoftBox py={3} px={5}>
            <Typography variant="h6" fontWeight="bold" gutterBottom>
              {t("Informations sur l'entreprise")}
            </Typography>
            <Divider sx={{ mb: 3 }} />

            <Grid container spacing={3}>
              {/* First column with company details and logo */}
              <Grid item xs={12} md={6}>
                <Typography variant="h6" fontWeight="bold" gutterBottom>
                  {t("Détails de l'entreprise")}
                </Typography>
                <Typography variant="h6">{t("Nom")} : {data.company_name}</Typography>
                <Typography variant="h6">
                  {t("Emplacement")} : {data.location}
                </Typography>
                <Typography variant="h6">
                  {t("Email")} : {data.contact_email}
                </Typography>
                <Typography variant="h6">
                  {t("Téléphone")} : {data.contact_phone}
                </Typography>
                <Typography variant="h6">{t("Adresse")} : {data.address}</Typography>

                {/* Display company logo if available */}
                {data && data.logo && (
                  <SoftBox display="flex" my={3}>
                    <img
                      src={data.logo}
                      alt="Company Logo"
                      style={{
                        width: "150px",
                        height: "auto",
                        borderRadius: "8px",
                      }}
                    />
                  </SoftBox>
                )}
              </Grid>

              {/* Second column with other information */}
              <Grid item xs={12} md={6}>
                {/* Fiscal Information */}
                <Grid container>
                  <Grid item xs={12}>
                    <Typography variant="h6" fontWeight="bold">
                      {t("Informations fiscales")}
                    </Typography>
                    <Typography variant="h6">
                      {t("Valeur du timbre")} : {data.stamp_value} TND
                    </Typography>
                    <Typography variant="h6">
                      {t("Valeur de la taxe")} : {data.tax_value}%
                    </Typography>
                    <Typography variant="h6">
                      {t("Code TVA")} : {data.code_tva}
                    </Typography>
                    <Typography variant="h6">
                      {t("Code BIC")} : {data.code_bic}
                    </Typography>
                  </Grid>
                </Grid>

                {/* Bank Details */}
                <Grid container>
                  <Grid item xs={12} mt={2}>
                    <Typography variant="h6" fontWeight="bold">
                      {t("Détails bancaires")}
                    </Typography>
                    <Typography variant="h6">{data.bank_details}</Typography>
                  </Grid>
                </Grid>

                {/* Additional Information */}
                <Grid container>
                  <Grid item xs={12} mt={2}>
                    <Typography variant="h6" fontWeight="bold">
                      {t("Informations supplémentaires")}
                    </Typography>
                    <Typography variant="h6">
                      {t("Date de création")} :{" "}
                      {new Date(data.date_created).toLocaleDateString("fr-FR")}
                    </Typography>
                    <Typography variant="h6">
                      {t("Dernière mise à jour")} :{" "}
                      {new Date(data.last_updated).toLocaleDateString("fr-FR")}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </SoftBox>
        </Card>

        <Footer />
      </SoftBox>
    </DashboardLayout>
  );
}

export default EntrepriseInfo;
