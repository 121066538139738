import Badge from "@mui/material/Badge"; // Import Badge
import PropTypes from "prop-types";
import Collapse from "@mui/material/Collapse";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Icon from "@mui/material/Icon";
import SoftBox from "components/SoftBox"; // Your custom SoftBox component

import {
  collapseItem,
  collapseIconBox,
  collapseIcon,
  collapseText,
  collapseArrow,
} from "examples/Sidenav/styles/sidenavCollapse";

import { useSoftUIController } from "context";

function SidenavCollapse({
  icon,
  name,
  children = false,
  active = false,
  noCollapse = false,
  open = false,
  color = "info",
  ...rest
}) {
  const [controller] = useSoftUIController();
  const { miniSidenav, transparentSidenav, sidenavColor } = controller;

  const draftOrdersCount = localStorage.getItem("draftOrdersCount");

  // Check if the child route has "commandes-encours"
  const isCommandesEncours =
    children && children[1]?.key === "commandes-encours";
  const renderBadgeForChild = (child) => {
    if (child.key === "commandes-encours") {
      return (
        <Badge
          color="error"
          badgeContent={draftOrdersCount} // Adjust this number as per your logic
          overlap="circular"
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
          sx={{
            "& .MuiBadge-badge": {
              marginRight: "-25px",
              marginTop: "12px",
            },
          }}
        >
          {child}
        </Badge>
      );
    }
    return child;
  };

  return (
    <>
      <ListItem component="li">
        <SoftBox
          {...rest}
          sx={(theme) => collapseItem(theme, { active, transparentSidenav })}
        >
          <ListItemIcon
            sx={(theme) =>
              collapseIconBox(theme, {
                active,
                transparentSidenav,
                sidenavColor,
              })
            }
          >
            {/* Wrap the icon with Badge if it's "commandes-encours" */}
            <Badge
              color="error"
              badgeContent={
                !open && isCommandesEncours ? draftOrdersCount : null
              } // Display badge content for commandes-encours
              overlap="circular"
              anchorOrigin={{ vertical: "top", horizontal: "right" }}
              sx={{
                "& .MuiBadge-badge": {
                  marginRight: "-10px",
                  marginTop: "-10px",
                },
              }}
            >
              {typeof icon === "string" ? (
                <Icon sx={(theme) => collapseIcon(theme, { active })}>
                  {icon}
                </Icon>
              ) : (
                icon
              )}
            </Badge>
          </ListItemIcon>

          <ListItemText
            primary={name}
            sx={(theme) =>
              collapseText(theme, { miniSidenav, transparentSidenav, active })
            }
          />

          <Icon
            sx={(theme) =>
              collapseArrow(theme, {
                noCollapse,
                transparentSidenav,
                miniSidenav,
                open,
              })
            }
          >
            expand_less
          </Icon>
        </SoftBox>
      </ListItem>

      {/* Render children with badges if necessary */}
      {children && (
        <Collapse in={open} unmountOnExit>
          {children.map((child) => renderBadgeForChild(child))}
        </Collapse>
      )}
    </>
  );
}

SidenavCollapse.propTypes = {
  color: PropTypes.oneOf([
    "primary",
    "secondary",
    "info",
    "success",
    "warning",
    "error",
    "dark",
  ]),
  icon: PropTypes.node.isRequired,
  name: PropTypes.string.isRequired,
  children: PropTypes.node,
  active: PropTypes.bool,
  noCollapse: PropTypes.bool,
  open: PropTypes.bool,
};

export default SidenavCollapse;
