import { useEffect, useState } from "react";

// @mui material components
import Grid from "@mui/material/Grid";

// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import ComplexStatisticsCard from "examples/Cards/StatisticsCards/ComplexStatisticsCard";
import ReportsApi from "api/reports";
import {
  Tooltip,
  ResponsiveContainer,
  PieChart,
  Pie,
  Cell,
  BarChart,
  CartesianGrid,
  XAxis,
  YAxis,
  Bar,
} from "recharts";
import SoftTypography from "components/SoftTypography";
import { Legend } from "chart.js";
import SoftButton from "components/SoftButton";
import { CircularProgress, Icon, Menu, MenuItem } from "@mui/material";
import { useTranslation } from "react-i18next";

const COLORS = [
  "#cb0c9f",
  "#8392ab",
  "#00669c",
  "#82d616",
  "#fbcf33",
  "#ea0606",
  "#ff4500",
  "#67748e",
  "#344767",
  "#3aaf85",
];

function Reports() {
  const [reportData, setReportData] = useState(null);
  const [filter, setFilter] = useState("month");
  const [anchorEl, setAnchorEl] = useState(null);
  const { t } = useTranslation();

  const filterText = {
    day: `${t("Rapport du Jour")}`,
    week: `${t("Rapport Par Semaine")}`,
    month: `${t("Rapport Par Mois")}`,
    year: `${t("Rapport Annuel")}`,
  };

  const handleFilterChange = (newFilter) => {
    setFilter(newFilter);
    setAnchorEl(null);
  };

  const handleFilterButtonClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    const fetchReportData = async () => {
      try {
        const token = localStorage.getItem("token");
        if (!token) {
          console.error("No token found in localStorage.");
          return;
        }
        const response = await ReportsApi.GetReports(token, filter);
        setReportData(response.data);
      } catch (error) {
        console.error("Error fetching report data", error);
      }
    };

    fetchReportData();
  }, [filter]);

  if (!reportData)
    return (
      <SoftBox
        display="flex"
        justifyContent="center"
        alignItems="center"
        p={45}
      >
        <CircularProgress sx={{ color: "#1F51FF" }} />
      </SoftBox>
    );

  const {
    daily_sales,
    sales_per_client,
    sales_per_article,
    sales_of_promo_articles,
    cash_balance,
  } = reportData;

  // Prepare data for pie charts
  const salesPerClientData =
    sales_per_client?.map((client) => ({
      name: client.client_username,
      value: client.total_sales,
    })) || [];

  const salesPerArticleData =
    sales_per_article?.map((article) => ({
      name: article.product_name,
      value: article.total_sales,
    })) || [];

  const salesPerPromotedArticleData =
    sales_of_promo_articles?.map((article) => ({
      name: article.product_name,
      value: article.total_sales,
    })) || [];

  // const columns = [
  //   { Header: "Client", accessor: "name" },
  //   { Header: "Chiffre d'affaires", accessor: "value" },
  // ];

  // const rows = salesPerClientData.map((data, index) => ({
  //   id: index + 1,
  //   name: data.name,
  //   value: data.value,
  // }));

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <SoftBox
        display="flex"
        justifyContent="space-between"
        alignItems="flex-end"
        mb={4}
        mt={1}
        sx={{
          flexDirection: { xs: "column", md: "row" },
          alignItems: { xs: "flex-start", md: "flex-end" },
        }}
      >
        <SoftButton
          variant="contained"
          color="dark"
          onClick={handleFilterButtonClick}
        >
          {filterText[filter]}
          <Icon>keyboard_arrow_down</Icon>
        </SoftButton>
        <Menu
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={handleMenuClose}
        >
          <MenuItem onClick={() => handleFilterChange("day")}>
            {t("Rapport du Jour")}
          </MenuItem>
          <MenuItem onClick={() => handleFilterChange("week")}>
            {t(" Rapport Par Semaine")}
          </MenuItem>
          <MenuItem onClick={() => handleFilterChange("month")}>
            {t("Rapport Par Mois")}
          </MenuItem>
          <MenuItem onClick={() => handleFilterChange("year")}>
            {t("Rapport Annuel")}
          </MenuItem>
        </Menu>
      </SoftBox>

      <SoftBox py={3}>
        <SoftBox>
          <Grid container spacing={3}>
            {/* Statistics Cards */}
            <Grid item xs={12} md={5}>
              <Grid container spacing={3}>
                <Grid item xs={12} md={8}>
                  <ComplexStatisticsCard
                    icon="monetization_on_icon"
                    count={{
                      number: daily_sales.total_sales.toFixed(2),
                      label: `${t("Chiffres d'affaire")}`,
                    }}
                    color="primary"
                  />
                </Grid>
                <Grid item xs={12} md={8}>
                  <ComplexStatisticsCard
                    icon="point_of_sale_icon"
                    count={{
                      number: cash_balance.total_cash_balance,
                      label: `${t("Caisse")}`,
                    }}
                    color="info"
                  />
                </Grid>
                <Grid item xs={12} md={8}>
                  <ComplexStatisticsCard
                    icon="storefront"
                    count={{
                      number: 15360,
                      label: `${t("Bénéfices")}`,
                    }}
                    color="secondary"
                  />
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={12} md={7}>
              <SoftBox
                sx={{
                  height: "450px",
                  padding: { xs: "15px", md: "20px" },
                  backgroundColor: "white",
                  borderRadius: "12px",
                  boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)",
                  border: "1px solid #e0e0e0",
                }}
              >
                <SoftTypography mb={2} variant="h5" textAlign="center">
                  {t("Chiffre d'affaires par clients")}
                </SoftTypography>
                <ResponsiveContainer width="100%" height="100%">
                  <BarChart
                    data={salesPerClientData}
                    margin={{ top: 20, right: 20, bottom: 40, left: 0 }}
                  >
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis
                      dataKey="name"
                      tick={{
                        fontSize: 12,
                        fill: "#666",
                        fontWeight: 600,
                      }}
                      angle={-45}
                      textAnchor="end"
                      height={60}
                      tickLine={false}
                      axisLine={{ stroke: "#e0e0e0" }}
                    />
                    <YAxis
                      tick={{
                        fontSize: 12,
                        fill: "#666",
                        fontWeight: 600,
                      }}
                      tickLine={false}
                      axisLine={{ stroke: "#e0e0e0" }}
                    />
                    <Tooltip />
                    <Legend />
                    <Bar dataKey="value" fill="#1E90FF" barSize={50} />
                  </BarChart>
                </ResponsiveContainer>
              </SoftBox>
            </Grid>
            <Grid container spacing={2} xs={12} mt={{ xs: 10, lg: 0 }}>
              <Grid item xs={12} lg={4}>
                <SoftBox mb={3} p={2} sx={{ height: "400px", margin: "auto" }}>
                  <SoftTypography mb={2} variant={"h5"} textAlign="center">
                    {t("Chiffre d'affaires par articles")}
                  </SoftTypography>
                  <ResponsiveContainer>
                    <PieChart>
                      <Pie
                        data={salesPerArticleData}
                        dataKey="value"
                        outerRadius={150}
                        innerRadius={50}
                        paddingAngle={2}
                        label={false}
                      >
                        {salesPerArticleData.map((entry, index) => (
                          <Cell
                            key={`cell-${index}`}
                            fill={COLORS[index % COLORS.length]}
                            stroke={COLORS[index % COLORS.length]}
                            padding={5}
                          />
                        ))}
                      </Pie>
                      <Tooltip
                        formatter={(value, name) => [`${value}`, name]}
                        contentStyle={{
                          backgroundColor: "#fff",
                          border: "none",
                          boxShadow: "0px 0px 6px rgba(0, 0, 0, 0.1)",
                        }}
                      />
                    </PieChart>
                  </ResponsiveContainer>
                </SoftBox>
              </Grid>

              {/* Display labels */}
              <Grid container xs={12} lg={6} ml={15} mt={10} mb={-2}>
                {salesPerArticleData.map((entry, index) => (
                  <Grid
                    item
                    xs={salesPerArticleData.length <= 4 ? 12 : 6}
                    key={entry.name}
                  >
                    <SoftBox
                      mt={2}
                      display="flex"
                      flexDirection="row"
                      alignItems="center"
                      sx={{ width: "100%" }}
                    >
                      <SoftBox
                        sx={{
                          backgroundColor: COLORS[index % COLORS.length],
                          width: "16px",
                          height: "16px",
                          marginRight: "8px",
                          borderRadius: "3px",
                        }}
                      />
                      <SoftTypography variant={"body2"}>
                        {entry.name}
                      </SoftTypography>
                    </SoftBox>
                  </Grid>
                ))}
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <SoftBox mb={3} mt={12} p={2} sx={{ height: "600px" }}>
                <SoftTypography mb={2} variant={"h5"} textAlign="center">
                  {t("Chiffre d'affaires des articles en promo")}
                </SoftTypography>
                <ResponsiveContainer>
                  <BarChart
                    data={salesPerPromotedArticleData}
                    margin={{ top: 20, right: 20, bottom: 40, left: 0 }}
                  >
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis
                      dataKey="name"
                      tick={{ fontSize: 14 }}
                      angle={-45}
                      textAnchor="end"
                      height={200}
                    />
                    <YAxis />
                    <Tooltip />
                    <Legend />
                    <Bar dataKey="value" fill="#1E90FF" barSize={30} />
                  </BarChart>
                </ResponsiveContainer>
              </SoftBox>
            </Grid>
          </Grid>
        </SoftBox>
      </SoftBox>
      <Footer />
    </DashboardLayout>
  );
}

export default Reports;
