import React, { useEffect, useState } from "react";
import Card from "@mui/material/Card";
import CircularProgress from "@mui/material/CircularProgress";
import SoftBox from "components/SoftBox";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DataTable from "examples/Tables/DataTable";
import StockApi from "../../../api/stock";
import ActionCell from "./components/ActionCell";
import { exportDataToCSV } from "utils/csvUtils";
import SoftButton from "components/SoftButton";
import { useNavigate } from "react-router-dom";
import { Checkbox, Icon, Menu, MenuItem } from "@mui/material";
import SoftTypography from "components/SoftTypography";
import { useTranslation } from "react-i18next";

function StockOfAllWarehouses() {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const [stock, setStock] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectedStock, setSelectedStock] = useState([]);
  const [menu, setMenu] = useState(null);
  const openMenu = (event) => setMenu(event.currentTarget);
  const closeMenu = () => setMenu(null);
  const warehouseName = localStorage.getItem("warehouseName");

  useEffect(() => {
    handleGetStockOfAllWarehouses();
  }, []);

  const handleGetStockOfAllWarehouses = () => {
    const token = localStorage.getItem("token");
    if (!token) {
      console.error("No token found in localStorage.");
      return;
    }

    StockApi.GetStockOfAllWarehouses(token)
      .then((response) => {
        if (response && response.data.data) {
          setStock(response.data.data);
        } else {
          console.warn("No data found:", response.data.msg);
        }
      })
      .catch((error) => {
        if (error.response) {
          console.error("Error response data:", error.response.data.msg);
        } else {
          console.error("There has been an error.");
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const formatStockData = (warehouses) => {
    const stockData = [];
    const warehouseNames = [];

    warehouses.forEach((warehouse) => {
      const warehouseName = Object.keys(warehouse)[0]; // Get warehouse name
      warehouseNames.push(warehouseName); // Add to list of warehouse names
      const products =
        warehouse[warehouseName].remaining_stock_for_all_products;

      for (const productId in products) {
        const product = products[productId];
        const existingProduct = stockData.find(
          (item) => item.product_id === product.product_id
        );

        if (existingProduct) {
          // Add warehouse quantity to the existing product entry
          existingProduct.warehouses[warehouseName] = product.remaining_stock;
        } else {
          // Create a new product entry with warehouse data
          const newProduct = {
            product_id: product.product_id,
            product_name: product.product_name,
            warehouses: {
              [warehouseName]: product.remaining_stock,
            },
            quantity: product.remaining_stock,
          };
          stockData.push(newProduct);
        }
      }
    });

    return { stockData, warehouseNames };
  };
  // Using the function
  const { stockData, warehouseNames } = formatStockData(stock);

  const formatStockDataToPass = (warehouses) => {
    const stockData = [];

    warehouses.forEach((warehouse) => {
      const currentWarehouseName = Object.keys(warehouse)[0]; // Get warehouse name
      const products =
        warehouse[currentWarehouseName].remaining_stock_for_all_products;

      for (const productId in products) {
        const product = products[productId];

        // Filter products by excluding the current warehouse name
        if (currentWarehouseName !== warehouseName) {
          const newProduct = {
            product_id: product.product_id,
            product_name: product.product_name,
            quantity: product.remaining_stock,
          };
          stockData.push(newProduct);
        }
      }
    });

    return stockData; // Return only filtered stock data
  };

  const handleSelectStock = (item) => {
    setSelectedStock((prevSelected) =>
      prevSelected.some((stockItem) => stockItem.product_id === item.product_id)
        ? prevSelected.filter(
            (stockItem) => stockItem.product_id !== item.product_id
          )
        : [...prevSelected, item]
    );
  };

  const tableData = {
    columns: [
      {
        Header: `${t("Nom du produit")}`,
        accessor: "select_product",
        Cell: ({ cell }) => {
          const hasStock = Object.values(cell.row.original.warehouses).some(
            (quantity) => quantity > 0
          );
          // Check if the product exists in the warehouse specified by warehouseName
          const existsInWarehouse =
            cell.row.original.warehouses[warehouseName] > 0;

          return (
            <div style={{ display: "flex", alignItems: "center" }}>
              <Checkbox
                checked={selectedStock.some(
                  (stockItem) =>
                    stockItem.product_id === cell.row.original.product_id
                )}
                onChange={() =>
                  hasStock &&
                  !existsInWarehouse &&
                  handleSelectStock({
                    product_id: cell.row.original.product_id,
                    product_name: cell.row.original.product_name,
                    warehouses: cell.row.original.warehouses,
                  })
                }
                disabled={!hasStock}
              />
              <span style={{ marginLeft: 8 }}>
                {cell.row.original.product_name}
              </span>
            </div>
          );
        },
      },
      ...warehouseNames.map((warehouse) => ({
        Header: warehouse,
        accessor: `warehouses.${warehouse}`,
        Cell: ({ cell }) => cell.value || 0, // Show 0 if no stock available for that warehouse
      })),
      { Header: `${t("Demander")}`, accessor: "demander" },
    ],

    rows: stockData.map((item) => {
      const hasStock = Object.values(item.warehouses).some(
        (quantity) => quantity > 0
      );

      // Check if the product exists in the warehouse specified by warehouseName
      const existsInWarehouse = item.warehouses[warehouseName] > 0;

      return {
        product_id: item.product_id,
        product_name: item.product_name,
        warehouses: item.warehouses,
        demander:
          hasStock && !existsInWarehouse ? ( // Don't render ActionCell if it exists in the warehouse
            <ActionCell
              selectedStock={[
                {
                  product_id: item.product_id,
                  product_name: item.product_name,
                  warehouses: item.warehouses,
                },
              ]}
              stockData={formatStockDataToPass(stock)}
            />
          ) : null, // Don't render ActionCell if no stock or it exists in the warehouse
      };
    }),
  };

  const handleExportToCSV = () => {
    const exportColumns = tableData.columns.filter(
      (column) => column.accessor !== "demander" // Adjusted to exclude the correct accessor
    );
    const exportRows = tableData.rows.map(({ demander, ...row }) => row);
    exportDataToCSV(exportColumns, exportRows, "stock.csv");
  };

  const handleViewSelectedStock = () => {
    navigate("/gestion-de-stock/demande-de-stock", {
      state: { selectedStock, stockData: formatStockDataToPass(stock) },
    });
  };

  const renderMenu = (
    <Menu
      anchorEl={menu}
      anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
      transformOrigin={{ vertical: "top", horizontal: "left" }}
      open={Boolean(menu)}
      onClose={closeMenu}
      keepMounted
    >
      <MenuItem onClick={handleViewSelectedStock}>
        <SoftTypography variant="button" fontWeight="regular">
          {t("Demande tranfert de stock en produits sélectionnés")}
        </SoftTypography>
      </MenuItem>
      <MenuItem onClick={() => setSelectedStock([])}>
        <SoftTypography variant="button" color="error" fontWeight="regular">
          {t("Annuler la sélection")}
        </SoftTypography>
      </MenuItem>
    </Menu>
  );

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <SoftBox
        display="flex"
        flexDirection="column"
        justifyContent="space-between"
        minHeight="85vh"
      >
        <SoftBox my={3}>
          {!loading && (
            <SoftBox display="flex" justifyContent="space-between" mb={2}>
              <SoftBox display="flex" columnGap={3}>
                <SoftButton
                  variant={menu ? "contained" : "outlined"}
                  color="dark"
                  onClick={openMenu}
                  disabled={selectedStock.length === 0} // Changed to check selectedStock
                >
                  {t("Produits sélectionnés")}&nbsp;
                  <Icon>keyboard_arrow_down</Icon>
                </SoftButton>
                {renderMenu}
              </SoftBox>
              <SoftButton
                variant="gradient"
                color="info"
                onClick={handleExportToCSV}
              >
                {t("Exporter la Situation")}
              </SoftButton>
            </SoftBox>
          )}
          <Card>
            {loading ? (
              <SoftBox
                display="flex"
                justifyContent="center"
                alignItems="center"
                p={35}
              >
                <CircularProgress sx={{ color: "#1F51FF" }} />
              </SoftBox>
            ) : (
              <DataTable
                table={tableData}
                entriesPerPage={{
                  defaultValue: 10,
                  entries: [5, 10, 15, 20, 25],
                }}
                canSearch
                showTotalEntries={true}
                pagination={true}
              />
            )}
          </Card>
        </SoftBox>
        <Footer />
      </SoftBox>
    </DashboardLayout>
  );
}

export default StockOfAllWarehouses;
