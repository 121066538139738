import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { handleUpload } from "utils/ImageUploader";

function Media({ onChange, validateFormCallback, touched }) {
  const [idCardImage, setIdCardImage] = useState("");
  const [patentImage, setPatentImage] = useState("");
  const [errors, setErrors] = useState({});
  const { t } = useTranslation();

  useEffect(() => {
    validateForm();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [idCardImage, patentImage]);

  const handleImageUpload = async (event, imageType) => {
    const file = event.target.files[0];

    try {
      const imageUrl = await handleUpload(file);
      if (imageType === "id_card") {
        setIdCardImage(imageUrl);
        onChange({ id_card_image: imageUrl });
        setErrors((prevErrors) => ({ ...prevErrors, id_card: null }));
      } else if (imageType === "patent") {
        setPatentImage(imageUrl);
        onChange({ patent_image: imageUrl });
        setErrors((prevErrors) => ({ ...prevErrors, patent: null }));
      }
    } catch (error) {
      console.error("Error uploading image:", error);
      // Handle error state if needed
    }
  };

  const validateForm = () => {
    const newErrors = {};
    if (!idCardImage) {
      newErrors.id_card = `${t("L'image du ID Card est requise")}`;
    }
    if (!patentImage) {
      newErrors.patent = `${t("L'image du Patent est requise")}`;
    }
    setErrors(newErrors);
    validateFormCallback(Object.keys(newErrors).length === 0);
  };

  return (
    <SoftBox>
      <SoftTypography variant="h5">{t("Média")}</SoftTypography>
      <SoftBox mt={2} p={1} display="flex">
        {/* ID Card Image */}
        <div style={{ marginRight: 20, flex: 1 }}>
          <SoftBox mb={1}>
            <SoftTypography
              component="label"
              variant="caption"
              fontWeight="bold"
            >
              {t("Image du ID Card")}
            </SoftTypography>
            <input
              type="file"
              onChange={(event) => handleImageUpload(event, "id_card")}
              accept="image/*"
              style={{ width: "100%" }}
            />
            {idCardImage && (
              <img
                src={idCardImage}
                alt="ID Card"
                style={{ maxWidth: "90%", marginTop: 10 }}
              />
            )}
            {touched && errors.id_card && (
              <SoftTypography variant="caption" color="error">
                {errors.id_card}
              </SoftTypography>
            )}
          </SoftBox>
        </div>

        {/* Patent Image */}
        <div style={{ flex: 1 }}>
          <SoftTypography component="label" variant="caption" fontWeight="bold">
            {t("Image du Patent")}
          </SoftTypography>
          <input
            type="file"
            onChange={(event) => handleImageUpload(event, "patent")}
            accept="image/*"
            style={{ width: "100%" }}
          />
          {patentImage && (
            <img
              src={patentImage}
              alt="Patent"
              style={{ maxWidth: "90%", marginTop: 10 }}
            />
          )}
          {touched && errors.patent && (
            <SoftTypography variant="caption" color="error">
              {errors.patent}
            </SoftTypography>
          )}
        </div>
      </SoftBox>
    </SoftBox>
  );
}

export default Media;
