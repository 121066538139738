import axios from "./index";

// InvoicesApi class handles operations related to invoices and delivery notes
class InvoicesApi {
  /**
   * Creates a new invoice by sending a POST request with invoice data.
   * The request includes an Authorization header with the Bearer token.
   * @param {Object} data - The invoice data to be sent in the request body.
   * @param {string} token - The authentication token for the user.
   * @returns {Promise} - The response after creating the invoice.
   */
  static CreateInvoices = (data, token) => {
    return axios.post(`${base}/invoices`, data, {
      headers: { Authorization: `Bearer ${token}` },
    });
  };

  /**
   * Fetches the list of all invoices by sending a GET request.
   * The request includes an Authorization header with the Bearer token.
   * @param {string} token - The authentication token for the user.
   * @returns {Promise} - The response containing the list of all invoices.
   */
  static getInvoices = (token, page) => {
    return axios.get(`${base}/invoices_list?page=${page}`, {
      headers: { Authorization: `Bearer ${token}` },
    });
  };

  /**
   * Fetches the details of a specific invoice by its order ID.
   * The request includes an Authorization header with the Bearer token.
   * @param {string} token - The authentication token for the user.
   * @param {string|number} orderId - The ID of the order for which the invoice is requested.
   * @returns {Promise} - The response containing the invoice details.
   */
  static GetInvoicesById = (token, orderId) => {
    return axios.get(`${base}/invoices_list/${orderId}`, {
      headers: { Authorization: `Bearer ${token}` },
    });
  };

  /**
   * Fetches the details of a specific delivery note by its ID.
   * The request includes an Authorization header with the Bearer token.
   * @param {string} token - The authentication token for the user.
   * @param {string|number} deliveryNoteId - The ID of the delivery note.
   * @returns {Promise} - The response containing the delivery note details.
   */
  static GetDeliveryNotesById = (token, deliveryNoteId) => {
    return axios.get(`/invoices/delivery_notes/${deliveryNoteId}`, {
      headers: { Authorization: `Bearer ${token}` },
    });
  };
}

// Base URL for the order-related API
let base = "order";

export default InvoicesApi;
