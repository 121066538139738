import axios from "./index";

// OrdersApi class handles operations related to different types of orders
class OrdersApi {
  // Shopping Items
  /**
   * Fetches all shopping items with pagination.
   * @param {string} token - The authentication token for the user.
   * @param {number} page - The page number for paginated results.
   * @returns {Promise} - The response containing the list of shopping items for the specified page.
   */
  static GetShoppingItems = (token, page) => {
    return axios.get(`accounts/all_shopping_items/?p=${page}`, {
      headers: { Authorization: `Bearer ${token}` },
    });
  };

  // Draft Orders
  /**
   * Fetches a list of draft orders with pagination.
   * @param {string} token - The authentication token for the user.
   * @param {number} [page=1] - The page number for paginated results.
   * @returns {Promise} - The response containing the list of draft orders for the specified page.
   */
  static GetDraftOrders = (token, page = 1) => {
    return axios.get(`${base}/draft_orders?p=${page}`, {
      headers: { Authorization: `Bearer ${token}` },
    });
  };

  /**
   * Creates a new draft order.
   * @param {string} token - The authentication token for the user.
   * @param {Object} data - The draft order data to be sent in the request body.
   * @returns {Promise} - The response after creating the draft order.
   */
  static CreateDraftOrders = (token, data) => {
    return axios.post(`order/order-warehouse`, data, {
      headers: { Authorization: `Bearer ${token}` },
    });
  };

  /**
   * Cancels a draft order by its ID.
   * @param {string} token - The authentication token for the user.
   * @param {string|number} orderId - The ID of the draft order to be canceled.
   * @returns {Promise} - The response after canceling the draft order.
   */
  static CancelDraftOrders = (token, orderId) => {
    return axios.post(
      `${base}/cancel_draft/${orderId}`,
      {},
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
  };

  /**
   * Confirms a draft order by its ID.
   * @param {string} token - The authentication token for the user.
   * @param {string|number} orderId - The ID of the draft order to be confirmed.
   * @returns {Promise} - The response after confirming the draft order.
   */
  static ConfirmDraftOrders = (token, orderId) => {
    return axios.post(
      `${base}/confirm_draft/${orderId}`,
      {},
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
  };

  // On the Way Orders
  /**
   * Fetches a list of orders that are on the way with pagination.
   * @param {string} token - The authentication token for the user.
   * @param {number} [page=1] - The page number for paginated results.
   * @returns {Promise} - The response containing the list of on-the-way orders for the specified page.
   */
  static GetOnWayOrders = (token, page = 1) => {
    return axios.get(`${base}/ontheway_orders?p=${page}`, {
      headers: { Authorization: `Bearer ${token}` },
    });
  };

  /**
   * Cancels an order that is on the way by its ID.
   * @param {string} token - The authentication token for the user.
   * @param {string|number} orderId - The ID of the on-the-way order to be canceled.
   * @returns {Promise} - The response after canceling the on-the-way order.
   */
  static CancelOnWayOrders = (token, orderId) => {
    return axios.post(
      `${base}/cancel_on_way/${orderId}`,
      {},
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
  };

  /**
   * Marks an on-the-way order as delivered by its ID.
   * @param {string} token - The authentication token for the user.
   * @param {string|number} orderId - The ID of the on-the-way order to be marked as delivered.
   * @returns {Promise} - The response after marking the on-the-way order as delivered.
   */
  static DeliverOnWayOrders = (token, orderId) => {
    return axios.post(
      `${base}/delivered_on_way/${orderId}`,
      {},
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
  };

  // Confirmed Orders
  /**
   * Fetches a list of confirmed orders with pagination.
   * @param {string} token - The authentication token for the user.
   * @param {number} [page=1] - The page number for paginated results.
   * @returns {Promise} - The response containing the list of confirmed orders for the specified page.
   */
  static GetConfirmedOrders = (token, page = 1) => {
    return axios.get(`${base}/confirmed_orders?p=${page}`, {
      headers: { Authorization: `Bearer ${token}` },
    });
  };

  /**
   * Cancels a confirmed order by its ID.
   * @param {string} token - The authentication token for the user.
   * @param {string|number} orderId - The ID of the confirmed order to be canceled.
   * @returns {Promise} - The response after canceling the confirmed order.
   */
  static CancelConfirmedOrders = (token, orderId) => {
    return axios.post(
      `${base}/cancel_confirmed/${orderId}`,
      {},
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
  };

  /**
   * Marks a confirmed order as on its way by its ID.
   * @param {string} token - The authentication token for the user.
   * @param {string|number} orderId - The ID of the confirmed order to be marked as on its way.
   * @returns {Promise} - The response after marking the confirmed order as on its way.
   */
  static OnWayConfirmedOrders = (token, orderId) => {
    return axios.post(
      `${base}/on_way_confirmed/${orderId}`,
      {},
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
  };

  // Delivered Orders
  /**
   * Fetches a list of all delivered orders.
   * @param {string} token - The authentication token for the user.
   * @returns {Promise} - The response containing the list of all delivered orders.
   */
  static GetAllDeliveredOrders = (token) => {
    return axios.get(`${base}/delivered_list`, {
      headers: { Authorization: `Bearer ${token}` },
    });
  };

  /**
   * Fetches delivered orders by client ID.
   * @param {string} token - The authentication token for the user.
   * @param {Object} clientID - The ID of the client whose delivered orders are requested.
   * @returns {Promise} - The response containing the delivered orders for the specified client.
   */
  static GetDeliveredOrdersByClient = (token, clientID) => {
    return axios.post(`${base}/delivered_list_by_client`, clientID, {
      headers: { Authorization: `Bearer ${token}` },
    });
  };

  /**
   * Fetches a paginated list of delivered orders.
   * @param {string} token - The authentication token for the user.
   * @param {number} [page=1] - The page number for paginated results.
   * @returns {Promise} - The response containing the list of delivered orders for the specified page.
   */
  static GetDeliveredOrders = (token, page = 1) => {
    return axios.get(`${base}/delivered_orders?p=${page}`, {
      headers: { Authorization: `Bearer ${token}` },
    });
  };

  // Cancelled Orders
  /**
   * Fetches a paginated list of canceled orders.
   * @param {string} token - The authentication token for the user.
   * @param {number} [page=1] - The page number for paginated results.
   * @returns {Promise} - The response containing the list of canceled orders for the specified page.
   */
  static GetCancelledOrders = (token, page = 1) => {
    return axios.get(`${base}/cancelled_orders?p=${page}`, {
      headers: { Authorization: `Bearer ${token}` },
    });
  };

  /**
   * Fetches the order lines for a specific order by its ID.
   * @param {string|number} orderId - The ID of the order whose lines are requested.
   * @param {string} token - The authentication token for the user.
   * @returns {Promise} - The response containing the order lines for the specified order.
   */
  static GetOrderlines = (orderId, token) => {
    return axios.get(`${base}/get_order_lines/${orderId}`, {
      headers: { Authorization: `Bearer ${token}` },
    });
  };

  /**
   * Processes payment details for a specific order by its ID.
   * @param {string|number} orderId - The ID of the order for which payment details are processed.
   * @param {Object} data - The payment details to be sent in the request body.
   * @param {string} token - The authentication token for the user.
   * @returns {Promise} - The response after processing the payment details.
   */
  static Payment = (orderId, data, token) => {
    return axios.post(`payment/order/${orderId}/payment_details/v1`, data, {
      headers: { Authorization: `Bearer ${token}` },
    });
  };
}

// Base URL for the order-related API
let base = "order";

export default OrdersApi;
