import React from "react";
import GooglePlacesAutocomplete, {
  geocodeByPlaceId,
  getLatLng,
} from "react-google-places-autocomplete";
import { useTranslation } from "react-i18next";

const GooglePlacesInput = ({ onPlaceSelected, onInputChange }) => {
  const { t } = useTranslation();
  const handleSelect = async (value) => {
    const placeId = value?.value?.place_id;

    if (placeId) {
      try {
        const results = await geocodeByPlaceId(placeId);
        const latLng = await getLatLng(results[0]);
        onPlaceSelected({
          address: value.label,
          latitude: latLng.lat,
          longitude: latLng.lng,
        });
      } catch (error) {
        console.error("Error fetching geolocation:", error);
      }
    }
  };

  const handleChange = (value) => {
    onInputChange(value);
  };

  return (
    <GooglePlacesAutocomplete
      apiKey="AIzaSyDXaSWO7Ye3o87IjizMoSBqHQ-uKoYErs0"
      autocompletionRequest={{
        componentRestrictions: { country: "tn" },
      }}
      selectProps={{
        onChange: handleSelect,
        onInputChange: handleChange,
        placeholder: `${t("Entrez une adresse")}`,
      }}
      onLoadFailed={(error) =>
        console.error("Autocomplete load failed:", error)
      }
    />
  );
};

export default GooglePlacesInput;
