// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";

// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";

// Soft UI Dashboard PRO React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";

// ProductPage page components
import ProductInfo from "./components/ProductInfo";
import { useEffect, useState } from "react";
import ProductsApi from "api/products";
import { useParams } from "react-router-dom";
import { CircularProgress } from "@mui/material";
import { useTranslation } from "react-i18next";

/**
 * `ProductPage` component displays detailed information about a product.
 * It fetches product data based on the product ID from the URL parameters.
 */
function ProductPage() {
  const { t } = useTranslation();

  // Extracts the product ID from the URL parameters
  const { productId } = useParams();

  // State for holding product data
  const [product, setProduct] = useState();
  // State for handling loading state
  const [loading, setLoading] = useState(true);

  /**
   * Fetches the product data by product ID and updates state.
   */
  const handleGetProduct = () => {
    const token = localStorage.getItem("token");

    if (!token) {
      console.error("No token found in localStorage.");
      setLoading(false);
      return;
    }

    ProductsApi.GetProductById(productId, token)
      .then((response) => {
        if (response.data) {
          setProduct(response.data);
        } else {
          console.warn("No data found:", response.data.msg);
        }
      })
      .catch((error) => {
        if (error.response) {
          console.error("Error response data:", error.response.data.msg);
          console.error("Full error response:", error.response);
        } else {
          console.error("There has been an error.");
        }
      })
      .finally(() => setLoading(false));
  };

  // Effect hook to fetch product data on component mount
  useEffect(() => {
    handleGetProduct();
    // eslint-disable-next-line
  }, []);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <SoftBox
        display="flex"
        flexDirection="column"
        justifyContent="space-between"
        minHeight="85vh"
      >
        <SoftBox py={3}>
          <Card sx={{ overflow: "visible" }}>
            {loading ? (
              <SoftBox
                display="flex"
                justifyContent="center"
                alignItems="center"
                p={35}
              >
                <CircularProgress sx={{ color: "#1F51FF" }} />
              </SoftBox>
            ) : (
              <SoftBox p={3}>
                <SoftBox mb={3}>
                  <SoftTypography variant="h5" fontWeight="medium">
                    {t("Détails du produit")}
                  </SoftTypography>
                </SoftBox>

                <Grid container spacing={3}>
                  <Grid item xs={12} lg={6} xl={5}>
                    <SoftBox
                      component="img"
                      src={product?.product_image}
                      alt="Product Image"
                      shadow="lg"
                      borderRadius="lg"
                      width="100%"
                    />
                  </Grid>
                  <Grid item xs={12} lg={5} sx={{ mx: "auto" }}>
                    <ProductInfo product={product} />
                  </Grid>
                </Grid>
              </SoftBox>
            )}
          </Card>
        </SoftBox>
        <Footer />
      </SoftBox>
    </DashboardLayout>
  );
}

export default ProductPage;
