import axios from "./index";

// NotificationsApi class handles operations related to notifications
class NotificationsApi {
  /**
   * Fetches a paginated list of notifications by sending a GET request.
   * The request includes an Authorization header with the Bearer token.
   * @param {string} token - The authentication token for the user.
   * @param {number} page - The page number for paginated results.
   * @returns {Promise} - The response containing the list of notifications for the specified page.
   */
  static GetNotifications = (token, page) => {
    return axios.get(`${base}/notifications?page=${page}`, {
      headers: { Authorization: `Bearer ${token}` },
    });
  };

  /**
   * Creates a new notification by sending a POST request with notification data.
   * The request includes an Authorization header with the Bearer token.
   * @param {Object} data - The notification data to be sent in the request body.
   * @param {string} token - The authentication token for the user.
   * @returns {Promise} - The response after creating the notification.
   */
  static CreateNotification = (data, token) => {
    return axios.post(`${base}/notifications`, data, {
      headers: { Authorization: `Bearer ${token}` },
    });
  };
}

// Base URL for the notification-related API
let base = "accounts";

export default NotificationsApi;
