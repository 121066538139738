import React, { useEffect, useState } from "react";
import Card from "@mui/material/Card";
import SoftBox from "components/SoftBox";
import SoftButton from "components/SoftButton";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DataTable from "examples/Tables/DataTable";
import { CircularProgress, Pagination, Stack } from "@mui/material";
import { Link } from "react-router-dom";
import NotificationsApi from "api/notifications";
import { useTranslation } from "react-i18next";

function NotificationsList() {
  const [notifications, setNotifications] = useState([]);
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const { t } = useTranslation();

  const fetchNotifications = async (page) => {
    try {
      setLoading(true);

      const token = localStorage.getItem("token");
      const response = await NotificationsApi.GetNotifications(token, page);
      setNotifications(response.data.results);
      setTotalPages(Math.ceil(response.data.count / 10));
      setLoading(false);
    } catch (error) {
      console.error("Failed to fetch notifications:", error);
      setLoading(false);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchNotifications(page);
  }, [page]);

  const handlePageChange = (event, value) => {
    setPage(value);
  };

  const columns = [
    { Header: t("Titre"), accessor: "title" },
    { Header: t("Description"), accessor: "description" },
    { Header: t("Date de création"), accessor: "created_at" },
  ];

  const rows = notifications.map((notification) => ({
    title: notification.title,
    description: notification.description,
    created_at: new Date(notification.created_at).toLocaleString(),
  }));

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <SoftBox mb={2}>
        <Link to="/gestion-des-promotions/nouvelle-notification">
          <SoftButton variant="gradient" color="info">
            + {t("Nouvelle notification")}
          </SoftButton>
        </Link>
      </SoftBox>
      <SoftBox
        display="flex"
        flexDirection="column"
        justifyContent="space-between"
        minHeight="80vh"
      >
        <Card sx={{ maxWidth: { xl: "70%" }, width: "100%" }}>
          <SoftBox p={3}>
            {loading ? (
              <SoftBox
                display="flex"
                justifyContent="center"
                alignItems="center"
                p={35}
              >
                <CircularProgress sx={{ color: "#1F51FF" }} />
              </SoftBox>
            ) : (
              <>
                <DataTable
                  table={{ columns, rows }}
                  entriesPerPage={false}
                  canSearch
                />
                {totalPages > 1 && (
                  <Stack
                    spacing={2}
                    sx={{
                      mt: 2,
                      position: "absolute",
                      bottom: 20,
                      left: 200,
                      transform: "translateX(-50%)",
                    }}
                  >
                    <Pagination
                      count={totalPages}
                      page={page}
                      onChange={handlePageChange}
                      color="secondary"
                    />
                  </Stack>
                )}
              </>
            )}
          </SoftBox>
        </Card>
        <Footer />
      </SoftBox>
    </DashboardLayout>
  );
}

export default NotificationsList;
