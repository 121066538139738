import React from "react";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import DataTable from "examples/Tables/DataTable";
import { useTranslation } from "react-i18next";

function Banque() {
  const { t } = useTranslation();

  const staticData = [
    {
      date: "1/2/2024",
      type_operation: "Paie",
      description: "Virement Salaire",
      entree: 0.0,
      sortie: 1940.0,
      solde: 410.0,
    },
    {
      date: "1/2/2024",
      type_operation: "Vente",
      description: "Cheque Recu Client BBK",
      entree: 2350.0,
      sortie: 0.0,
      solde: 2760.0,
    },
    {
      date: "3/3/2024",
      type_operation: "Vente",
      description: "Virement Client BBK",
      entree: 3280.0,
      sortie: 0.0,
      solde: 6040.0,
    },
    {
      date: "3/15/2024",
      type_operation: "Achat",
      description: "Paiment Facture",
      entree: 0.0,
      sortie: 3100.0,
      solde: 2940.0,
    },
    {
      date: "4/14/2024",
      type_operation: "Depenses",
      description: "Paiement loyer",
      entree: 0.0,
      sortie: 420.0,
      solde: 2520.0,
    },
    {
      date: "4/30/2024",
      type_operation: "Vente",
      description: "Cheque Recu Client Afro best",
      entree: 3250.0,
      sortie: 0.0,
      solde: 5770.0,
    },
    {
      date: "5/15/2024",
      type_operation: "Achat",
      description: "Fournisseur de Mayonnaise",
      entree: 0.0,
      sortie: 2600.0,
      solde: 3170.0,
    },
    {
      date: "7/31/2024",
      type_operation: "Vente",
      description: "Virement Recu Afro best",
      entree: 2630.0,
      sortie: 0.0,
      solde: 5800.0,
    },
  ];

  // Define columns for DataTable
  const columns = [
    { Header: "Date", accessor: "date" },
    { Header: t("Type operation"), accessor: "type_operation" },
    { Header: t("Description"), accessor: "description" },
    { Header: t("Entrée"), accessor: "entree" },
    { Header: t("Sortie"), accessor: "sortie" },
    { Header: t("Solde"), accessor: "solde" },
  ];

  // Map staticData to rows for DataTable
  const rows = staticData.map((row) => ({
    date: row.date,
    type_operation: row.type_operation,
    description: row.description,
    entree: row.entree ? row.entree.toFixed(2) : "-",
    sortie: row.sortie ? row.sortie.toFixed(2) : "-",
    solde: row.solde.toFixed(2),
  }));

  const totalEntree = staticData.reduce((sum, row) => sum + row.entree, 0);
  const totalSortie = staticData.reduce((sum, row) => sum + row.sortie, 0);
  const finalSolde = 3170;

  rows.push({
    date: "",
    type_operation: <strong>{t("Total Mouvements")}</strong>,
    description: "",
    entree: <strong>{totalEntree.toFixed(2)}</strong>,
    sortie: <strong>{totalSortie.toFixed(2)}</strong>,
    solde: "",
  });

  rows.push({
    date: "",
    type_operation: "",
    description: <strong>{t("Solde")}</strong>,
    entree: "",
    sortie: "",
    solde: <strong>3170.00</strong>,
  });

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <SoftBox
        display="flex"
        flexDirection="column"
        justifyContent="space-between"
        minHeight="85vh"
      >
        <SoftBox m={3}>
          <Grid container spacing={3} justifyContent="center">
            <Grid item xs={12}>
              <Card>
                <SoftBox pt={3}>
                  <SoftTypography variant={"h4"} textAlign="center">
                    Banque STB
                  </SoftTypography>
                  <SoftTypography variant={"h6"} textAlign="right" mr={3}>
                    8/31/2024
                  </SoftTypography>
                </SoftBox>
                <SoftBox px={3} pb={3}>
                  <SoftTypography variant={"h6"} textAlign="right">
                    {t("Solde Ouverture")}: 2,350.00
                  </SoftTypography>
                  <SoftTypography variant={"h6"} textAlign="right">
                    {t("Solde finale")}: {finalSolde.toFixed(2)}
                  </SoftTypography>
                </SoftBox>
                <SoftBox p={3}>
                  {/* DataTable */}
                  <DataTable
                    table={{ columns, rows }}
                    entriesPerPage={false}
                    columnMinWidth={70}
                  />
                </SoftBox>
              </Card>
            </Grid>
          </Grid>
        </SoftBox>
        <Footer />
      </SoftBox>
    </DashboardLayout>
  );
}

export default Banque;
