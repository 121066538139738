import React, { useEffect, useState, useRef, useCallback } from "react";
import { Link } from "react-router-dom";
import {
  Card,
  CircularProgress,
  Stack,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Snackbar,
  Menu,
  MenuItem,
} from "@mui/material";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DataTable from "examples/Tables/DataTable";
import ProductCell from "./components/ProductCell";
import ActionCell from "./components/ActionCell";
import ProductsApi from "api/products";
import {
  getDownloadURL,
  ref,
  uploadBytesResumable,
  getMetadata,
} from "firebase/storage";
import { storage } from "../../../firebase";
import { Pagination } from "@mui/material";
import { exportDataToCSV } from "utils/csvUtils";
import SoftBox from "components/SoftBox";
import SoftButton from "components/SoftButton";
import MuiAlert from "@mui/material/Alert";
import SoftInput from "components/SoftInput";
import { useTranslation } from "react-i18next";

function ProductsList() {
  const [products, setProducts] = useState([]);
  const [productsToExtract, setProductsToExtract] = useState([]);
  const [loading, setLoading] = useState(true);
  const [loadingExtract, setLoadingExtract] = useState(false);
  const [showLoadingExtract, setShowLoadingExtract] = useState(false);
  const [file, setFile] = useState(null);
  const [openModal, setOpenModal] = useState(false);
  const [uploading, setUploading] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const fileInputRef = useRef(null);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [anchorEl, setAnchorEl] = useState(null);

  const [dataFetched, setDataFetched] = useState(false);
  const [exportPending, setExportPending] = useState(false);
  const [search, setSearch] = useState("");
  const [loadingSearch, setLoadingSearch] = useState(false);
  const { t } = useTranslation();

  useEffect(() => {
    handleGetProducts(page, search);
  }, [page, search]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        await handleGetProductsToExtract();
        setDataFetched(true);
      } catch (error) {
        console.error("Error fetching products:", error);
      }
    };

    fetchData();
  }, []);

  const handleGetProducts = (page, search = "") => {
    const token = localStorage.getItem("token");
    if (!token) {
      console.error("Aucun jeton trouvé dans le localStorage.");
      return;
    }
    if (search) {
      setLoadingSearch(true);
      ProductsApi.SearchProductByName(token, page, search)
        .then((response) => {
          if (response.data) {
            setProducts(response.data.results);
            setTotalPages(Math.ceil(response.data.count / 10));
          } else {
            console.warn("Aucune donnée trouvée:", response.data.msg);
          }
        })
        .catch((error) => {
          console.error(
            "Erreur de réponse des données:",
            error.response?.data?.msg || "Une erreur s'est produite."
          );
        })
        .finally(() => {
          setLoadingSearch(false);
        });
    } else {
      setLoading(true);
      ProductsApi.GetProducts(token, page)
        .then((response) => {
          if (response.data) {
            setProducts(response.data.results);
            setTotalPages(Math.ceil(response.data.count / 10));
          } else {
            console.warn("Aucune donnée trouvée:", response.data.msg);
          }
        })
        .catch((error) => {
          console.error(
            "Erreur de réponse des données:",
            error.response?.data?.msg || "Une erreur s'est produite."
          );
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  const handleGetProductsToExtract = () => {
    const token = localStorage.getItem("token");
    if (!token) {
      console.error("Aucun jeton trouvé dans le localStorage.");
      return Promise.reject("Aucun jeton trouvé dans le localStorage.");
    }
    setLoadingExtract(true);
    return ProductsApi.GetProductsToExtract(token)
      .then((response) => {
        if (response.data) {
          setProductsToExtract(response.data);
          return response.data;
        } else {
          console.warn("Aucune donnée trouvée:", response.data.msg);
        }
      })
      .catch((error) => {
        console.error(
          "Erreur de réponse des données:",
          error.response?.data?.msg || "Une erreur s'est produite."
        );
      })
      .finally(() => {
        setLoadingExtract(false);
      });
  };

  const handleDataUpdate = () => {
    handleGetProducts(page, search);
  };

  const handlePageChange = (event, value) => {
    setPage(value);
  };

  const columns = React.useMemo(
    () => [
      { Header: `${t("Nom du produit")}`, accessor: "product_name" },
      { Header: `${t("Prix unitaire")}`, accessor: "unit_price" },
      { Header: `${t("Catégorie")}`, accessor: "category_name" },
      { Header: `${t("Commande Max")}`, accessor: "max_order" },
      { Header: `${t("Prix Total")}`, accessor: "total_price" },
      {
        Header: t("action"),
        accessor: "action",
        Cell: ({ value }) => (
          <ActionCell productId={value} onDataUpdate={handleDataUpdate} />
        ),
      },
    ],
    // eslint-disable-next-line
    [t]
  );

  const rows = React.useMemo(
    () =>
      products.map((product) => ({
        product_name: product.product_name,
        unit_price: `${product.unit_price} TND`,
        category_name: product.category_name,
        max_order: product.max_order,
        total_price: `${product.total_price} TND`,
        action: product.id,
      })),
    [products]
  );

  const columnsCSV = React.useMemo(
    () => [
      { Header: `${t("Nom du produit")}`, accessor: "product_name" },
      { Header: `${t("Code de référence")}`, accessor: "reference_code" },
      { Header: `${t("Prix unitaire")}`, accessor: "unit_price" },
      { Header: `${t("Catégorie")}`, accessor: "category_name" },
      { Header: `${t("Boîte")}`, accessor: "box" },
      { Header: `${t("Quantité par boîte")}`, accessor: "box_items_quantity" },
      { Header: `${t("Prix de boîte")}`, accessor: "total_price" },
      { Header: `${t("Prix d'achat")}`, accessor: "buying_price" },
      { Header: `${t("Commande Max")}`, accessor: "max_order" },
      { Header: `${t("Statut")}`, accessor: "status" },
      { Header: "Description", accessor: "description" },
      {
        Header: "Image",
        accessor: "product_image",
        Cell: ({ value }) => <ProductCell image={value} />,
      },
    ],
    [t]
  );

  const rowsCSV = React.useMemo(
    () =>
      productsToExtract.map((product) => ({
        id: product.id,
        product_name: product.product_name,
        reference_code: product.reference_code,
        unit_price: product.unit_price ? `${product.unit_price} TND` : "",
        category_name: product.category_name,
        box: product.box ? "Oui" : "Non",
        box_items_quantity: product.box_items_quantity,
        total_price: product.total_price ? `${product.total_price} TND` : "",
        buying_price: product.buying_price ? `${product.buying_price} TND` : "",
        max_order: product.max_order,
        status: product.status,
        description: product.description,
        product_image: product.product_image,
      })),
    [productsToExtract]
  );

  const handleExportToCSV = useCallback(() => {
    const exportColumns = columnsCSV.filter(
      (column) => column.accessor !== "action"
    );
    const exportRows = rowsCSV.map((row) => {
      const { action, ...exportRow } = row;
      return exportRow;
    });
    exportDataToCSV(exportColumns, exportRows, "produits.csv");
  }, [columnsCSV, rowsCSV]);

  const handleFileChange = (e) => {
    setFile(e.target.files[0]);
    setOpenModal(true);
  };

  const handleUploadButtonClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = useCallback(() => {
    setAnchorEl(null);
  }, []);

  const handleExportToCSVClick = useCallback(() => {
    if (!dataFetched) {
      setExportPending(true);
    } else {
      handleExportToCSV();
      handleMenuClose();
    }
  }, [dataFetched, handleExportToCSV, handleMenuClose]);

  useEffect(() => {
    if (dataFetched && exportPending) {
      setShowLoadingExtract(false);
      setExportPending(false);
      handleExportToCSV();
      handleMenuClose();
    }
  }, [dataFetched, exportPending, handleExportToCSV, handleMenuClose]);

  const handleImportProductsClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const handleConfirmUpload = () => {
    setUploading(true);
    if (!file) {
      console.error("Aucun fichier sélectionné.");
      setUploading(false);
      return;
    }

    // Proceed with file upload logic here
    const fileId = Date.now();
    const fileName = `${file.name}_${fileId}`;

    const storageRef = ref(storage, fileName);
    const uploadTask = uploadBytesResumable(storageRef, file);

    uploadTask.on(
      "state_changed",
      (snapshot) => {
        const progress =
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        console.log("Téléchargement à " + progress + "% terminé");
      },
      (error) => {
        console.error("Échec du téléchargement:", error);
        setUploading(false);
      },
      () => {
        getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
          getMetadata(uploadTask.snapshot.ref)
            .then((metadata) => {
              const data = {
                category_id: 1,
                file_link: metadata.name,
              };

              const token = localStorage.getItem("token");
              if (!token) {
                console.error("Aucun jeton trouvé dans le localStorage.");
                setUploading(false);
                return;
              }

              ProductsApi.UploadProducts(data, token)
                .then((response) => {
                  setSnackbarMessage("Téléchargement réussi!");
                  setSnackbarOpen(true);
                })
                .catch((error) => {
                  console.error("Échec du téléchargement:", error);
                })
                .finally(() => {
                  setUploading(false);
                  setOpenModal(false);
                });
            })
            .catch((error) => {
              console.error(
                "Erreur lors de la récupération des métadonnées:",
                error
              );
              setUploading(false);
            });
        });
      }
    );
  };

  const handleCancelUpload = () => {
    setOpenModal(false);
    setFile(null);
  };

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      {!loading && (
        <Stack
          mb={2}
          direction="row"
          justifyContent="space-between"
          style={{ width: "100%" }}
        >
          <Link to="/gestion-de-stock/ajouter-articles">
            <SoftButton variant="gradient" color="info">
              + {t("Nouveau Produit")}
            </SoftButton>
          </Link>
          <React.Fragment>
            <input
              type="file"
              accept=".csv"
              ref={fileInputRef}
              style={{ display: "none" }}
              onChange={handleFileChange}
            />
            <SoftButton
              variant="gradient"
              color="info"
              onClick={handleUploadButtonClick}
            >
              {t("Importer")} / {t("Exporter")}
            </SoftButton>
            <Menu
              anchorEl={anchorEl}
              open={Boolean(anchorEl)}
              onClose={handleMenuClose}
            >
              <MenuItem onClick={handleImportProductsClick}>
                {t("Importez les Produits")}
              </MenuItem>
              {!showLoadingExtract ? (
                <MenuItem
                  onClick={() => {
                    setShowLoadingExtract(loadingExtract);
                    handleExportToCSVClick();
                  }}
                >
                  {t("Exportez les Produits")}
                </MenuItem>
              ) : (
                <MenuItem>
                  {t("Exportation en cours")}
                  <CircularProgress
                    size={15}
                    sx={{ color: "#1F51FF", marginLeft: "10px" }}
                  />
                </MenuItem>
              )}
            </Menu>
          </React.Fragment>
        </Stack>
      )}
      <SoftBox
        display="flex"
        flexDirection="column"
        justifyContent="space-between"
        minHeight="80vh"
      >
        <SoftBox my={3}>
          <Card>
            {loading ? (
              <SoftBox
                display="flex"
                justifyContent="center"
                alignItems="center"
                p={35}
              >
                <CircularProgress sx={{ color: "#1F51FF" }} />
              </SoftBox>
            ) : (
              <>
                <SoftBox
                  position="relative"
                  width="15rem"
                  ml="auto"
                  mb={-5}
                  p={2}
                >
                  <SoftInput
                    placeholder={`${t("Rechercher")}...`}
                    value={search}
                    onChange={(e) => setSearch(e.target.value)}
                    sx={{
                      width: "100%",
                      paddingRight: loadingSearch ? "40px" : "10px",
                    }}
                  />
                  {loadingSearch && (
                    <CircularProgress
                      sx={{
                        color: "grey",
                        position: "absolute",
                        right: "25px",
                        top: "35%",
                        transform: "translateY(-50%)",
                      }}
                      size={18}
                    />
                  )}
                </SoftBox>
                <DataTable
                  entriesPerPage={{
                    defaultValue: 10,
                    entries: [5, 10, 15, 20, 25],
                  }}
                  // canSearch
                  showTotalEntries
                  pagination={{ variant: "gradient", color: "info" }}
                  isSorted
                  noEndBorder
                  table={{ columns, rows }}
                />
                {totalPages > 1 && (
                  <Stack
                    spacing={2}
                    sx={{
                      mt: 2,
                      position: "absolute",
                      bottom: 7,
                      left: 200,
                      transform: "translateX(-50%)",
                    }}
                  >
                    <Pagination
                      count={totalPages}
                      page={page}
                      onChange={handlePageChange}
                      color="secondary"
                    />
                  </Stack>
                )}
              </>
            )}
          </Card>
        </SoftBox>
        <Footer />
      </SoftBox>

      <Dialog
        open={openModal}
        onClose={() => setOpenModal(false)}
        sx={{ padding: "55px" }}
      >
        <DialogTitle>{t("Confirmer le Téléchargement du Fichier")}</DialogTitle>
        <DialogContent sx={{ padding: "25px" }}>
          <p>
            {t("Fichier Sélectionné")}:{" "}
            {file ? file.name : `${t("Aucun fichier sélectionné")}`}
          </p>
        </DialogContent>
        <DialogActions>
          <SoftButton
            variant="gradient"
            color="light"
            size="medium"
            onClick={handleCancelUpload}
          >
            {t("Annuler")}
          </SoftButton>
          <SoftButton
            variant="gradient"
            color="info"
            size="medium"
            onClick={handleConfirmUpload}
            disabled={uploading}
          >
            {uploading ? (
              <CircularProgress size={24} color="white" />
            ) : (
              `${t("Confirmer")}`
            )}
          </SoftButton>
        </DialogActions>
      </Dialog>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
      >
        <MuiAlert
          elevation={6}
          variant="filled"
          onClose={handleSnackbarClose}
          severity="success"
        >
          {snackbarMessage}
        </MuiAlert>
      </Snackbar>
    </DashboardLayout>
  );
}

export default ProductsList;
