import React, { useEffect, useState } from "react";
import Card from "@mui/material/Card";
import SoftBox from "components/SoftBox";
import SoftButton from "components/SoftButton";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DataTable from "examples/Tables/DataTable";
import { CircularProgress } from "@mui/material";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import ManagersApi from "api/managers";

function ManagersList() {
  const [managersList, setManagersList] = useState([]);
  const [loading, setLoading] = useState(true);
  const { t } = useTranslation();

  const fetchManagresList = async () => {
    try {
      setLoading(true);

      const token = localStorage.getItem("token");
      const response = await ManagersApi.GetManagersList(token);
      setManagersList(response.data);
      setLoading(false);
    } catch (error) {
      console.error("Failed to fetch managersList:", error);
      setLoading(false);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchManagresList();
  }, []);

  const columns = [
    { Header: t("Prénom"), accessor: "firstname" },
    { Header: t("Nom"), accessor: "lastname" },
    { Header: t("Téléphone"), accessor: "phone" },
    { Header: t("Poste"), accessor: "poste" },
  ];

  const rows = managersList.map((manager) => ({
    firstname: manager.firstname,
    lastname: manager.lastname,
    phone: manager.phone,
    poste: t("Manager d'entrepôt"),
  }));

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <SoftBox mb={2}>
        <Link to="/gestion-des-ventes/nouveau-manager">
          <SoftButton variant="gradient" color="info">
            + {t("nouveau manager")}
          </SoftButton>
        </Link>
      </SoftBox>
      <SoftBox
        display="flex"
        flexDirection="column"
        justifyContent="space-between"
        minHeight="80vh"
      >
        <Card sx={{ maxWidth: "70%", width: "100%" }}>
          <SoftBox p={3}>
            {loading ? (
              <SoftBox
                display="flex"
                justifyContent="center"
                alignItems="center"
                p={35}
              >
                <CircularProgress sx={{ color: "#1F51FF" }} />
              </SoftBox>
            ) : (
              <DataTable
                table={{ columns, rows }}
                entriesPerPage={false}
                canSearch
              />
            )}
          </SoftBox>
        </Card>
        <Footer />
      </SoftBox>
    </DashboardLayout>
  );
}

export default ManagersList;
