import axios from "./index";

class SupplierApi {
  static CreateSupplier = (token, payload) => {
    return axios.post(`${base}/suppliers/`, payload, {
      headers: { Authorization: `Bearer ${token}` },
    });
  };
  static GetSupplierByID = (token, supplierID) => {
    return axios.get(`${base}/suppliers/${supplierID}`, {
      headers: { Authorization: `Bearer ${token}` },
    });
  };
  static GetSuppliersList = (token) => {
    return axios.get(`${base}/suppliers/`, {
      headers: { Authorization: `Bearer ${token}` },
    });
  };
  static EditSupplier = (token, ID, payload) => {
    return axios.put(`${base}/suppliers/${ID}/`, payload, {
      headers: { Authorization: `Bearer ${token}` },
    });
  };
}

// Base URL for the authentication API
let base = "accounts";

export default SupplierApi;
