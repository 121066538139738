import React, { useEffect, useState } from "react";
import { Card, Grid, Typography, Box, IconButton } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import SoftBox from "components/SoftBox";
import Footer from "examples/Footer";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import SoftButton from "components/SoftButton";
import SoftTypography from "components/SoftTypography";
import SoftInput from "components/SoftInput";
import MultiSelect from "components/MultiSelect";
import { useNavigate } from "react-router-dom";
import WarehouseApi from "api/warehouse";
import { useTranslation } from "react-i18next";

function ModifierEntrepotsInfo() {
  const navigate = useNavigate();
  const { t } = useTranslation();

  // State hooks for warehouses and managers list
  const [warehouses, setWarehouses] = useState([
    { name: "", city: "", address: "", managers: [] },
  ]);
  const [warehousesList, setWarehousesList] = useState([]);
  const token = localStorage.getItem("token");

  // Fetch list of warehouse managers on component mount
  useEffect(() => {
    const fetchWarehouseManagers = async () => {
      try {
        const response = await WarehouseApi.GetWarehouseManagersList(token);
        const managersList = response.data.results.map((manager) => ({
          label: manager.username,
          value: manager.id,
        }));
        setWarehousesList(managersList);
      } catch (error) {
        console.error("Failed to fetch warehouse managers:", error);
      }
    };

    fetchWarehouseManagers();
  }, [token]);

  // Update warehouse information based on user input
  const handleWarehouseChange = (index, name, value) => {
    const updatedWarehouses = [...warehouses];
    updatedWarehouses[index][name] = value;
    setWarehouses(updatedWarehouses);
  };

  // Add a new warehouse form
  const handleAddWarehouse = () => {
    setWarehouses([
      ...warehouses,
      { name: "", city: "", address: "", managers: [] },
    ]);
  };

  // Remove a specific warehouse form
  const handleRemoveWarehouse = (index) => {
    const updatedWarehouses = warehouses.filter((_, i) => i !== index);
    setWarehouses(updatedWarehouses);
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <SoftBox
        display="flex"
        flexDirection="column"
        justifyContent="space-between"
        minHeight="85vh"
      >
        <SoftBox py={3} px={14}>
          <Card>
            <SoftBox py={3} px={5}>
              <Box>
                <Typography variant="h6" fontWeight="bold" mb={2}>
                  {t("Modifier les informations des entrepôts")}
                </Typography>

                {warehouses.map((warehouse, index) => (
                  <Grid container spacing={3} key={index} mb={2}>
                    {/* Warehouse Name */}
                    <Grid item xs={12} sm={2.5}>
                      <SoftTypography variant="h6">{t("Nom")}</SoftTypography>
                      <SoftInput
                        style={{ marginBottom: "1rem" }}
                        name="name"
                        value={warehouse.name}
                        onChange={(e) =>
                          handleWarehouseChange(
                            index,
                            e.target.name,
                            e.target.value
                          )
                        }
                      />
                    </Grid>

                    {/* City */}
                    <Grid item xs={12} sm={2.5}>
                      <SoftTypography variant="h6">{t("Ville")}</SoftTypography>
                      <SoftInput
                        style={{ marginBottom: "1rem" }}
                        name="city"
                        value={warehouse.city}
                        onChange={(e) =>
                          handleWarehouseChange(
                            index,
                            e.target.name,
                            e.target.value
                          )
                        }
                      />
                    </Grid>

                    {/* Address */}
                    <Grid item xs={12} sm={3}>
                      <SoftTypography variant="h6">{t("Adresse")}</SoftTypography>
                      <SoftInput
                        style={{ marginBottom: "1rem" }}
                        name="address"
                        value={warehouse.address}
                        onChange={(e) =>
                          handleWarehouseChange(
                            index,
                            e.target.name,
                            e.target.value
                          )
                        }
                      />
                    </Grid>

                    {/* Managers */}
                    <Grid item xs={12} sm={3}>
                      <SoftTypography variant="h6">{t("Gestionnaire")}</SoftTypography>
                      <MultiSelect
                        placeholder={t("Sélectionner un gestionnaire")}
                        options={warehousesList}
                        value={warehouse.managers}
                        onChange={(selectedOptions) =>
                          handleWarehouseChange(
                            index,
                            "managers",
                            selectedOptions
                          )
                        }
                      />
                      <SoftTypography
                        variant="caption"
                        color="info"
                        fontWeight="bold"
                        sx={{ cursor: "pointer" }}
                        onClick={() =>
                          navigate("/gestion-des-ventes/nouveau-client")
                        }
                      >
                        + {t("Ajouter un gestionnaire")}
                      </SoftTypography>
                    </Grid>

                    {/* Remove Warehouse Button */}
                    <Grid item xs={12} sm={1}>
                      <IconButton
                        color="error"
                        onClick={() => handleRemoveWarehouse(index)}
                        sx={{ mt: 3 }}
                      >
                        <DeleteIcon />
                      </IconButton>
                    </Grid>
                  </Grid>
                ))}

                {/* Add New Warehouse Button */}
                <Box display="flex" mt={2}>
                  <SoftButton
                    variant="outlined"
                    color="info"
                    onClick={handleAddWarehouse}
                  >
                    <AddIcon /> {t("Ajouter un entrepôt")}
                  </SoftButton>
                </Box>
              </Box>

              {/* Save Changes Button */}
              <Box display="flex" justifyContent="flex-end">
                <SoftButton variant="contained" color="info" type="submit">
                  {t("Enregistrer les modifications")}
                </SoftButton>
              </Box>
            </SoftBox>
          </Card>
        </SoftBox>
        <Footer />
      </SoftBox>
    </DashboardLayout>
  );
}

export default ModifierEntrepotsInfo;
