import axios from "./index";

// CategoriesApi class handles fetching product categories
class CategoriesApi {
  /**
   * Fetches the list of product categories by sending a GET request.
   * The request includes an Authorization header with the Bearer token.
   * @param {string} token - The authentication token for the user.
   * @returns {Promise} - The response containing the list of product categories.
   */
  static GetCategories = (token) => {
    return axios.get(`${base}/categories`, {
      headers: { Authorization: `Bearer ${token}` },
    });
  };
}

// Base URL for the product-related API
let base = "product";

export default CategoriesApi;
