import Grid from "@mui/material/Grid";
import { useEffect, useState } from "react";
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Icon,
  Menu,
  MenuItem,
  TextField,
} from "@mui/material";

// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";

// Soft UI Dashboard PRO React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import MiniStatisticsCard from "examples/Cards/StatisticsCards/MiniStatisticsCard";

// Recharts Components
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  PieChart,
  Pie,
  Cell,
} from "recharts";
import SoftButton from "components/SoftButton";
// import StatisticsApi from "api/statistics";
import { useTranslation } from "react-i18next";

const COLORS = [
  "#cb0c9f",
  "#8392ab",
  "#00669c",
  "#82d616",
  "#fbcf33",
  "#ea0606",
  "#ff4500",
  "#67748e",
  "#344767",
  "#3aaf85",
];

function Default() {
  const [statistics, setStatistics] = useState({});
  const [loading, setLoading] = useState(false);

  const [filter, setFilter] = useState("daily");
  const [anchorEl, setAnchorEl] = useState(null);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  const { t } = useTranslation();

  const filterText = {
    daily: `${t("Statistiques du Jour")}`,
    weekly: `${t("Statistiques de la Semaine")}`,
    monthly: `${t("Statistiques du Mois")}`,
    custom: `${t("Statistiques de période")}`,
  };

  const handleFilterChange = (newFilter) => {
    setFilter(newFilter);
    if (newFilter === "custom") {
      setDialogOpen(true);
    } else {
      setStartDate(null);
      setEndDate(null);
      setDialogOpen(false);
      handleGetStatistics(newFilter);
    }
    setAnchorEl(null);
  };
  const handleFilterButtonClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleDialogClose = () => {
    setDialogOpen(false);
  };

  const handleSave = () => {
    handleGetStatistics();
    handleDialogClose();
  };


  const handleGetStatistics = () => {
    setLoading(true);

    // Données fictives
    const fakeData = {
      sales: { percentage_change: 15.2, current_value: 25000 },
      cash_balance: { percentage_change: -5, current_value: 10000 },
      total_payments: { percentage_change: 8.3, current_value: 18000 },
      orders_count: { percentage_change: 12, current_value: 120 },
      clients_count: { percentage_change: 7, current_value: 75 },
      stock_total: { percentage_change: 10.5, current_value: 5000 },
      sales_rotation: { percentage_change: -2.5, current_value: 3000 },
      top_sold_articles: [
        { product_name: "Viva Canette Citron", percentage_change: 30 },
        {
          product_name: "Maestro Sensation Mini Tablette Noir",
          percentage_change: 25,
        },
        { product_name: "Daily'n - Brownie", percentage_change: 10 },
        { product_name: "Safia 1L", percentage_change: 20 },
        {
          product_name: "El Manar - Thon entier HO 160g",
          percentage_change: 15,
        },
        { product_name: "Judy - Nettoyant", percentage_change: 25 },
        {
          product_name: "Jadida - Pot mayonnaise 240ml",
          percentage_change: 32,
        },
      ],
      bottom_sold_articles: [
        { product_name: "Jektis", percentage_change: 38 },
        {
          product_name: "Said Junior- Chocolat au lait et dragées",
          percentage_change: 15,
        },
        { product_name: "Cristal - Huile de Mais 2L", percentage_change: 20 },
        { product_name: "Chahia - Jambon Poulet 1kg", percentage_change: 25 },
        { product_name: "Hag - Riz Etuvé 1Kg", percentage_change: 15 },
      ],
      top_clients: [
        { username: "BBK", percentage_change: 45 },
        { username: "Hotel Rosa", percentage_change: 30 },
        { username: "افرو باست", percentage_change: 35 },
        { username: "Rejeb Chaalia", percentage_change: 30 },
        { username: "Livrili", percentage_change: 25 },
        { username: "foulenStore", percentage_change: 18 },
      ],
      bottom_clients: [
        { username: "Client test", percentage_change: 10 },
        { username: "Mohamed", percentage_change: 15 },
        { username: "Ibrahim", percentage_change: 5 },
        { username: "Ahmed", percentage_change: 14 },
        { username: "Youssef", percentage_change: 8 },
      ],
    };

    // Simulation de délai pour imiter une requête API
    setTimeout(() => {
      setStatistics(fakeData);
      setLoading(false);
    }, 1000);
  };

  useEffect(() => {
    if (filter === "custom" && !startDate && !endDate) {
      return;
    } else {
      handleGetStatistics();
    }
    // eslint-disable-next-line
  }, [filter]);

  const topArticlesBarChartData =
    statistics.top_sold_articles?.map((article) => ({
      name: article.product_name,
      sales: article.percentage_change,
    })) || [];

  const bottomArticlesBarChartData =
    statistics.bottom_sold_articles?.map((article) => ({
      name: article.product_name,
      sales: article.percentage_change,
    })) || [];

  const clientsPieChartData =
    statistics.bottom_clients?.map((client) => ({
      name: client.username,
      value: client.percentage_change,
    })) || [];

  const topClientsPieChartData =
    statistics.top_clients?.map((client) => ({
      name: client.username,
      value: client.percentage_change,
    })) || [];

  const cardData = [
    {
      title: `${t("Chiffre d'Affaires")}`,
      icon: "attach_money",
      color: "primary",
      value: statistics?.sales?.percentage_change,
      current_value: formatNumber(statistics?.sales?.current_value),
    },
    {
      title: `${t("Solde Caisse")}`,
      icon: "account_balance_wallet",
      color: "secondary",
      value: statistics?.cash_balance?.percentage_change,
      current_value: formatNumber(statistics?.cash_balance?.current_value),
    },
    {
      title: `${t("Total Encaissement")}`,
      icon: "receipt",
      color: "success",
      value: statistics?.total_payments?.percentage_change,
      current_value: formatNumber(statistics?.total_payments?.current_value),
    },
    {
      title: `${t("Total Paiement")}`,
      icon: "payment",
      color: "warning",
      value: statistics?.total_payments?.percentage_change,
      current_value: formatNumber(statistics?.total_payments?.current_value),
    },
    {
      title: `${t("Nombre de Commandes du Jour")}`,
      icon: "shopping_bag",
      color: "info",
      value: statistics?.orders_count?.percentage_change,
      current_value: formatNumber(statistics?.orders_count?.current_value),
    },
    {
      title: `${t("Nombre de Clients du Jour")}`,
      icon: "people",
      color: "error",
      value: statistics?.clients_count?.percentage_change,
      current_value: formatNumber(statistics?.clients_count?.current_value),
    },
    {
      title: `${t("Total en Stock")}`,
      icon: "inventory",
      color: "primary",
      value: statistics?.stock_total?.percentage_change,
      current_value: formatNumber(statistics?.stock_total?.current_value),
    },
    {
      title: `${t("Rotation Vente du Jour")}`,
      icon: "refresh",
      color: "error",
      value: statistics?.sales_rotation?.percentage_change,
      current_value: formatNumber(statistics?.sales_rotation?.current_value),
    },
  ];

  function formatNumber(value) {
    if (typeof value === "number" && !Number.isInteger(value)) {
      return value.toFixed(3);
    }
    return value;
  }

  return (
    <DashboardLayout>
      <DashboardNavbar />
      {loading ? (
        <SoftBox
          display="flex"
          justifyContent="center"
          alignItems="center"
          p={35}
        >
          <CircularProgress sx={{ color: "#1F51FF" }} />
        </SoftBox>
      ) : (
        <>
          <SoftBox
            display="flex"
            justifyContent="space-between"
            alignItems="flex-end"
            mb={4}
            mt={1}
          >
            <SoftButton
              variant="contained"
              color="info"
              onClick={handleFilterButtonClick}
            >
              {filterText[filter]}
              <Icon>keyboard_arrow_down</Icon>
            </SoftButton>
            <Menu
              anchorEl={anchorEl}
              open={Boolean(anchorEl)}
              onClose={handleMenuClose}
            >
              <MenuItem onClick={() => handleFilterChange("daily")}>
                {t("Statistiques du Jour")}
              </MenuItem>
              <MenuItem onClick={() => handleFilterChange("weekly")}>
                {t("Statistiques de la Semaine")}
              </MenuItem>
              <MenuItem onClick={() => handleFilterChange("monthly")}>
                {t("Statistiques du Mois")}
              </MenuItem>
              <MenuItem onClick={() => handleFilterChange("custom")}>
                {t("Statistiques de Période")}
              </MenuItem>
            </Menu>
          </SoftBox>

          <Dialog open={dialogOpen} onClose={handleDialogClose}>
            <DialogTitle>{t("Définir la Période")}</DialogTitle>
            <DialogContent>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <SoftTypography variant="text" mb={1}>
                    {t("Date de Début")}
                  </SoftTypography>
                  <TextField
                    type="date"
                    InputLabelProps={{ shrink: true }}
                    value={startDate}
                    onChange={(e) => setStartDate(e.target.value)}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <SoftTypography variant="text" mb={1}>
                    {t("Date de Fin")}
                  </SoftTypography>
                  <TextField
                    type="date"
                    InputLabelProps={{ shrink: true }}
                    value={endDate}
                    onChange={(e) => setEndDate(e.target.value)}
                    fullWidth
                  />
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleDialogClose}>Annuler</Button>
              <Button onClick={handleSave} color="primary">
                {t("Enregistrer")}
              </Button>
            </DialogActions>
          </Dialog>

          <SoftBox mb={3}>
            <SoftBox mb={3}>
              <SoftTypography p={1} mb={1} variant="h5">
                {t("Statistiques Générales")}
              </SoftTypography>
              <Grid container spacing={2}>
                {cardData.map((card, index) => (
                  <Grid item xs={12} sm={6} md={4} lg={3} key={index}>
                    <SoftBox mb={3}>
                      <MiniStatisticsCard
                        title={{ text: card.title, fontWeight: "bold" }}
                        percentage={{
                          color:
                            card.value > 0
                              ? `${card.value}%`
                              : card.value < 0
                              ? "error"
                              : "black",
                          text:
                            card.value > 0
                              ? `+${card.value}%`
                              : card.value < 0
                              ? `${card.value}%`
                              : `${card.value}%`,
                        }}
                        count={card.current_value}
                        icon={{ color: card.color, component: card.icon }}
                      />
                    </SoftBox>
                  </Grid>
                ))}
              </Grid>
            </SoftBox>

            <SoftTypography p={1} mb={1} variant="h5">
              {t("Statistiques Articles")}
            </SoftTypography>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <SoftBox
                  mb={3}
                  p={2}
                  sx={{ height: { xs: "400px", lg: "600px" } }}
                >
                  <SoftTypography mb={1} textAlign="center">
                    {t("Articles les Plus Vendus")}
                  </SoftTypography>
                  <ResponsiveContainer>
                    <BarChart
                      data={topArticlesBarChartData}
                      margin={{ top: 20, right: 20, bottom: 40, left: 0 }}
                    >
                      <CartesianGrid strokeDasharray="3 3" />
                      <XAxis
                        dataKey="name"
                        tick={{ fontSize: 12 }}
                        angle={-45}
                        textAnchor="end"
                        height={50}
                      />
                      <YAxis />
                      <Tooltip />
                      <Legend />
                      <Bar dataKey="sales" fill="#82ca9d" barSize={25} />
                    </BarChart>
                  </ResponsiveContainer>
                </SoftBox>
              </Grid>

              <Grid item xs={12} sm={6}>
                <SoftBox
                  mb={3}
                  p={2}
                  sx={{ height: { xs: "400px", lg: "600px" } }}
                >
                  <SoftTypography mb={1} textAlign="center">
                    {t("Articles les Moins Vendus")}
                  </SoftTypography>
                  <ResponsiveContainer>
                    <BarChart
                      data={bottomArticlesBarChartData}
                      margin={{ top: 20, right: 20, bottom: 40, left: 0 }}
                    >
                      <CartesianGrid strokeDasharray="3 3" />
                      <XAxis
                        dataKey="name"
                        tick={{ fontSize: 12 }}
                        angle={-45}
                        textAnchor="end"
                        height={50}
                      />
                      <YAxis />
                      <Tooltip />
                      <Legend />
                      <Bar dataKey="sales" fill="#1E90FF" barSize={25} />
                    </BarChart>
                  </ResponsiveContainer>
                </SoftBox>
              </Grid>
            </Grid>

            <SoftTypography p={1} mb={5} variant="h5">
              {t("Statistiques Clients")}
            </SoftTypography>
            <Grid container spacing={2}>
              {/* Clients Moins Dépensiers */}
              <Grid item xs={12} lg={6}>
                <Grid container spacing={2}>
                  <Grid item xs={12} lg={6}>
                    <SoftBox
                      mb={3}
                      p={2}
                      sx={{
                        height: { xs: "300px", sm: "400px" },
                        margin: "auto",
                      }}
                    >
                      <SoftTypography mb={2} textAlign="center">
                        {t("Statistiques Clients Moins Dépensiers")}
                      </SoftTypography>
                      <ResponsiveContainer>
                        <PieChart>
                          <Pie
                            data={clientsPieChartData}
                            dataKey="value"
                            outerRadius={100}
                            innerRadius={50}
                            paddingAngle={5}
                            label={false}
                          >
                            {clientsPieChartData.map((entry, index) => (
                              <Cell
                                key={`cell-${index}`}
                                fill={COLORS[index % COLORS.length]}
                              />
                            ))}
                          </Pie>
                          <Tooltip
                            formatter={(value) => formatNumber(value)}
                            contentStyle={{
                              backgroundColor: "#fff",
                              border: "none",
                              boxShadow: "0px 0px 6px rgba(0, 0, 0, 0.1)",
                            }}
                          />
                        </PieChart>
                      </ResponsiveContainer>
                    </SoftBox>
                  </Grid>
                  <Grid item xs={12} lg={6}>
                    <SoftBox
                      mt={2}
                      display="flex"
                      flexDirection="column"
                      alignItems="flex-start"
                    >
                      {clientsPieChartData.map((entry, index) => (
                        <SoftBox
                          key={entry.name}
                          display="flex"
                          alignItems="center"
                          mb={1}
                          sx={{ width: "100%", fontSize: "14px" }}
                        >
                          <SoftBox
                            sx={{
                              backgroundColor: COLORS[index % COLORS.length],
                              width: "16px",
                              height: "16px",
                              marginRight: "8px",
                              borderRadius: "3px",
                            }}
                          />
                          <SoftTypography>{entry.name}</SoftTypography>
                        </SoftBox>
                      ))}
                    </SoftBox>
                  </Grid>
                </Grid>
              </Grid>

              {/* Clients les Plus Dépensiers */}
              <Grid item xs={12} lg={6}>
                <Grid container spacing={2}>
                  <Grid item xs={12} lg={6}>
                    <SoftBox
                      mb={3}
                      p={2}
                      sx={{
                        height: { xs: "300px", sm: "400px" },
                        margin: "auto",
                      }}
                    >
                      <SoftTypography mb={2} textAlign="center">
                        {t("Statistiques Clients les Plus Dépensiers")}
                      </SoftTypography>
                      <ResponsiveContainer>
                        <PieChart>
                          <Pie
                            data={topClientsPieChartData}
                            dataKey="value"
                            outerRadius={100}
                            innerRadius={50}
                            paddingAngle={5}
                            label={false}
                          >
                            {topClientsPieChartData.map((entry, index) => (
                              <Cell
                                key={`cell-${index}`}
                                fill={COLORS[index % COLORS.length]}
                              />
                            ))}
                          </Pie>
                          <Tooltip
                            formatter={(value) => formatNumber(value)}
                            contentStyle={{
                              backgroundColor: "#fff",
                              border: "none",
                              boxShadow: "0px 0px 6px rgba(0, 0, 0, 0.1)",
                            }}
                          />
                        </PieChart>
                      </ResponsiveContainer>
                    </SoftBox>
                  </Grid>
                  <Grid item xs={12} lg={6}>
                    <SoftBox
                      mt={2}
                      display="flex"
                      flexDirection="column"
                      alignItems="flex-start"
                    >
                      {topClientsPieChartData.map((entry, index) => (
                        <SoftBox
                          key={entry.name}
                          display="flex"
                          alignItems="center"
                          mb={1}
                          sx={{ width: "100%", fontSize: "14px" }}
                        >
                          <SoftBox
                            sx={{
                              backgroundColor: COLORS[index % COLORS.length],
                              width: "16px",
                              height: "16px",
                              marginRight: "8px",
                              borderRadius: "3px",
                            }}
                          />
                          <SoftTypography>{entry.name}</SoftTypography>
                        </SoftBox>
                      ))}
                    </SoftBox>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </SoftBox>
          <Footer />
        </>
      )}
    </DashboardLayout>
  );
}

export default Default;
