import { useEffect, useState } from "react";
import Grid from "@mui/material/Grid";
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftSelect from "components/SoftSelect";
import {
  firstactivityList,
  secondactivityList,
  storeTypeList,
} from "utils/data";
import FormField from "../FormField";
import { useTranslation } from "react-i18next";
import { Switch } from "@mui/material";

function UserInfo({ formData, onChange, validateFormCallback, touched }) {
  const { values } = formData;
  const { t } = useTranslation();

  const [formFields, setFormFields] = useState({
    first_activity: values.first_activity || "",
    second_activity: values.second_activity || "",
    store_type: values.store_type || "",
    firstname: values.firstname || "",
    lastname: values.lastname || "",
    username: values.username || "",
    phone: values.phone || "",
    mat_fiscale: values.mat_fiscale || "",
    password: values.password || "",
    password2: values.password2 || "",
    user_type: values.user_type || "",
    regime_forfaitaire: values?.regime_forfaitaire || false,
  });

  const [errors, setErrors] = useState({});

  const validateForm = () => {
    const newErrors = {};

    if (!formFields.firstname.trim()) {
      newErrors.firstname = `${t("Le prénom est requis")}`;
    }
    if (!formFields.lastname.trim()) {
      newErrors.lastname = `${t("Le nom de famille est requis")}`;
    }
    if (!formFields.username.trim()) {
      newErrors.username = `${t("Le nom d'utilisateur est requis")}`;
    }
    if (!formFields.phone.trim()) {
      newErrors.phone = `${t("Le numéro de téléphone est requis")}`;
    } else if (!/^\d{8}$/.test(formFields.phone.trim())) {
      newErrors.phone = `${t("Le numéro de téléphone doit contenir 8 chiffres")}`;
    }
    if (!formFields.mat_fiscale.trim()) {
      newErrors.mat_fiscale = `${t("Le matricule fiscale est requis")}`;
    } else if (
      !/^(\d{3}[A-Z]{3}\d{7}\/[A-Z])$/.test(formFields.mat_fiscale.trim())
    ) {
      newErrors.mat_fiscale =
        `${t("Le matricule fiscal doit être au format correct (123ABC1234567/X)")}`;
    }
    if (!formFields.password.trim()) {
      newErrors.password = `${t("Le mot de passe est requis")}`;
    }
    if (!formFields.password2.trim()) {
      newErrors.password2 = `${t("Veuillez confirmer le mot de passe")}`;
    } else if (formFields.password !== formFields.password2) {
      newErrors.password2 = `${t("Les mots de passe ne correspondent pas")}`;
    }
    if (!formFields.user_type) {
      newErrors.user_type = `${t("Le type d'utilisateur est requis")}`;
    }
    if (!formFields.store_type) {
      newErrors.store_type = `${t("Le type de magasin est requis")}`;
    }
    if (!formFields.first_activity) {
      newErrors.first_activity = `${t("La première activité est requise")}`;
    }
    if (!formFields.second_activity) {
      newErrors.second_activity = `${t("La deuxième activité est requise")}`;
    }
    if (formFields.regime_forfaitaire === undefined) {
      newErrors.regime_forfaitaire = `${t("Le régime est requis")}`;
    }

    setErrors(newErrors);
    validateFormCallback(Object.keys(newErrors).length === 0);
  };

  useEffect(() => {
    onChange(formFields);
    // eslint-disable-next-line
  }, [formFields]);

  useEffect(() => {
    validateForm();
    // eslint-disable-next-line
  }, [formFields, t]);

  const handleInputChange = (fieldName, value) => {
    if (
      ["firstname", "lastname", "username"].includes(fieldName) &&
      /^\d+$/.test(value.trim())
    ) {
      return;
    }
    setFormFields((prevFields) => ({ ...prevFields, [fieldName]: value }));
    setErrors((prevErrors) => ({ ...prevErrors, [fieldName]: null }));
  };

  return (
    <SoftBox>
      <SoftBox lineHeight={0}>
        <SoftTypography variant="h5" fontWeight="bold">
          {t("Informations de client")}
        </SoftTypography>
      </SoftBox>
      <SoftBox mt={2}>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>
            <FormField
              label={t("Prénom")}
              name="firstname"
              value={formFields.firstname}
              onChange={(e) => handleInputChange("firstname", e.target.value)}
              placeholder={t("Entrez votre prénom")}
              error={touched && errors.firstname}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormField
              label={t("Nom de famille")}
              name="lastname"
              value={formFields.lastname}
              onChange={(e) => handleInputChange("lastname", e.target.value)}
              placeholder={t("Entrez votre nom de famille")}
              error={touched && errors.lastname}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormField
              label={t("Nom d'utilisateur")}
              name="username"
              value={formFields.username}
              onChange={(e) => handleInputChange("username", e.target.value)}
              placeholder={t("Entrez votre nom d'utilisateur")}
              error={touched && errors.username}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormField
              type="number"
              label={t("Numéro de téléphone")}
              name="phone"
              value={formFields.phone}
              onChange={(e) => handleInputChange("phone", e.target.value)}
              placeholder={t("Entrez votre numéro de téléphone")}
              error={touched && errors.phone}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <SoftBox mb={2}>
              <SoftTypography
                component="label"
                variant="caption"
                fontWeight="bold"
                textTransform="capitalize"
              >
                {t("Type d'utilisateur")}
              </SoftTypography>
              <SoftSelect
                value={formFields.user_type}
                onChange={(value) => handleInputChange("user_type", value)}
                options={[{ value: "client", label: "Client" }]}
                errorLabel={touched && errors.user_type}
              />
            </SoftBox>
          </Grid>
          <Grid item xs={12} sm={6}>
            <SoftBox mb={2}>
              <SoftTypography
                component="label"
                variant="caption"
                fontWeight="bold"
                textTransform="capitalize"
              >
                {t("Type de magasin")}
              </SoftTypography>
              <SoftSelect
                value={formFields.store_type}
                onChange={(value) => handleInputChange("store_type", value)}
                options={storeTypeList.map((store) => ({
                  value: t(store.value),
                  label: t(store.label),
                }))}
                errorLabel={touched && errors.store_type}
              />
            </SoftBox>
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormField
              label={t("Matricule fiscale")}
              name="mat_fiscale"
              value={formFields.mat_fiscale.toUpperCase()}
              onChange={(e) =>
                handleInputChange("mat_fiscale", e.target.value.toUpperCase())
              }
              placeholder={t("Entrez votre matricule fiscale")}
              error={touched && errors.mat_fiscale}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <SoftTypography variant="caption" fontWeight="bold">
              {t("Régime")}
            </SoftTypography>
            <SoftBox display="flex" alignItems="center" mt={1}>
              <SoftTypography variant="caption" fontWeight="medium" mr={2}>
                {t("Réelle")}
              </SoftTypography>
              <Switch
                checked={!!formFields.regime_forfaitaire}
                onChange={(e) =>
                  handleInputChange("regime_forfaitaire", e.target.checked)
                }
                color="primary"
              />
              <SoftTypography variant="caption" fontWeight="medium" ml={2}>
                {t("Forfaitaire")}
              </SoftTypography>
            </SoftBox>
            {touched && !formFields.regime_forfaitaire && (
              <SoftTypography
                variant="caption"
                color="error"
                fontWeight="bold"
                mt={1}
              >
                {t("Ce champ est obligatoire")}
              </SoftTypography>
            )}
          </Grid>

          <Grid item xs={12} sm={6}>
            <SoftBox mb={2}>
              <SoftTypography
                component="label"
                variant="caption"
                fontWeight="bold"
                textTransform="capitalize"
              >
                {t("Première activité")}
              </SoftTypography>
              <SoftSelect
                value={formFields.first_activity}
                onChange={(value) => handleInputChange("first_activity", value)}
                options={firstactivityList.map((activity) => ({
                  value: t(activity.value),
                  label: t(activity.label),
                }))}
                placement="top"
                errorLabel={touched && errors.first_activity}
              />
            </SoftBox>
          </Grid>
          <Grid item xs={12} sm={6}>
            <SoftBox mb={2}>
              <SoftTypography
                component="label"
                variant="caption"
                fontWeight="bold"
                textTransform="capitalize"
              >
                {t("Deuxième activité")}
              </SoftTypography>
              <SoftSelect
                value={formFields.second_activity}
                onChange={(value) =>
                  handleInputChange("second_activity", value)
                }
                options={secondactivityList.map((activity) => ({
                  value: t(activity.value),
                  label: t(activity.label),
                }))}
                placement="top"
                errorLabel={touched && errors.second_activity}
              />
            </SoftBox>
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormField
              label={t("Mot de passe")}
              name="password"
              type="password"
              value={formFields.password}
              onChange={(e) => handleInputChange("password", e.target.value)}
              placeholder={t("Entrez votre mot de passe")}
              error={touched && errors.password}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormField
              label={t("Confirmer le mot de passe")}
              name="password2"
              type="password"
              value={formFields.password2}
              onChange={(e) => handleInputChange("password2", e.target.value)}
              placeholder={t("Confirmez votre mot de passe")}
              error={touched && errors.password2}
            />
          </Grid>
        </Grid>
      </SoftBox>
    </SoftBox>
  );
}

export default UserInfo;
