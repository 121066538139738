import axios from "./index";

// WarehouseApi class handles operations related to warehouse management
class WarehouseApi {
  /**
   * Fetches the list of warehouse managers.
   * @param {string} token - The authentication token for the user.
   * @returns {Promise} - The response containing the list of warehouse managers.
   */
  static GetWarehouseManagersList = (token) => {
    return axios.get(`${base}/warehouse_managers`, {
      headers: { Authorization: `Bearer ${token}` },
    });
  };

  /**
   * Fetches the list of warehouses.
   * @param {string} token - The authentication token for the user.
   * @returns {Promise} - The response containing the list of warehouses.
   */
  static GetWarehouseList = (token) => {
    return axios.get(`${base}/warehouse_list`, {
      headers: { Authorization: `Bearer ${token}` },
    });
  };

  static assignDefaultWarehouseTOManager = (token, ID) => {
    return axios.post(
      `${base}/assign_default_warehouse/${ID}`,
      {},
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
  };
}

// Base URL for the warehouse-related API
let base = "accounts";

export default WarehouseApi;
