import React, { useState, useEffect } from "react";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import ProductsApi from "api/products";
import { useNavigate, useParams } from "react-router-dom";
import CircularProgress from "@mui/material/CircularProgress";
import SoftTypography from "components/SoftTypography";
import FormField from "./components/FormField";
import SoftBox from "components/SoftBox";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import SoftButton from "components/SoftButton";
import SoftSelect from "components/SoftSelect";
import Footer from "examples/Footer";
import { useTranslation } from "react-i18next";

function EditPromotion() {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const { promotionId } = useParams();

  const [promotionInfo, setPromotionInfo] = useState({});
  const [loading, setLoading] = useState(false);
  const [loadingSave, setLoadingSave] = useState(false);
  const [products, setProducts] = useState([]);

  const fetchAllProducts = async (token) => {
    try {
      const response = await ProductsApi.GetAllProducts(token);
      setProducts(response.data);
    } catch (error) {
      console.error("Error fetching products:", error);
    }
  };

  const fetchPromotedProductById = async (token, promotionId) => {
    try {
      const response = await ProductsApi.GetPromotedProductById(
        promotionId,
        token
      );
      const { name, product_id, discount_rate, start_date, end_date } =
        response.data;
      setPromotionInfo({
        name,
        product_id: { value: product_id, label: product_id.product_name },
        discount_rate,
        start_date: new Date(start_date).toISOString().substring(0, 16),
        end_date: new Date(end_date).toISOString().substring(0, 16),
        product_name: response.data.product_name,
      });
    } catch (error) {
      console.error("Error fetching promoted product:", error);
    }
  };

  useEffect(() => {
    const token = localStorage.getItem("token");
    if (!token) {
      console.error("No token found in localStorage.");
      return;
    }

    const fetchData = async () => {
      setLoading(true);

      try {
        await Promise.all([
          fetchAllProducts(token),
          fetchPromotedProductById(token, promotionId),
        ]);
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [promotionId]);

  const handlePromotionInfoChange = (newInfo) => {
    setPromotionInfo({
      ...promotionInfo,
      ...newInfo,
    });
  };

  const handleSave = () => {
    const token = localStorage.getItem("token");
    if (!token) {
      console.error("No token found in localStorage.");
      return;
    }

    setLoadingSave(true);

    const payload = {
      name: promotionInfo.name,
      product_id: promotionInfo.product_id.value,
      discount_rate: promotionInfo.discount_rate,
      start_date: promotionInfo.start_date,
      end_date: promotionInfo.end_date,
    };

    ProductsApi.UpdatePromotedProduct(payload, promotionId, token)
      .then((response) => {
        console.log("Promotion updated successfully:", response.data);
        navigate("/promotions");
      })
      .catch((error) => {
        console.error(error.response?.data?.msg || error.message);
      })
      .finally(() => {
        setLoadingSave(false);
      });
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      {loading ? (
        <SoftBox
          display="flex"
          justifyContent="center"
          alignItems="center"
          p={35}
        >
          <CircularProgress sx={{ color: "#1F51FF" }} />
        </SoftBox>
      ) : (
        <SoftBox
          display="flex"
          flexDirection="column"
          justifyContent="space-between"
          minHeight="85vh"
        >
          <SoftBox py={3} mt={10}>
            <Grid container justifyContent="center" sx={{ height: "100%" }}>
              <Grid item xs={12} sm={10} md={8} lg={6}>
                <Card elevation={3}>
                  <CardContent>
                    <SoftTypography variant="h5" gutterBottom>
                      {t("Informations sur la promotion")}
                    </SoftTypography>
                    <SoftBox mt={3}>
                      <Grid container spacing={3}>
                        <Grid item xs={12} sm={6}>
                          <FormField
                            type="text"
                            label={t("Nom de la promotion")}
                            placeholder={t("Nom de la promotion")}
                            value={promotionInfo.name || ""}
                            onChange={(e) =>
                              handlePromotionInfoChange({
                                name: e.target.value,
                              })
                            }
                          />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <SoftBox mb={2}>
                            <SoftTypography
                              component="label"
                              variant="caption"
                              fontWeight="bold"
                              textTransform="capitalize"
                            >
                              {t("Produit")}
                            </SoftTypography>
                            <SoftSelect
                              label={t("Produit")}
                              placeholder={promotionInfo.product_name}
                              onChange={(newValue) =>
                                handlePromotionInfoChange({
                                  product_id: newValue,
                                })
                              }
                              options={products.map((product) => ({
                                value: product.id,
                                label: product.product_name,
                              }))}
                            />
                          </SoftBox>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <FormField
                            type="number"
                            label={t("Taux de remise")}
                            placeholder={t("Taux de remise")}
                            value={promotionInfo.discount_rate || ""}
                            onChange={(e) =>
                              handlePromotionInfoChange({
                                discount_rate: e.target.value,
                              })
                            }
                          />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <FormField
                            type="datetime-local"
                            label={t("Date de Début")}
                            value={promotionInfo.start_date || ""}
                            onChange={(e) =>
                              handlePromotionInfoChange({
                                start_date: e.target.value,
                              })
                            }
                          />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <FormField
                            type="datetime-local"
                            label={t("Date de Fin")}
                            value={promotionInfo.end_date || ""}
                            onChange={(e) =>
                              handlePromotionInfoChange({
                                end_date: e.target.value,
                              })
                            }
                          />
                        </Grid>
                      </Grid>
                    </SoftBox>
                    <SoftBox mt={2} display="flex" justifyContent="flex-end">
                      <SoftButton
                        disabled={loadingSave}
                        type="submit"
                        variant="gradient"
                        color="dark"
                        onClick={handleSave}
                      >
                        {loadingSave ? (
                          <CircularProgress size={24} color="inherit" />
                        ) : (
                          t("Enregistrer")
                        )}
                      </SoftButton>
                    </SoftBox>
                  </CardContent>
                </Card>
              </Grid>
            </Grid>
          </SoftBox>
          <Footer />
        </SoftBox>
      )}
    </DashboardLayout>
  );
}

export default EditPromotion;
