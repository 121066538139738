function numberToWordsArabic(num) {
  const ones = [
    "",
    "واحد",
    "اثنان",
    "ثلاثة",
    "أربعة",
    "خمسة",
    "ستة",
    "سبعة",
    "ثمانية",
    "تسعة",
  ];

  const tens = [
    "",
    "عشرة",
    "عشرون",
    "ثلاثون",
    "أربعون",
    "خمسون",
    "ستون",
    "سبعون",
    "ثمانون",
    "تسعون",
  ];

  const teens = [
    "عشرة",
    "أحد عشر",
    "اثنا عشر",
    "ثلاثة عشر",
    "أربعة عشر",
    "خمسة عشر",
    "ستة عشر",
    "سبعة عشر",
    "ثمانية عشر",
    "تسعة عشر",
  ];

  const scales = ["", "ألف", "مليون", "مليار", "تريليون"];

  // Chunk the number into three-digit groups
  const chunkify = (n) => {
    let chunks = [];
    let numStr = String(n);
    while (numStr.length > 0) {
      chunks.unshift(numStr.slice(-3));
      numStr = numStr.slice(0, -3);
    }
    return chunks;
  };

  const convertChunk = (chunk) => {
    let number = parseInt(chunk, 10);
    if (number === 0) return "";

    let words = [];
    if (number >= 100) {
      let hundreds = Math.floor(number / 100);

      // Handle special case for 100s (مائة) and its plural form (مئتين)
      if (hundreds === 1) {
        words.push("مائة");
      } else if (hundreds === 2) {
        words.push("مئتان");
      } else if (hundreds > 2) {
        words.push(ones[hundreds] + " مائة");
      }
      number = number % 100;

      // Add "و" if there are tens or ones following
      if (number > 0) {
        words.push("و");
      }
    }

    if (number >= 20) {
      let tensPart = Math.floor(number / 10);
      number = number % 10;

      // Add "و" if there is a ones part after the tens
      if (number > 0) {
        words.push(ones[number]);
        words.push("و"); // Add "و" between the tens and ones
      }

      words.push(tens[tensPart]);
    } else if (number >= 10) {
      words.push(teens[number - 10]);
    } else if (number > 0) {
      words.push(ones[number]);
    }

    return words.join(" ");
  };
  
  let [integerPart, decimalPart] = String(num).split(".");

  let chunks = chunkify(integerPart);
  let words = [];

  // Handle each chunk of the integer part
  for (let i = 0; i < chunks.length; i++) {
    const chunkWords = convertChunk(chunks[i]);
    if (chunkWords) {
      let scale = scales[chunks.length - i - 1];

      // Special case for 2000
      if (scale === "ألف" && chunks[i] === "2") {
        words.push("ألفين");
      } else if (scale === "ألف" && chunkWords === "واحد") {
        words.push("ألف");
      } else {
        // For numbers like "200" or "1500", add "و" before the next chunk
        words.push(chunkWords + (scale ? " " + scale : ""));
      }

      // Add "و" only if it's not the last chunk
      if (i < chunks.length - 1) {
        words.push("و");
      }
    }
  }

  if (words.length === 0) {
    words.push("صفر");
  }

  words.push("دينار");

  // Handle decimal part (millimes)
  if (decimalPart) {
    let decimalWords = convertChunk(decimalPart);

    // Adjust the conjunction placement in the decimal part
    let decimalChunks = decimalWords.split(" ");
    if (decimalChunks.length > 1) {
      decimalChunks = decimalChunks.filter((word) => word !== "و");
    }
    decimalWords = decimalChunks.join(" و ");

    words.push("و " + decimalWords + " مليم");
  }

  // Capitalize the first letter and return the sentence
  let result = words.join(" ").trim();
  return result.charAt(0).toUpperCase() + result.slice(1);
}

export default numberToWordsArabic;
