import { createContext, useContext, useReducer, useMemo } from "react"; // Import necessary hooks from React

// prop-types is a library for typechecking of props
import PropTypes from "prop-types"; // Import PropTypes for prop type validation

// The Soft UI Dashboard PRO React main context
const SoftUI = createContext(null); // Create a context for the Soft UI

// Setting custom name for the context which is visible on React DevTools
SoftUI.displayName = "SoftUIContext"; // Set a display name for debugging

// Soft UI Dashboard PRO React reducer function
function reducer(state, action) {
  switch (action.type) {
    case "MINI_SIDENAV": {
      return { ...state, miniSidenav: action.value }; // Update miniSidenav state
    }
    case "TRANSPARENT_SIDENAV": {
      return { ...state, transparentSidenav: action.value }; // Update transparentSidenav state
    }
    case "SIDENAV_COLOR": {
      return { ...state, sidenavColor: action.value }; // Update sidenavColor state
    }
    case "TRANSPARENT_NAVBAR": {
      return { ...state, transparentNavbar: action.value }; // Update transparentNavbar state
    }
    case "FIXED_NAVBAR": {
      return { ...state, fixedNavbar: action.value }; // Update fixedNavbar state
    }
    case "OPEN_CONFIGURATOR": {
      return { ...state, openConfigurator: action.value }; // Update openConfigurator state
    }
    case "DIRECTION": {
      return { ...state, direction: action.value }; // Update direction state
    }
    case "LAYOUT": {
      return { ...state, layout: action.value }; // Update layout state
    }
    default: {
      throw new Error(`Unhandled action type: ${action.type}`); // Handle unrecognized action types
    }
  }
}

// Soft UI Dashboard PRO React context provider component
function SoftUIControllerProvider({ children }) {
  // Initial state of the context
  const initialState = {
    miniSidenav: false,
    transparentSidenav: true,
    sidenavColor: "dark",
    transparentNavbar: true,
    fixedNavbar: true,
    openConfigurator: false,
    direction: "ltr",
    layout: "dashboard",
  };

  // useReducer hook to manage state and dispatch actions
  const [controller, dispatch] = useReducer(reducer, initialState);

  // Memoize the value to optimize performance
  const value = useMemo(() => [controller, dispatch], [controller, dispatch]);

  return <SoftUI.Provider value={value}>{children}</SoftUI.Provider>; // Provide the context value to children
}

// Soft UI Dashboard PRO React custom hook for using the context
function useSoftUIController() {
  const context = useContext(SoftUI); // Use the context

  if (!context) {
    throw new Error(
      "useSoftUIController should be used inside the SoftUIControllerProvider."
    ); // Error if used outside provider
  }

  return context; // Return the context value
}

// Typechecking props for the SoftUIControllerProvider
SoftUIControllerProvider.propTypes = {
  children: PropTypes.node.isRequired, // Ensure children prop is a valid node
};

// Context module functions for dispatching actions
const setMiniSidenav = (dispatch, value) =>
  dispatch({ type: "MINI_SIDENAV", value }); // Dispatch action to set mini sidenav
const setTransparentSidenav = (dispatch, value) =>
  dispatch({ type: "TRANSPARENT_SIDENAV", value }); // Dispatch action to set transparent sidenav
const setSidenavColor = (dispatch, value) =>
  dispatch({ type: "SIDENAV_COLOR", value }); // Dispatch action to set sidenav color
const setTransparentNavbar = (dispatch, value) =>
  dispatch({ type: "TRANSPARENT_NAVBAR", value }); // Dispatch action to set transparent navbar
const setFixedNavbar = (dispatch, value) =>
  dispatch({ type: "FIXED_NAVBAR", value }); // Dispatch action to set fixed navbar
const setOpenConfigurator = (dispatch, value) =>
  dispatch({ type: "OPEN_CONFIGURATOR", value }); // Dispatch action to open configurator
const setDirection = (dispatch, value) =>
  dispatch({ type: "DIRECTION", value }); // Dispatch action to set direction
const setLayout = (dispatch, value) => dispatch({ type: "LAYOUT", value }); // Dispatch action to set layout

// Export the provider, custom hook, and action functions for use in other components
export {
  SoftUIControllerProvider,
  useSoftUIController,
  setMiniSidenav,
  setTransparentSidenav,
  setSidenavColor,
  setTransparentNavbar,
  setFixedNavbar,
  setOpenConfigurator,
  setDirection,
  setLayout,
};
