import React from "react";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import DataTable from "examples/Tables/DataTable";
import { useTranslation } from "react-i18next";

function Caisse() {
  const { t } = useTranslation();

  const staticData = [
    {
      date: "1/1/2024",
      type_operation: "Vente",
      description: "client afro best",
      entree: 350.0,
      sortie: 0,
      solde: 845.0,
    },
    {
      date: "1/2/2024",
      type_operation: "Vente",
      description: "client afro best",
      entree: 530.0,
      sortie: 0,
      solde: 1375.0,
    },
    {
      date: "1/2/2024",
      type_operation: "Vente",
      description: "Client BBK",
      entree: 1350.0,
      sortie: 0,
      solde: 2725.0,
    },
    {
      date: "3/3/2024",
      type_operation: "Vente",
      description: "Client BBK",
      entree: 2280.0,
      sortie: 0,
      solde: 5005.0,
    },
    {
      date: "15/03/2024",
      type_operation: "Depenses",
      description: "Paiment Facture Steg",
      entree: 0,
      sortie: 310.0,
      solde: 4695.0,
    },
    {
      date: "4/14/2024",
      type_operation: "Depenses",
      description: "Paiement loyer",
      entree: 0,
      sortie: 420.0,
      solde: 4275.0,
    },
    {
      date: "5/15/2024",
      type_operation: "Achat",
      description: "Fournisseur de Mayonnaise",
      entree: 0,
      sortie: 2600.0,
      solde: 1675.0,
    },
  ];

  // Calculate totals
  const totalEntree = staticData.reduce((sum, row) => sum + row.entree, 0);
  const totalSortie = staticData.reduce((sum, row) => sum + row.sortie, 0);
  const finalSolde = staticData[staticData.length - 1].solde;

  // Define columns
  const columns = [
    { Header: "Date", accessor: "date" },
    { Header: t("Type operation"), accessor: "type_operation" },
    { Header: t("Description"), accessor: "description" },
    { Header: t("Entrée"), accessor: "entree" },
    { Header: t("Sortie"), accessor: "sortie" },
    { Header: t("Solde"), accessor: "solde" },
  ];

  // Define rows
  const rows = staticData.map((row) => ({
    date: row.date,
    type_operation: row.type_operation,
    description: row.description,
    entree: row.entree ? row.entree.toFixed(2) : "-",
    sortie: row.sortie ? row.sortie.toFixed(2) : "-",
    solde: row.solde.toFixed(2),
  }));

  rows.push({
    date: "",
    type_operation: <strong>{t("Total Mouvements")}</strong>,
    description: "",
    entree: <strong>{totalEntree.toFixed(2)}</strong>,
    sortie: <strong>{totalSortie.toFixed(2)}</strong>,
    solde: "",
  });
  rows.push({
    date: "",
    type_operation: "",
    description: <strong>{t("Solde")}</strong>,
    entree: "",
    sortie: "",
    solde: <strong>1675.0</strong>,
  });

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <SoftBox
        display="flex"
        flexDirection="column"
        justifyContent="space-between"
        minHeight="85vh"
      >
        <SoftBox m={3}>
          <Grid container spacing={3} justifyContent="center">
            <Grid item xs={12}>
              <Card>
                <SoftBox p={3}>
                  <SoftTypography variant={"h4"} textAlign="center">
                    {t("Caisse Magasin")}
                  </SoftTypography>
                  <SoftTypography variant={"h6"} textAlign="right">
                    8/31/2024
                  </SoftTypography>
                </SoftBox>
                <SoftBox px={3} pb={3}>
                  <SoftTypography variant={"h6"} textAlign="right">
                    {t("Solde Ouverture")}: 495.00
                  </SoftTypography>
                  <SoftTypography variant={"h6"} textAlign="right">
                    {t("Solde finale")}: {finalSolde.toFixed(2)}
                  </SoftTypography>
                </SoftBox>
                <SoftBox p={3}>
                  <DataTable
                    table={{ columns, rows }}
                    entriesPerPage={false}
                    columnMinWidth={70}
                  />
                </SoftBox>
              </Card>
            </Grid>
          </Grid>
        </SoftBox>
        <Footer />
      </SoftBox>
    </DashboardLayout>
  );
}

export default Caisse;
