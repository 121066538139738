import React, { useEffect, useState } from "react";
import { CircularProgress, Card, Stack, Pagination } from "@mui/material";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DataTable from "examples/Tables/DataTable";
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import OrdersApi from "api/orders";
import ActionCell from "./components/ActionCell";
import { useTranslation } from "react-i18next";

function ShoppingItems() {
  const [orders, setOrders] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const { t } = useTranslation();

  const handleGetOrders = (page) => {
    const token = localStorage.getItem("token");

    if (!token) {
      console.error("No token found in localStorage.");
      setError("No token found in localStorage.");
      setLoading(false);
      return;
    }

    OrdersApi.GetShoppingItems(token, page)
      .then((response) => {
        if (response.data) {
          const filteredOrders = response.data.results.filter(
            (order) => order.shopping_items?.length > 0
          );
          setOrders(filteredOrders);
          setTotalPages(Math.ceil(filteredOrders.length / 9));
        } else {
          console.warn("No data found:", response.data.msg);
        }
      })
      .catch((error) => {
        if (error.response) {
          console.error("Error response data:", error.response.data.msg);
          console.error("Full error response:", error.response);
        } else {
          console.error("There has been an error.");
        }
        setError(error.response?.data?.msg || error.message);
      })
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    handleGetOrders(page);
  }, [page]);

  const columns = React.useMemo(
    () => [
      {
        Header: `${t("Client")}`,
        accessor: "client",
        width: 70,
      },
      { Header: `${t("Prix total")}`, accessor: "order_total_price" },
      {
        Header: t("action"),
        accessor: "action",
        Cell: ({ row }) => (
          <ActionCell client_id={row.original.client_id} page={page} />
        ),
      },
    ],
    // eslint-disable-next-line
    []
  );

  const rows = React.useMemo(
    () =>
      orders.map((order) => {
        let total_price = 0;

        if (Array.isArray(order.shopping_items)) {
          if (Array.isArray(order.shopping_items[0])) {
            total_price = order.shopping_items[1]?.order_total_price || 0;
          } else {
            total_price = order.shopping_items[0]?.order_total_price || 0;
          }
        } else {
          total_price = order.shopping_items?.order_total_price || 0;
        }

        return {
          client_id: order.client_id,
          client: order.client,
          order_total_price: `${total_price.toFixed(3)} TND`,
        };
      }),
    [orders]
  );

  const handlePageChange = (event, value) => {
    setPage(value);
  };

  if (error) {
    return (
      <DashboardLayout>
        <DashboardNavbar />
        <SoftBox my={3}>
          <SoftTypography color="error">{error}</SoftTypography>
        </SoftBox>
        <Footer />
      </DashboardLayout>
    );
  }

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <SoftBox
        display="flex"
        flexDirection="column"
        justifyContent="space-between"
        minHeight="85vh"
      >
        <SoftBox
          sx={{
            width: { xs: "100%", xl: "75%" },
            margin: "auto",
            marginTop: "5%",
            marginBottom: "5%",
          }}
        >
          <Card>
            {loading ? (
              <SoftBox
                display="flex"
                justifyContent="center"
                alignItems="center"
                p={35}
              >
                <CircularProgress sx={{ color: "#1F51FF" }} />
              </SoftBox>
            ) : (
              <>
                <DataTable
                  table={{ columns, rows }}
                  entriesPerPage={false}
                  canSearch
                  columnMinWidth={70}
                />
                {totalPages > 1 && (
                  <Stack
                    spacing={2}
                    sx={{
                      mt: 2,
                      position: "absolute",
                      bottom: 20,
                      left: 200,
                      transform: "translateX(-50%)",
                    }}
                  >
                    <Pagination
                      count={totalPages}
                      page={page}
                      onChange={handlePageChange}
                      color="secondary"
                    />
                  </Stack>
                )}
              </>
            )}
          </Card>
        </SoftBox>
        <Footer />
      </SoftBox>
    </DashboardLayout>
  );
}

export default ShoppingItems;
