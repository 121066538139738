import React, { useState } from "react";
import { Card, Grid, Typography, Box, IconButton } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import SoftBox from "components/SoftBox";
import Footer from "examples/Footer";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import SoftButton from "components/SoftButton";
import FormField from "../ajouter-articles/components/FormField";
import SupplierApi from "api/supplier";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

function NewSupplier() {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const [suppliersList, setSuppliersList] = useState([
    { name: "", phone: "", email: "" },
  ]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  const handleAddSupplier = () => {
    setSuppliersList([...suppliersList, { name: "", phone: "", email: "" }]);
  };

  const handleRemoveSupplier = (index) => {
    if (suppliersList.length > 1) {
      const updatedSuppliers = suppliersList.filter((_, i) => i !== index);
      setSuppliersList(updatedSuppliers);
    }
  };

  const handleChange = (index, field, value) => {
    const updatedSuppliers = suppliersList.map((supplier, i) =>
      i === index ? { ...supplier, [field]: value } : supplier
    );
    setSuppliersList(updatedSuppliers);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError("");

    const token = localStorage.getItem("token");

    try {
      // Validate form fields (basic validation)
      for (const supplier of suppliersList) {
        if (!supplier.name || !supplier.phone || !supplier.email) {
          setError("Tous les champs sont obligatoires.");
          setLoading(false);
          return;
        }
      }

      for (const supplier of suppliersList) {
        await SupplierApi.CreateSupplier(token, {
          name: supplier.name,
          phone: supplier.phone,
          email: supplier.email,
        });
      }

      // Clear form after successful submission
      setSuppliersList([{ name: "", phone: "", email: "" }]);
      navigate("/gestion-de-stock/listes-des-fournisseurs");
    } catch (err) {
      setError("Erreur lors de la création des fournisseurs.");
      console.error("Create supplier error:", err);
    } finally {
      setLoading(false);
    }
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <SoftBox
        display="flex"
        flexDirection="column"
        justifyContent="space-between"
        minHeight="85vh"
      >
        <SoftBox py={3} px={14}>
          <Card>
            <SoftBox py={3} px={5}>
              <Box>
                <Typography variant="h6" fontWeight="bold" mb={2}>
                  {t("Créer des fournisseurs")}
                </Typography>

                {/* Display error message */}
                {error && <Typography color="error">{error}</Typography>}

                <form onSubmit={handleSubmit}>
                  {/* Render input fields for each supplier */}
                  {suppliersList.map((supplier, index) => (
                    <Grid container spacing={3} key={index} mb={2}>
                      <Grid item xs={12} sm={3.5}>
                        <FormField
                          autoFocus
                          margin="dense"
                          label={t("Nom")}
                          fullWidth
                          value={supplier.name}
                          onChange={(e) =>
                            handleChange(index, "name", e.target.value)
                          }
                        />
                      </Grid>
                      <Grid item xs={12} sm={3.5}>
                        <FormField
                          margin="dense"
                          label={t("Téléphone")}
                          fullWidth
                          value={supplier.phone}
                          onChange={(e) =>
                            handleChange(index, "phone", e.target.value)
                          }
                        />
                      </Grid>
                      <Grid item xs={12} sm={3.5}>
                        <FormField
                          margin="dense"
                          label={t("Email")}
                          fullWidth
                          value={supplier.email}
                          onChange={(e) =>
                            handleChange(index, "email", e.target.value)
                          }
                        />
                      </Grid>
                      <Grid item xs={12} sm={1} my="auto">
                        <IconButton
                          color="error"
                          onClick={() => handleRemoveSupplier(index)}
                          sx={{ mt: 3 }}
                          disabled={suppliersList.length === 1}
                        >
                          <DeleteIcon />
                        </IconButton>
                      </Grid>
                    </Grid>
                  ))}
                  <Box display="flex" mt={4}>
                    <SoftButton
                      variant="outlined"
                      color="info"
                      onClick={handleAddSupplier}
                    >
                      <AddIcon /> {t("Ajouter un fournisseur")}
                    </SoftButton>
                  </Box>

                  <Box display="flex" justifyContent="flex-end" mt={4}>
                    <SoftButton
                      variant="contained"
                      color="info"
                      type="submit"
                      disabled={loading}
                    >
                      {loading ? `${t("Création")}...` : `${t("Créer")}`}
                    </SoftButton>
                  </Box>
                </form>
              </Box>
            </SoftBox>
          </Card>
        </SoftBox>
        <Footer />
      </SoftBox>
    </DashboardLayout>
  );
}

export default NewSupplier;
