// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftBadge from "components/SoftBadge";
import { useTranslation } from "react-i18next";

function ProductInfo({ product }) {
  const { t } = useTranslation();

  // Function to strip HTML tags from a string
  const stripHtmlTags = (htmlString) => {
    const regex = /(<([^>]+)>)/gi;
    return htmlString?.replace(regex, "");
  };

  return (
    <SoftBox>
      <SoftBox mb={1}>
        <SoftTypography variant="h3" fontWeight="bold">
          {product?.product_name}
        </SoftTypography>
      </SoftBox>
      <SoftBox mb={1}>
        <SoftTypography variant="h5" fontWeight="medium">
          {product?.total_price} TND
        </SoftTypography>
      </SoftBox>
      <SoftBadge
        variant="contained"
        color="success"
        badgeContent={t("En stock")}
        container
      />
      <SoftBox>
        <SoftTypography
          variant="body1"
          component="div"
          dangerouslySetInnerHTML={{
            __html: stripHtmlTags(product?.description),
          }}
        />
      </SoftBox>
    </SoftBox>
  );
}

export default ProductInfo;
