// @mui material components
import Card from "@mui/material/Card";

// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftButton from "components/SoftButton";
import { handleUpload } from "utils/ImageUploader";
import { useState, useRef } from "react";
import { useTranslation } from "react-i18next";

function ProductImage({ onChange, defaultImage }) {
    const { t } = useTranslation();

  const [image, setImage] = useState("");
  const fileInputRef = useRef(null);

  const handleButtonClick = () => {
    fileInputRef.current.click();
  };

  const handleImageUpload = async (event) => {
    const file = event.target.files[0];

    try {
      const imageUrl = await handleUpload(file);
      setImage(imageUrl);
      onChange({ product_image: imageUrl });
    } catch (error) {
      console.error("Error uploading image:", error);
    }
  };

  return (
    <Card sx={{ height: "100%" }}>
      <SoftBox p={3}>
        <SoftTypography variant="h5" fontWeight="bold">
          {t("Image du produit")}
        </SoftTypography>
        <SoftBox
          component="img"
          src={image || defaultImage}
          alt={t("Image du produit")}
          borderRadius="lg"
          shadow="lg"
          width="100%"
          my={3}
        />
        <input
          type="file"
          accept="image/*"
          ref={fileInputRef}
          style={{ display: "none" }}
          onChange={handleImageUpload}
        />
        <SoftBox display="flex">
          <SoftBox mr={1}>
            <SoftButton
              variant="gradient"
              color="info"
              size="small"
              onClick={handleButtonClick}
            >
              {t("Modifier")}
            </SoftButton>
          </SoftBox>
        </SoftBox>
      </SoftBox>
    </Card>
  );
}

export default ProductImage;
