import { forwardRef } from "react";
import PropTypes from "prop-types";
import Select from "react-select";
import colors from "assets/theme/base/colors";
import styles from "components/SoftSelect/styles";
import SoftTypography from "components/SoftTypography";

const SoftSelect = forwardRef(
  (
    {
      size = "medium",
      error = false,
      success = false,
      placement = "auto",
      errorLabel,
      isMulti = false,
      disabled = false,
      styles: customStyles, // Add customStyles here
      ...rest
    },
    ref
  ) => {
    const { light } = colors;

    const menuPlacement = placement === "top" ? "top" : "auto";

    // Merge the customStyles with the default styles
    const mergedStyles = {
      ...styles(size, error, success),
      menu: (provided) => ({
        ...provided,
        zIndex: 9999, // Ensure the dropdown appears above other content
        ...customStyles?.menu, // Apply custom styles if provided
      }),
      multiValue: (provided) => ({
        ...provided,
        borderRadius: "4px",
        backgroundColor: light.main, // Custom background color for selected items
        minWidth: "150px",
        ...customStyles?.multiValue, // Apply custom styles if provided
      }),
      multiValueLabel: (provided) => ({
        ...provided,
        color: light.text, // Custom text color for selected items
        ...customStyles?.multiValueLabel, // Apply custom styles if provided
      }),
      multiValueRemove: (provided) => ({
        ...provided,
        color: light.text, // Custom color for remove button
        ":hover": {
          backgroundColor: light.hover, // Hover effect for remove button
          color: light.text,
        },
        ...customStyles?.multiValueRemove, // Apply custom styles if provided
      }),
      menuList: (provided) => ({
        ...provided,
        maxHeight: "200px", // Adjust height as needed
        ...customStyles?.menuList, // Apply custom styles if provided
      }),
      ...customStyles, // Merge customStyles with the default ones
    };

    return (
      <>
        <Select
          {...rest}
          ref={ref}
          isMulti={isMulti}
          closeMenuOnSelect={isMulti ? false : true}
          isDisabled={disabled}
          styles={mergedStyles} // Use merged styles here
          theme={(theme) => ({
            ...theme,
            colors: {
              ...theme.colors,
              primary25: light.main,
              primary: light.main,
            },
          })}
          menuPlacement={menuPlacement}
          getOptionLabel={(option) => option.label}
          getOptionValue={(option) => option.value}
        />
        {errorLabel && (
          <SoftTypography component="error" color="error" variant="caption">
            {errorLabel}
          </SoftTypography>
        )}
      </>
    );
  }
);

SoftSelect.propTypes = {
  size: PropTypes.oneOf(["small", "medium", "large"]),
  error: PropTypes.bool,
  success: PropTypes.bool,
  placement: PropTypes.oneOf(["auto", "top"]),
  isMulti: PropTypes.bool,
  disabled: PropTypes.bool,
  styles: PropTypes.object, // Accept the custom styles prop
};

export default SoftSelect;
