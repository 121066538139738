import React, { useState, useEffect } from "react";
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import { Card } from "@mui/material";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import DataTable from "examples/Tables/DataTable";
import Footer from "examples/Footer";
import StatusCell from "./components/StatusCell";
import TransferApi from "api/transfer";
import { useTranslation } from "react-i18next";

const MesDemandesDeStock = () => {
  const { t } = useTranslation();

  const [demandes, setDemandes] = useState([]);
  const [loading, setLoading] = useState(true);

  const handleGetTransferRequests = () => {
    const token = localStorage.getItem("token");

    if (!token) {
      console.error("No token found in localStorage.");
      setLoading(false);
      return;
    }

    TransferApi.GetTransferRequestsList(token)
      .then((response) => {
        if (response.data) {
          setDemandes(response.data);
        } else {
          console.warn("No data found:", response.data.msg);
        }
      })
      .catch((error) => {
        if (error.response) {
          console.error("Error response data:", error.response.data.msg);
          console.error("Full error response:", error.response);
        } else {
          console.error("There has been an error.");
        }
        console.log(error.response?.data?.msg || error.message);
      })
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    handleGetTransferRequests();
  }, []);

  // Define columns for the DataTable
  const columns = [
    { Header: "Date", accessor: "date", align: "left" },
    {
      Header: `${t("Nom de produit")}`,
      accessor: "product_name",
      align: "left",
    },
    { Header: `${t("Quantité")}`, accessor: "quantity", align: "center" },
    {
      Header: `${t("Entrepôt demandé")}`,
      accessor: "requested_warehouse",
      align: "left",
    },
    { Header: `${t("Statut")}`, accessor: "status", align: "center" },
  ];

  // Map the data into rows for DataTable
  const rows = demandes.map((demande) => {
    const date = new Date(demande.created_at).toLocaleDateString(); // Format date
    const productNames = Object.entries(demande.requested_prod_qts)
      .map(
        ([id, product]) => product.split(",")[0].replace(/[()]/g, "").trim() // Remove parentheses and trim spaces
      )
      .join(", "); // Join product names with a comma

    // Determine the display status based on the demande status
    const displayStatus = (() => {
      switch (demande.status) {
        case "draft":
          return t("En attente"); // Translate to "En attente"
        case "cancelled":
          return t("Annulé"); // Translate to "Annulé"
        case "validated":
          return t("Validé"); // Translate to "Validé"
        default:
          return demande.status; // Fallback to original status if not matched
      }
    })();

    return {
      date,
      product_name: productNames, // Use only product names without parentheses
      quantity: Object.values(demande.requested_prod_qts).reduce(
        (total, product) => {
          const quantityMatch = product.match(/(\d+)/);
          return total + (quantityMatch ? parseInt(quantityMatch[0], 10) : 0);
        },
        0
      ),
      requested_warehouse: demande.warehouse_requested_name,
      status: (
        <StatusCell
          status={displayStatus} // Use the translated display status
          color={
            displayStatus === t("Validé")
              ? "success"
              : displayStatus === t("En attente")
              ? "warning"
              : "error"
          }
          icon={
            displayStatus === t("Validé")
              ? "check"
              : displayStatus === t("En attente")
              ? "access_time"
              : displayStatus === t("Annulé")
              ? "error_outline"
              : "error"
          }
        />
      ),
    };
  });

  return (
    <DashboardLayout>
      <DashboardNavbar />

      <SoftBox
        p={3}
        display="flex"
        flexDirection="column"
        justifyContent="space-between"
        minHeight="85vh"
      >
        <Card>
          <SoftTypography variant="h6" mb={2} p={3}>
            {t("Mes Demandes de Stock")}
          </SoftTypography>

          <DataTable
            table={{ columns, rows }} // Passing columns and rows to the DataTable
            entriesPerPage={false} // Disabling entries per page feature
            loading={loading} // Show loading indicator while fetching data
          />
        </Card>
        <Footer />
      </SoftBox>
    </DashboardLayout>
  );
};

export default MesDemandesDeStock;
