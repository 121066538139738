import { useEffect } from "react";

// import AuthApi from "../../../api/auth";
// import { useAuth } from "../../../auth-context/auth.context";
import { useNavigate } from "react-router-dom";

function SignOut() {
  const navigate = useNavigate();
  // const { user, setUser } = useAuth();

  const handleLogout = async () => {
    // await AuthApi.Logout(user);
    // await setUser(null);
    localStorage.removeItem("user");
    localStorage.removeItem("token");
    return navigate("/authentication/connexion");
  };

  useEffect(() => {
    handleLogout();
    // eslint-disable-next-line
  }, []);

  return null;
}

export default SignOut;
