import axios from "./index";

// CustomersApi class handles operations related to customer management
class CustomersApi {
  /**
   * Fetches the list of all customers by sending a GET request.
   * The request includes an Authorization header with the Bearer token.
   * @param {string} token - The authentication token for the user.
   * @returns {Promise} - The response containing the list of all customers.
   */
  static GetAllCustomers = (token) => {
    return axios.get(`${base}/client_list`, {
      headers: { Authorization: `Bearer ${token}` },
    });
  };

  /**
   * Fetches a paginated list of customers by sending a GET request.
   * The request includes an Authorization header with the Bearer token.
   * @param {string} token - The authentication token for the user.
   * @param {number} [page=1] - The page number for paginated results (default is 1).
   * @returns {Promise} - The response containing the list of customers for the given page.
   */
  static GetCustomers = (token, page = 1) => {
    return axios.get(`${base}/customers/?p=${page}`, {
      headers: { Authorization: `Bearer ${token}` },
    });
  };

  /**
   * Extracts customer data by sending a GET request.
   * The request includes an Authorization header with the Bearer token.
   * @param {string} token - The authentication token for the user.
   * @returns {Promise} - The response containing the extracted customer data.
   */
  static GetCustomersoExtract = (token) => {
    return axios.get(`product/customers_extracted`, {
      headers: { Authorization: `Bearer ${token}` },
    });
  };

  /**
   * Fetches the details of a specific customer by their ID.
   * The request includes an Authorization header with the Bearer token.
   * @param {string|number} customerId - The ID of the customer.
   * @param {string} token - The authentication token for the user.
   * @returns {Promise} - The response containing the customer's details.
   */
  static GetCustomerById = (customerId, token) => {
    return axios.get(`${base}/customers/${customerId}`, {
      headers: { Authorization: `Bearer ${token}` },
    });
  };

  /**
   * Deletes a customer by sending a DELETE request using the customer's ID.
   * The request includes an Authorization header with the Bearer token.
   * @param {string|number} customerId - The ID of the customer to be deleted.
   * @param {string} token - The authentication token for the user.
   * @returns {Promise} - The response after deleting the customer.
   */
  static DeleteCustomer = (customerId, token) => {
    return axios.delete(`${base}/customers/${customerId}`, {
      headers: { Authorization: `Bearer ${token}` },
    });
  };

  /**
   * Updates a customer's information by sending a PUT request with customer data.
   * The request includes an Authorization header with the Bearer token.
   * @param {string|number} customerId - The ID of the customer to be updated.
   * @param {Object} customerData - The updated customer data.
   * @param {string} token - The authentication token for the user.
   * @returns {Promise} - The response after updating the customer information.
   */
  static UpdateCustomer = (customerId, customerData, token) => {
    return axios.put(`${base}/customers/${customerId}/`, customerData, {
      headers: { Authorization: `Bearer ${token}` },
    });
  };
}

// Base URL for the customer-related API
let base = "accounts";

export default CustomersApi;
