import React from "react";
import Icon from "@mui/material/Icon";
import Tooltip from "@mui/material/Tooltip";
import { Link } from "react-router-dom";
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import { useTranslation } from "react-i18next";

function ActionCell({ supplierID }) {
  const { t } = useTranslation();

  return (
    <SoftBox display="flex" alignItems="center">
      <SoftTypography variant="body1" color="grey-400" sx={{ lineHeight: 0 }}>
        <Tooltip
          title={t("Modifier les informations du fournisseur")}
          placement="top"
        >
          <Link
            to={`/gestion-de-stock/modifer-informations-fournisseur`}
            state={{ supplierID }}
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              width: "40px",
              height: "40px",
              borderRadius: "50%",
              transition: "background-color 0.3s ease",
              textDecoration: "none",
              color: "inherit",
            }}
          >
            <Icon>edit</Icon>
          </Link>
        </Tooltip>
      </SoftTypography>
    </SoftBox>
  );
}

export default ActionCell;
