import { useEffect, useState } from "react";
import Grid from "@mui/material/Grid";
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftSelect from "components/SoftSelect";
import {
  firstactivityList,
  secondactivityList,
  storeTypeList,
} from "utils/data";
import SoftInput from "components/SoftInput";
import { useTranslation } from "react-i18next";
import { Switch } from "@mui/material";

function UserInfo({ formData, onChange, validateFormCallback, formSubmitted }) {
  const { t } = useTranslation();

  const [formFields, setFormFields] = useState({
    first_activity: formData?.first_activity || "",
    second_activity: formData?.second_activity || "",
    store_type: formData?.store_type || "",
    firstname: formData?.firstname || "",
    lastname: formData?.lastname || "",
    username: formData?.username || "",
    phone: formData?.phone || "",
    mat_fiscale: formData?.mat_fiscale || "",
    password: formData?.password || "",
    password2: formData?.password2 || "",
    user_type: formData?.user_type || "",
    regime_forfaitaire: formData?.regime_forfaitaire || false,
  });

  const [errors, setErrors] = useState({});

  const validateForm = (submit = false) => {
    const newErrors = {};

    if (!/^\d{8}$/.test(formFields.phone.trim())) {
      newErrors.phone = `${t(
        "Le numéro de téléphone doit contenir 8 chiffres"
      )}`;
    }
    if (
      !/^(\d{3}[A-Z]{3}\d{7}\/[A-Z])$/.test(
        formFields.mat_fiscale.trim()?.toUpperCase()
      )
    ) {
      newErrors.mat_fiscale = `${t(
        "Le matricule fiscal doit être au format correct (123ABC1234567/X)"
      )}`;
    }
    if (formFields.password !== formFields.password2) {
      newErrors.password2 = `${t("Les mots de passe ne correspondent pas")}`;
    }
    setErrors(newErrors);
    validateFormCallback(Object.keys(newErrors).length === 0);
  };

  useEffect(() => {
    onChange(formFields);
    // eslint-disable-next-line
  }, [formFields]);

  useEffect(() => {
    if (formSubmitted) {
      validateForm(true);
    }
    // eslint-disable-next-line
  }, [formSubmitted]);

  const handleInputChange = (fieldName, value) => {
    if (
      ["firstname", "lastname", "username"].includes(fieldName) &&
      /^\d+$/.test(value.trim())
    ) {
      return;
    }
    setFormFields((prevFields) => ({ ...prevFields, [fieldName]: value }));
    setErrors((prevErrors) => ({ ...prevErrors, [fieldName]: null }));
  };

  return (
    <SoftBox>
      <SoftBox lineHeight={0}>
        <SoftTypography variant="h5" fontWeight="bold">
          {t("Informations de client")}
        </SoftTypography>
      </SoftBox>
      <SoftBox mt={2}>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>
            <SoftTypography variant="caption" fontWeight="bold">
              {t("Prénom")}
            </SoftTypography>
            <SoftInput
              type="text"
              value={formFields.firstname || formData.firstname}
              onChange={(e) => handleInputChange("firstname", e.target.value)}
              placeholder={t("Entrez votre prénom")}
              error={formSubmitted && errors.firstname}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <SoftTypography variant="caption" fontWeight="bold">
              {t("Nom de famille")}{" "}
            </SoftTypography>
            <SoftInput
              type="text"
              value={formFields.lastname || formData.lastname}
              onChange={(e) => handleInputChange("lastname", e.target.value)}
              placeholder={t("Entrez votre nom de famille")}
              error={formSubmitted && errors.lastname}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <SoftTypography variant="caption" fontWeight="bold">
              {t("Nom d'utilisateur")}{" "}
            </SoftTypography>
            <SoftInput
              type="text"
              value={formFields.username || formData.username}
              onChange={(e) => handleInputChange("username", e.target.value)}
              placeholder={t("Entrez votre nom d'utilisateur")}
              error={formSubmitted && errors.username}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <SoftTypography variant="caption" fontWeight="bold">
              {t("Numéro de téléphone")}{" "}
            </SoftTypography>
            <SoftInput
              type="text"
              value={formFields.phone || formData.phone}
              onChange={(e) => handleInputChange("phone", e.target.value)}
              placeholder={t("Entrez votre numéro de téléphone")}
              error={formSubmitted && errors.phone}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <SoftBox mb={2}>
              <SoftTypography
                component="label"
                variant="caption"
                fontWeight="bold"
                textTransform="capitalize"
              >
                {t("Type d'utilisateur")}{" "}
              </SoftTypography>
              <SoftSelect
                placeholder={
                  formData?.business_role_id ||
                  `${t("Entrez le type de client")}`
                }
                value={formFields.user_type || formData.user_type}
                onChange={(value) => handleInputChange("user_type", value)}
                options={[{ value: "client", label: "Client" }]}
                errorLabel={formSubmitted && errors.user_type}
              />
            </SoftBox>
          </Grid>
          <Grid item xs={12} sm={6}>
            <SoftBox mb={2}>
              <SoftTypography
                component="label"
                variant="caption"
                fontWeight="bold"
                textTransform="capitalize"
              >
                {t("Type de magasin")}
              </SoftTypography>
              <SoftSelect
                placeholder={formData?.store_type?.value}
                value={formFields.store_type?.value}
                onChange={(value) => handleInputChange("store_type", value)}
                options={storeTypeList.map((store) => ({
                  value: t(store.value),
                  label: t(store.label),
                }))}
                errorLabel={formSubmitted && errors.store_type}
              />
            </SoftBox>
          </Grid>
          <Grid item xs={12} sm={6}>
            <SoftTypography variant="caption" fontWeight="bold">
              {t("Matricule fiscale")}{" "}
            </SoftTypography>
            <SoftInput
              type="text"
              value={
                formFields.mat_fiscale?.toUpperCase() ||
                formData.mat_fiscale?.toUpperCase()
              }
              onChange={(e) =>
                handleInputChange("mat_fiscale", e.target.value.toUpperCase())
              }
              placeholder={t("Entrez votre matricule fiscale")}
              error={formSubmitted && errors.mat_fiscale}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <SoftTypography variant="caption" fontWeight="bold">
              {t("Régime")}
            </SoftTypography>
            <SoftBox display="flex" alignItems="center" mt={1}>
              <SoftTypography variant="caption" fontWeight="medium" mr={2}>
                {t("Réelle")}
              </SoftTypography>
              <Switch
                checked={!!formFields.regime_forfaitaire}
                onChange={(e) =>
                  handleInputChange("regime_forfaitaire", e.target.checked)
                }
                color="primary"
              />
              <SoftTypography variant="caption" fontWeight="medium" ml={2}>
                {t("Forfaitaire")}
              </SoftTypography>
            </SoftBox>
            {formSubmitted && formData.regime_forfaitaire === undefined && (
              <SoftTypography
                variant="caption"
                color="error"
                fontWeight="bold"
                mt={1}
              >
                {t("Ce champ est obligatoire")}
              </SoftTypography>
            )}
          </Grid>

          <Grid item xs={12} sm={6}>
            <SoftBox mb={2}>
              <SoftTypography
                component="label"
                variant="caption"
                fontWeight="bold"
                textTransform="capitalize"
              >
                {t("Première activité")}
              </SoftTypography>
              <SoftSelect
                placeholder={formData.first_activity}
                value={formFields.first_activity}
                onChange={(value) => handleInputChange("first_activity", value)}
                options={firstactivityList?.map((activity) => ({
                  value: t(activity.value),
                  label: t(activity.label),
                }))}
                placement="top"
                errorLabel={formSubmitted && errors.first_activity}
              />
            </SoftBox>
          </Grid>
          <Grid item xs={12} sm={6}>
            <SoftBox mb={2}>
              <SoftTypography
                component="label"
                variant="caption"
                fontWeight="bold"
                textTransform="capitalize"
              >
                {t("Deuxième activité")}
              </SoftTypography>
              <SoftSelect
                placeholder={formData.second_activity}
                value={formFields.second_activity}
                onChange={(value) =>
                  handleInputChange("second_activity", value)
                }
                options={secondactivityList?.map((activity) => ({
                  value: t(activity.value),
                  label: t(activity.label),
                }))}
                placement="top"
                errorLabel={formSubmitted && errors.second_activity}
              />
            </SoftBox>
          </Grid>
          <Grid item xs={12} sm={6}>
            <SoftTypography variant="caption" fontWeight="bold">
              {t("Mot de passe")}{" "}
            </SoftTypography>
            <SoftInput
              type="password"
              value={formFields.password}
              onChange={(e) => handleInputChange("password", e.target.value)}
              placeholder={t("Entrez votre mot de passe")}
              error={formSubmitted && errors.password}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <SoftTypography variant="caption" fontWeight="bold">
              {t("Confirmer le mot de passe")}
            </SoftTypography>
            <SoftInput
              type="password"
              value={formFields.password2}
              onChange={(e) => handleInputChange("password2", e.target.value)}
              placeholder={t("Confirmez votre mot de passe")}
              error={formSubmitted && errors.password2}
            />
          </Grid>
        </Grid>
      </SoftBox>
    </SoftBox>
  );
}

export default UserInfo;
