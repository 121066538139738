export const storeTypeList = [
  {
    label: "Alimentation Générale",
    value: "Attar",
  },
  {
    label: "Fruits Secs",
    value: "Hamas",
  },
  {
    label: "Supérette",
    value: "Superette",
  },
  {
    label: "Vente de volaille et dérivés",
    value: "Mazr3a",
  },
  {
    label: "Épicerie",
    value: "Epicerie",
  },
  {
    label: "Pâtisserie",
    value: "Patisserie",
  },
  {
    label: "Boulangerie",
    value: "BOULANGERIE",
  },
  {
    label: "Restaurant",
    value: "RESTAURANT",
  },
  {
    label: "Entreprise",
    value: "ENTREPRISE",
  },
  {
    label: "Vente de légumes et fruits",
    value: "kHADHAR",
  },
  {
    label: "Parfumerie",
    value: "PARFUMERIE",
  },
];

export const firstactivityList = [
  {
    label: "Alimentation Générale",
    value: "Alimentation generale",
  },
  {
    label: "Fruits Secs",
    value: "Fruit secs",
  },
  {
    label: "Supérette",
    value: "Superette",
  },
  {
    label: "Épices et salaisons",
    value: "Epices et salaisons",
  },
  {
    label: "Vente de volaille et dérivés",
    value: "Epices et salaisons",
  },
  {
    label: "Pâtisserie",
    value: "Pâtisserie",
  },
  {
    label: "Boulangerie",
    value: "Boulangerie",
  },
  {
    label: "Restaurant",
    value: "Restaurant",
  },
  {
    label: "Autre: Entreprise",
    value: "Autre: Corporte",
  },
  {
    label: "Vente de légumes et fruits",
    value: "Vente legumes et fruits",
  },
  {
    label: "Vente de produits de parfumerie",
    value: "Ventes produits de parfumerie",
  },
  {
    label: "Autres",
    value: "Autres",
  },
];

export const secondactivityList = [
  {
    label: "...",
    value: "...",
  },
  {
    label: "Alimentation Générale",
    value: "Alimentation Générale",
  },
  {
    label: "Fruits Secs",
    value: "Fruit secs",
  },
  {
    label: "Supérette",
    value: "Superette",
  },
  {
    label: "Épices et salaisons",
    value: "Epices et salaisons",
  },
  {
    label: "Vente de volaille et dérivés",
    value: "Vente vollailles et dérivés",
  },
  {
    label: "Pâtisserie",
    value: "Pâtisserie",
  },
  {
    label: "Boulangerie",
    value: "Boulangerie",
  },
  {
    label: "Restaurant",
    value: "Restaurant",
  },
  {
    label: "Autre: Entreprise",
    value: "Autre corporate",
  },
  {
    label: "Vente de légumes et fruits",
    value: "Vente legumes et fruits",
  },
  {
    label: "Vente de produits de parfumerie",
    value: "Ventes produits de parfumerie",
  },
  {
    label: "Autres",
    value: "Autres",
  },
  {
    label: "Commerce d'épices",
    value: "CCE d'epices",
  },
];
