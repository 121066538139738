import React, { useState, useEffect } from "react";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import ProductsApi from "api/products";
import { useNavigate } from "react-router-dom";
import CircularProgress from "@mui/material/CircularProgress";
import SoftTypography from "components/SoftTypography";
import FormField from "./components/FormField";
import SoftBox from "components/SoftBox";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import SoftButton from "components/SoftButton";
import SoftSelect from "components/SoftSelect";
import StockApi from "api/stock";
import Footer from "examples/Footer";
import { useTranslation } from "react-i18next";

function NewStock() {
  const navigate = useNavigate();
  const { t } = useTranslation();

  // State to manage stock information and form validation
  const [stockInfo, setStockInfo] = useState({
    product_id: "",
    quantity: null,
  });
  const [loading, setLoading] = useState(false); // Loading state for product fetching
  const [loadingSave, setLoadingSave] = useState(false); // Loading state for saving stock
  const [products, setProducts] = useState([]); // List of products for selection
  const [errors, setErrors] = useState({}); // Validation errors

  // Fetch products when the component mounts
  useEffect(() => {
    const token = localStorage.getItem("token");
    if (!token) {
      console.error("No token found in localStorage.");
      return;
    }

    setLoading(true);
    ProductsApi.GetAllProducts(token)
      .then((response) => {
        setProducts(response.data);
      })
      .catch((error) => {
        console.error("Error fetching products:", error);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  // Validate form inputs
  const validate = () => {
    let valid = true;
    const newErrors = {};
    if (!stockInfo.product_id) {
      newErrors.product_id = `${t("Le produit est requis !")}`;
      valid = false;
    }

    if (!stockInfo.quantity) {
      newErrors.quantity = `${t("La quantité est requise !")}`;
      valid = false;
    }
    setErrors(newErrors);
    return valid;
  };

  // Handle changes to stock info and clear errors
  const handleStockInfoChange = (newInfo) => {
    setErrors({});
    setStockInfo({
      ...stockInfo,
      ...newInfo,
    });
    if (errors[newInfo.name]) {
      setErrors({
        ...errors,
        [newInfo.name]: "",
      });
    }
  };

  // Save stock information
  const handleSave = () => {
    const token = localStorage.getItem("token");
    if (!token) {
      console.error("No token found in localStorage.");
      return;
    }

    if (!validate()) {
      return;
    }

    setLoadingSave(true);

    const payload = {
      initial_stock_items: [
        {
          product_id: stockInfo.product_id.value,
          quantity: Number(stockInfo.quantity),
        },
      ],
    };

    StockApi.SetInitialStock(payload, token)
      .then((response) => {
        console.log("Stock créé avec succès:", response.data);
        navigate("/gestion-de-stock/situation-de-stock");
      })
      .catch((error) => {
        console.error(error.response?.data?.msg || error.message);
      })
      .finally(() => {
        setLoadingSave(false);
      });
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <SoftBox
        display="flex"
        flexDirection="column"
        justifyContent="space-between"
        minHeight="85vh"
      >
        <SoftBox py={3} mt={10}>
          <Grid container justifyContent="center" sx={{ height: "100%" }}>
            <Grid item xs={12} sm={10} md={8} lg={6}>
              <Card elevation={3}>
                <CardContent>
                  <SoftTypography variant="h5" gutterBottom>
                    {t("Informations sur le stock")}
                  </SoftTypography>
                  <SoftBox mt={3}>
                    <Grid container>
                      <Grid item xs={12}>
                        <SoftBox mb={2}>
                          <SoftTypography
                            component="label"
                            variant="caption"
                            fontWeight="bold"
                            textTransform="capitalize"
                          >
                            {t("Produit")}
                          </SoftTypography>
                          <SoftSelect
                            label={t("Produit")}
                            placeholder={t("Sélectionner un produit")}
                            value={stockInfo.product_id}
                            onChange={(newValue) =>
                              handleStockInfoChange({
                                product_id: newValue,
                              })
                            }
                            options={
                              loading
                                ? [
                                    {
                                      value: "",
                                      label: (
                                        <SoftBox
                                          display="flex"
                                          justifyContent="center"
                                          alignItems="center"
                                          minWidth="100%"
                                          minHeight="48px"
                                        >
                                          <CircularProgress
                                            size={20}
                                            sx={{
                                              color: "#1F51FF",
                                              marginLeft: 7,
                                            }}
                                          />
                                        </SoftBox>
                                      ),
                                    },
                                  ]
                                : products.length > 0
                                ? products.map((product) => ({
                                    value: product.id,
                                    label: product.product_name,
                                  }))
                                : [
                                    {
                                      value: "",
                                      label: `${t("Sélectionner un produit")}`,
                                    },
                                  ]
                            }
                            errorLabel={errors.product_id}
                          />
                        </SoftBox>
                      </Grid>
                      <Grid item xs={12}>
                        <FormField
                          type="number"
                          label={t("Valeur de stock")}
                          placeholder={t("Valeur de stock")}
                          value={stockInfo.quantity}
                          onChange={(e) => {
                            const value = Math.max(1, e.target.value); 
                            handleStockInfoChange({
                              quantity: value,
                            });
                          }}
                          error={errors.quantity}
                        />
                      </Grid>
                    </Grid>
                  </SoftBox>
                  <SoftBox mt={2} display="flex" justifyContent="flex-end">
                    <SoftButton
                      disabled={loadingSave}
                      type="submit"
                      variant="gradient"
                      color="dark"
                      onClick={handleSave}
                    >
                      {loadingSave ? (
                        <CircularProgress size={24} color="inherit" />
                      ) : (
                        `${t("Enregistrer")}`
                      )}
                    </SoftButton>
                  </SoftBox>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </SoftBox>
        <Footer />
      </SoftBox>
    </DashboardLayout>
  );
}

export default NewStock;
