import React, { useState } from "react";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import { useNavigate } from "react-router-dom";
import FormField from "layouts/pages/account/components/FormField";
import SoftButton from "components/SoftButton";
import { useTranslation } from "react-i18next";

function EditBank() {
  const { t } = useTranslation();

  const [bank, setBank] = useState({
    name: "",
    numAccount: "",
    createdAt: "",
    address: "",
  });

  const navigate = useNavigate();

  const handleChange = (e) => {
    setBank({ ...bank, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    navigate("/gestion-financiere/liste-des-banques");
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <SoftBox
        display="flex"
        flexDirection="column"
        justifyContent="space-between"
        minHeight="85vh"
      >
        <SoftBox py={2}>
          <Grid container justifyContent="center">
            <Grid item xs={12} md={6}>
              <Card>
                <CardContent>
                  <SoftTypography variant="h5" gutterBottom pb={5} pt={2}>
                    {t("Modifier la banque")}
                  </SoftTypography>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <FormField
                        fullWidth
                        label={t("Nom")}
                        name="name"
                        value={bank.name}
                        onChange={handleChange}
                        required
                      />
                    </Grid>

                    <Grid item xs={12}>
                      <FormField
                        fullWidth
                        label={t("Numéro de compte")}
                        name="numAccount"
                        value={bank.numAccount}
                        onChange={handleChange}
                        required
                      />
                    </Grid>

                    <Grid item xs={12}>
                      <FormField
                        fullWidth
                        label={t("Adresse")}
                        name="address"
                        value={bank.address}
                        onChange={handleChange}
                        required
                      />
                    </Grid>

                    <Grid item xs={12}>
                      <FormField
                        fullWidth
                        label={t("Date de création")}
                        name="createdAt"
                        type="date"
                        value={bank.createdAt}
                        onChange={handleChange}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        required
                      />
                    </Grid>

                    <Grid item xs={12} display="flex" justifyContent="flex-end">
                      <SoftButton
                        type="submit"
                        variant="contained"
                        color="info"
                        onClick={handleSubmit}
                      >
                        {t("Modifier la banque")}
                      </SoftButton>
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </SoftBox>
        <Footer />
      </SoftBox>
    </DashboardLayout>
  );
}

export default EditBank;
