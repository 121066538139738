import axios from "./index";

class TransferApi {
  static GetTransferRequestsList = (token) => {
    return axios.get(`${base}/transfer_request/`, {
      headers: { Authorization: `Bearer ${token}` },
    });
  };
  static CreateTransferRequest = (token, payload) => {
    return axios.post(`${base}/transfer_request/`, payload, {
      headers: { Authorization: `Bearer ${token}` },
    });
  };
}

// Base URL for the authentication API
let base = "accounts";

export default TransferApi;
