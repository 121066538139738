import PropTypes from "prop-types";
import Card from "@mui/material/Card";
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import DataTable from "examples/Tables/DataTable";

function DynamicDataTable({ title, columns, rows }) {
  return (
    <Card>
      <SoftBox pt={3} px={3}>
        <SoftTypography variant="h6" fontWeight="medium">
          {title}
        </SoftTypography>
      </SoftBox>
      <SoftBox py={1}>
        <DataTable
          table={{ columns, rows }}
          entriesPerPage={false}
          showTotalEntries={false}
          isSorted={false}
          noEndBorder
        />
      </SoftBox>
    </Card>
  );
}

DynamicDataTable.propTypes = {
  title: PropTypes.string.isRequired,
  columns: PropTypes.arrayOf(PropTypes.object).isRequired,
  rows: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default DynamicDataTable;
