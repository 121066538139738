import React from "react";
import { Box, Typography, Link } from "@mui/material";
import Footer from "examples/Footer";
import SoftBox from "components/SoftBox";

const Privacy = () => {
  return (
    <SoftBox p={5}>
      <SoftBox
        sx={{
          maxWidth: "90%",
          width: "100%",
          margin: "auto",
        }}
      >
        <Box
          sx={{
            width: { lg: "80%", xl: "75%" },
            mx: "auto",
            backgroundColor: "white",
            boxShadow: 3,
            borderRadius: 2,
          }}
        >
          <Box sx={{ px: 4 }}>
            <Typography
              variant="h3"
              sx={{
                py: 3,
                fontWeight: "bold",
                textAlign: "center",
                color: "blue",
                mt: 3,
                mb: 1,
              }}
            >
              Privacy Policy
            </Typography>

            <section style={{ marginBottom: "2rem" }}>
              <Typography
                variant="h5"
                sx={{
                  fontWeight: "bold",
                  color: "blue",
                  mt: 3,
                  mb: 1,
                }}
              >
                1. Information We Collect
              </Typography>
              <Typography sx={{ color: "text.secondary", mb: 2 }}>
                We may collect the following types of information:
              </Typography>
              <Box component="ul" sx={{ pl: 4, listStyleType: "disc" }}>
                <li>
                  <Typography sx={{ color: "text.secondary", mb: 2 }}>
                    <strong>Personal Information:</strong>
                  </Typography>
                  <Box component="ul" sx={{ pl: 4, listStyleType: "disc" }}>
                    <li>
                      <Typography sx={{ color: "text.secondary", mb: 1 }}>
                        <strong>Account Information:</strong> When you create an
                        account, we may collect your name, email address, and
                        other contact information.
                      </Typography>
                    </li>
                    <li>
                      <Typography sx={{ color: "text.secondary", mb: 1 }}>
                        <strong>Usage Data:</strong> We collect information
                        about how you use the App, including interactions,
                        device information, and log data.
                      </Typography>
                    </li>
                  </Box>
                </li>
                <li>
                  <Typography sx={{ color: "text.secondary", mb: 2 }}>
                    <strong>Non-Personal Information:</strong>
                  </Typography>
                  <Box component="ul" sx={{ pl: 4, listStyleType: "disc" }}>
                    <li>
                      <Typography sx={{ color: "text.secondary", mb: 1 }}>
                        <strong>Analytics Data:</strong> We may use third-party
                        analytics tools to collect non-personal data such as app
                        usage patterns and crash reports.
                      </Typography>
                    </li>
                  </Box>
                </li>
              </Box>
            </section>

            <section style={{ marginBottom: "2rem" }}>
              <Typography
                variant="h5"
                sx={{
                  fontWeight: "bold",
                  color: "blue",
                  mt: 3,
                  mb: 1,
                }}
              >
                2. How We Use Your Information
              </Typography>
              <Typography sx={{ color: "text.secondary", mb: 2 }}>
                We may use the information we collect for the following
                purposes:
              </Typography>
              <Box component="ul" sx={{ pl: 4, listStyleType: "disc" }}>
                <li>
                  <Typography sx={{ color: "text.secondary", mb: 1 }}>
                    To provide and infotain the App.
                  </Typography>
                </li>
                <li>
                  <Typography sx={{ color: "text.secondary", mb: 1 }}>
                    To improve the App, including adding new features and
                    functionalities.
                  </Typography>
                </li>
                <li>
                  <Typography sx={{ color: "text.secondary", mb: 1 }}>
                    To communicate with you, including responding to your
                    inquiries and sending updates.
                  </Typography>
                </li>
                <li>
                  <Typography sx={{ color: "text.secondary", mb: 1 }}>
                    To analyze usage and trends to better understand our users.
                  </Typography>
                </li>
              </Box>
            </section>

            <section style={{ marginBottom: "2rem" }}>
              <Typography
                variant="h5"
                sx={{
                  fontWeight: "bold",
                  color: "blue",
                  mt: 3,
                  mb: 1,
                }}
              >
                3. Sharing Your Information
              </Typography>
              <Typography sx={{ color: "text.secondary", mb: 2 }}>
                We do not share your personal data with third parties except in
                the following cases:
              </Typography>
              <Box component="ul" sx={{ pl: 4, listStyleType: "disc" }}>
                <li>
                  <Typography sx={{ color: "text.secondary", mb: 1 }}>
                    <strong>Service Providers:</strong> We may share data with
                    third-party service providers who assist us in operating the
                    App or conducting our business, as long as those parties
                    agree to keep this information confidential.
                  </Typography>
                </li>
                <li>
                  <Typography sx={{ color: "text.secondary", mb: 1 }}>
                    <strong>Legal Compliance:</strong> We may disclose your
                    information to comply with applicable laws, regulations, or
                    legal processes.
                  </Typography>
                </li>
              </Box>
            </section>

            <section style={{ marginBottom: "2rem" }}>
              <Typography
                variant="h5"
                sx={{
                  fontWeight: "bold",
                  color: "blue",
                  mt: 3,
                  mb: 1,
                }}
              >
                4. Data Security
              </Typography>
              <Typography sx={{ color: "text.secondary", mb: 2 }}>
                We are committed to protecting your data. We use
                industry-standard security measures to safeguard your
                information, but please note that no method of transmission over
                the internet or electronic storage is 100% secure.
              </Typography>
            </section>

            <section style={{ marginBottom: "2rem" }}>
              <Typography
                variant="h5"
                sx={{
                  fontWeight: "bold",
                  color: "blue",
                  mt: 3,
                  mb: 1,
                }}
              >
                5. Data Retention
              </Typography>
              <Typography sx={{ color: "text.secondary", mb: 2 }}>
                We will retain your personal data only as long as necessary to
                fulfill the purposes for which it was collected or as required
                by law.
              </Typography>
            </section>

            <section style={{ marginBottom: "2rem" }}>
              <Typography
                variant="h5"
                sx={{
                  fontWeight: "bold",
                  color: "blue",
                  mt: 3,
                  mb: 1,
                }}
              >
                6. Your Rights
              </Typography>
              <Typography sx={{ color: "text.secondary", mb: 2 }}>
                You have the right to access, update, or delete your personal
                data. You can do this by contacting us at the email address
                provided below.
              </Typography>
            </section>

            <section style={{ marginBottom: "2rem" }}>
              <Typography
                variant="h5"
                sx={{
                  fontWeight: "bold",
                  color: "blue",
                  mt: 3,
                  mb: 1,
                }}
              >
                7. Changes to This Privacy Policy
              </Typography>
              <Typography sx={{ color: "text.secondary", mb: 2 }}>
                We may update this Privacy Policy from time to time. We will
                notify you of any changes by posting the new Privacy Policy on
                this page.
              </Typography>
            </section>

            <section style={{ paddingBottom: "0.5rem" }}>
              <Typography
                variant="h5"
                sx={{
                  fontWeight: "bold",
                  color: "blue",
                  mt: 3,
                  mb: 1,
                }}
              >
                8. Contact Us
              </Typography>
              <Typography sx={{ color: "text.secondary", mb: 2 }}>
                If you have any questions or concerns about this Privacy Policy,
                please contact us at:
              </Typography>
              <address>
                <Typography
                  sx={{ color: "text.secondary", fontStyle: "normal", mb: 2 }}
                >
                  <strong>Livrili Technologies</strong>
                  <br />
                  Email:{" "}
                  <Link
                    href="mailto:rejeb.chaalia@gmail.com"
                    underline="hover"
                    sx={{ color: "text.secondary" }}
                  >
                    rejeb.chaalia@gmail.com
                  </Link>
                  <br />
                  Address: Street Belgacem Bazmi, Gabes, Tunisia
                </Typography>
              </address>
            </section>
          </Box>
        </Box>
      </SoftBox>
      <Box
        sx={{
          display: "flex",
          width: "100%",
          justifyContent: "flex-end", 
          pt: 4, 
          px: 35
        }}
      >
        <Footer />
      </Box>
    </SoftBox>
  );
};

export default Privacy;
