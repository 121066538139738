import React, { useState, useEffect } from "react";
import { Card, Grid, Typography, Box, IconButton } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import SoftBox from "components/SoftBox";
import Footer from "examples/Footer";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import SoftButton from "components/SoftButton";
import SoftTypography from "components/SoftTypography";
import SoftInput from "components/SoftInput";
import MultiSelect from "components/MultiSelect";
import { useNavigate } from "react-router-dom";
import WarehouseApi from "api/warehouse";
import { useTranslation } from "react-i18next";

/**
 * `NewWarehouse` component provides a form for creating new warehouses.
 * Users can add multiple warehouses, each with a name, city, address, and managers.
 * It also includes functionality for adding and removing warehouses from the form.
 */
function NewWarehouse() {
  const { t } = useTranslation();

  const navigate = useNavigate(); // Hook for navigation
  // State for holding the list of warehouses to be created
  const [warehouses, setWarehouses] = useState([
    { name: "", city: "", address: "", managers: [] },
  ]);
  // State for holding the list of warehouse managers
  const [warehousesList, setWarehousesList] = useState([]);
  // Token for API authentication
  const token = localStorage.getItem("token");

  /**
   * Effect hook to fetch the list of warehouse managers on component mount.
   * Updates the state with the list of managers.
   */
  useEffect(() => {
    const fetchWarehouseManagers = async () => {
      try {
        const response = await WarehouseApi.GetWarehouseManagersList(token);
        const managersList = response.data.results.map((manager) => ({
          label: manager.username,
          value: manager.id,
        }));
        setWarehousesList(managersList);
      } catch (error) {
        console.error("Failed to fetch warehouse managers:", error);
      }
    };

    fetchWarehouseManagers();
  }, [token]);

  /**
   * Handles changes to the warehouse input fields.
   * @param {number} index - The index of the warehouse being modified
   * @param {string} name - The name of the input field
   * @param {string} value - The new value of the input field
   */
  const handleWarehouseChange = (index, name, value) => {
    const updatedWarehouses = [...warehouses];
    updatedWarehouses[index][name] = value;
    setWarehouses(updatedWarehouses);
  };

  /**
   * Adds a new warehouse to the form.
   */
  const handleAddWarehouse = () => {
    setWarehouses([
      ...warehouses,
      { name: "", city: "", address: "", managers: [] },
    ]);
  };

  /**
   * Removes a warehouse from the form based on its index.
   * @param {number} index - The index of the warehouse to be removed
   */
  const handleRemoveWarehouse = (index) => {
    const updatedWarehouses = warehouses.filter((_, i) => i !== index);
    setWarehouses(updatedWarehouses);
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <SoftBox
        display="flex"
        flexDirection="column"
        justifyContent="space-between"
        minHeight="85vh"
      >
        <SoftBox py={3} sx={{ px: { lg: 14 } }}>
          <Card>
            <SoftBox py={3} px={5}>
              <Box>
                <Typography variant="h6" fontWeight="bold" mb={2}>
                  {t("Créer des entrepôts")}
                </Typography>

                {/* Render input fields for each warehouse */}
                {warehouses.map((warehouse, index) => (
                  <Grid container spacing={3} key={index} mb={2}>
                    <Grid item xs={12} sm={2.5}>
                      <SoftTypography variant="h6">{t("Nom")}</SoftTypography>
                      <SoftInput
                        style={{ marginBottom: "1rem" }}
                        name="name"
                        value={warehouse.name}
                        onChange={(e) =>
                          handleWarehouseChange(
                            index,
                            e.target.name,
                            e.target.value
                          )
                        }
                      />
                    </Grid>
                    <Grid item xs={12} sm={2.5}>
                      <SoftTypography variant="h6">{t("Ville")}</SoftTypography>
                      <SoftInput
                        style={{ marginBottom: "1rem" }}
                        name="city"
                        value={warehouse.city}
                        onChange={(e) =>
                          handleWarehouseChange(
                            index,
                            e.target.name,
                            e.target.value
                          )
                        }
                      />
                    </Grid>
                    <Grid item xs={12} sm={3}>
                      <SoftTypography variant="h6">
                        {t("Adresse")}
                      </SoftTypography>
                      <SoftInput
                        style={{ marginBottom: "1rem" }}
                        name="address"
                        value={warehouse.address}
                        onChange={(e) =>
                          handleWarehouseChange(
                            index,
                            e.target.name,
                            e.target.value
                          )
                        }
                      />
                    </Grid>
                    <Grid item xs={10.5} sm={3}>
                      <SoftTypography variant="h6">
                        {t("Gestionnaire")}
                      </SoftTypography>
                      <MultiSelect
                        placeholder={t("Sélectionner un gestionnaire")}
                        options={warehousesList}
                        value={warehouse.managers}
                        onChange={(selectedOptions) =>
                          handleWarehouseChange(
                            index,
                            "managers",
                            selectedOptions
                          )
                        }
                      />
                      <SoftTypography
                        variant="caption"
                        color="info"
                        fontWeight="bold"
                        sx={{ cursor: "pointer" }}
                        onClick={() =>
                          navigate("/gestion-des-ventes/nouveau-client")
                        }
                      >
                        + {t("Ajouter un gestionnaire")}
                      </SoftTypography>
                    </Grid>

                    <Grid item xs={1}>
                      <IconButton
                        color="error"
                        onClick={() => handleRemoveWarehouse(index)}
                        sx={{ mt: 3 }}
                      >
                        <DeleteIcon />
                      </IconButton>
                    </Grid>
                  </Grid>
                ))}
                <Box
                  display="flex"
                  sx={{
                    pt: { xs: 5 },
                    mt: { md: 2 },
                  }}
                >
                  <SoftButton
                    variant="outlined"
                    color="info"
                    onClick={handleAddWarehouse}
                  >
                    <AddIcon /> {t("Ajouter un entrepôt")}
                  </SoftButton>
                </Box>
              </Box>
              <Box display="flex" justifyContent="flex-end">
                <SoftButton variant="contained" color="info" type="submit">
                  {t("Enregistrer les modifications")}
                </SoftButton>
              </Box>
            </SoftBox>
          </Card>
        </SoftBox>
        <Footer />
      </SoftBox>
    </DashboardLayout>
  );
}

export default NewWarehouse;
