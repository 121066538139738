export const handleUpload = async (image) => {
  try {
    // Create a new FormData object to hold the image file and metadata
    const data = new FormData();
    data.append("file", image); // Append the image file to the FormData object
    data.append("upload_preset", "products"); // Append the upload preset for Cloudinary
    data.append("cloud_name", "dcrzf4pw1"); // Append the Cloudinary cloud name

    // Send a POST request to Cloudinary's image upload endpoint
    const response = await fetch(
      "https://api.cloudinary.com/v1_1/dcrzf4pw1/image/upload",
      {
        method: "post", // HTTP method for the request
        body: data, // The FormData object as the request body
      }
    );

    // Parse the response as JSON
    const responseData = await response.json();

    // Return the URL of the uploaded image
    return responseData.url;
  } catch (error) {
    // Log any errors that occur during the upload process
    console.log("Error uploading image:", error);
  }
};
