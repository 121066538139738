// src/i18n.js
import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import en from "../locales/en.json";
import fr from "../locales/fr.json";
import ar from "../locales/ar.json";

// Get the language from localStorage or default to 'fr'
const language = localStorage.getItem("language") || "fr";

i18n.use(initReactI18next).init({
  resources: {
    en: { translation: en },
    fr: { translation: fr },
    ar: { translation: ar },
  },
  lng: language, // Use language from localStorage or default
  fallbackLng: "fr", // Fallback language if translation is not available
  interpolation: {
    escapeValue: false, // React already safes from xss
  },
});

export default i18n;
