import Axios from "axios";
import { API_SERVER } from "../config/constant";

// Create an Axios instance with a base URL and default headers
const axios = Axios.create({
  baseURL: `${API_SERVER}`, // Base URL for API requests, retrieved from config
  headers: { "Content-Type": "application/json" }, // Default content type for all requests
});

// Interceptor for handling requests before they are sent
axios.interceptors.request.use(
  (config) => {
    // Modify or log the request configuration if needed
    return Promise.resolve(config); // Resolve with the modified configuration
  },
  (error) => Promise.reject(error) // Handle request errors
);

// Interceptor for handling responses after they are received
axios.interceptors.response.use(
  (response) => Promise.resolve(response), // Resolve the response if successful
  (error) => {
    return Promise.reject(error); // Handle response errors
  }
);

export default axios;
