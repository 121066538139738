// Import necessary components from Chart.js
import {
  Chart,
  LinearScale,
  CategoryScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";

// Import other necessary dependencies and components
import Grid from "@mui/material/Grid";
import SoftBox from "components/SoftBox";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import AnimatedStatisticsCard from "examples/Cards/StatisticsCards/AnimatedStatisticsCard";
import MiniStatisticsCard from "examples/Cards/StatisticsCards/MiniStatisticsCard";
import AnnouncementCard from "examples/Cards/AnnouncementCard";
import ProgressLineChart from "examples/Charts/LineCharts/ProgressLineChart";
import ProgressDoughnutChart from "examples/Charts/DoughnutCharts/ProgressDoughnutChart";
import TodoList from "layouts/pages/projects/general/components/TodoList";
import progressLineChartData from "layouts/pages/projects/general/data/progressLineChartData";
import progressDoughnutChartData from "layouts/pages/projects/general/data/progressDoughnutChartData";
import team3 from "assets/images/team-3.jpg";

// Register the components with Chart.js
Chart.register(
  LinearScale,
  CategoryScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

function General() {
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <SoftBox mt={3}>
        <Grid container spacing={3}>
          <Grid item xs={12} lg={8}>
            <Grid container spacing={3}>
              <Grid item xs={12} lg={4}>
                <AnimatedStatisticsCard
                  title="earnings"
                  count="$15,800"
                  percentage={{
                    color: "dark",
                    label: "+15% since last week",
                  }}
                  action={{
                    type: "internal",
                    route: "/pages/projects/general",
                    label: "view more",
                  }}
                />
              </Grid>
              <Grid item xs={12} md={6} lg={4}>
                <SoftBox mb={3}>
                  <MiniStatisticsCard
                    title={{ fontWeight: "medium", text: "today's money" }}
                    count="$53,000"
                    icon={{ color: "dark", component: "local_atm" }}
                    direction="left"
                  />
                </SoftBox>
                <MiniStatisticsCard
                  title={{ fontWeight: "medium", text: "sessions" }}
                  count="9,600"
                  percentage={{ color: "success", text: "+55%" }}
                  icon={{ color: "dark", component: "emoji_events" }}
                  direction="left"
                />
              </Grid>
              <Grid item xs={12} md={6} lg={4}>
                <SoftBox mb={3}>
                  <MiniStatisticsCard
                    title={{ fontWeight: "medium", text: "today's users" }}
                    count="2,300"
                    percentage={{ color: "success", text: "+3%" }}
                    icon={{ color: "dark", component: "public" }}
                    direction="left"
                  />
                </SoftBox>
                <MiniStatisticsCard
                  title={{ fontWeight: "medium", text: "Sign-Ups" }}
                  count="348"
                  percentage={{ color: "success", text: "+12%" }}
                  icon={{ color: "dark", component: "storefront" }}
                  direction="left"
                />
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <SoftBox my={3}>
                <TodoList />
              </SoftBox>
            </Grid>
          </Grid>
          <Grid item xs={12} lg={4}>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <AnnouncementCard
                  by={{ image: team3, name: "lucas prila", date: "2h ago" }}
                  badge={{ color: "info", label: "recommendation" }}
                  title="I need a Ruby developer for my new website."
                  description="The website was initially built in PHP, I need a professional ruby programmer to shift it."
                  value={{ type: "$", amount: "3,000", method: "month" }}
                  action={{
                    type: "internal",
                    route: "/pages/projects/general",
                    label: "apply",
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <ProgressLineChart
                  icon="date_range"
                  title="Tasks"
                  count={480}
                  progress={60}
                  chart={progressLineChartData}
                />
              </Grid>
              <Grid item xs={12}>
                <ProgressDoughnutChart
                  icon="workspace_premium"
                  title="projects"
                  count={115}
                  chart={progressDoughnutChartData}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </SoftBox>
      <Footer />
    </DashboardLayout>
  );
}

export default General;
