import React, { useState, useEffect } from "react";
import Grid from "@mui/material/Grid";
import SoftBox from "components/SoftBox";
import SoftButton from "components/SoftButton";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import CircularProgress from "@mui/material/CircularProgress";
import CustomersApi from "api/customers";
import Media from "./components/Media";
import Address from "./components/Address";
import { useParams, useNavigate } from "react-router-dom";
import UserInfo from "./components/UserInfo";
import { useTranslation } from "react-i18next";

function EditUser() {
  const { userId } = useParams();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const [userInfo, setUserInfo] = useState({});
  const [mediaInfo, setMediaInfo] = useState({});
  const [addressInfo, setAddressInfo] = useState({});

  const [loadingUser, setLoadingUser] = useState(false);
  const [loading, setLoading] = useState(false);

  const [formValid, setFormValid] = useState(false);
  const [formSubmitted, setFormSubmitted] = useState(false);

  useEffect(() => {
    const fetchUserDetails = async () => {
      try {
        setLoadingUser(true);
        const token = localStorage.getItem("token");
        if (!token) {
          throw new Error("No token found in localStorage.");
        }

        if (userId) {
          const response = await CustomersApi.GetCustomerById(userId, token);
          const data = response.data;

          setUserInfo({
            first_activity: data.first_activity,
            second_activity: data.second_activity,
            store_type: { value: data.store_type },
            firstname: data.firstname,
            lastname: data.lastname,
            username: data.username,
            phone: data.phone,
            mat_fiscale: data.mat_fiscale,
            password: data.password,
            password2: data.password2,
            user_type: data.user_type,
            regime_forfaitaire: data.regime_forfaitaire
          });
          setMediaInfo({
            id_card_image: data.id_card_image,
            patent_image: data.patent_image,
          });
          setAddressInfo({
            address_details: data.address_details,
            latitude: data.latitude,
            longitude: data.longitude,
          });
        }
      } catch (error) {
        console.error("Error fetching user data:", error);
      } finally {
        setLoadingUser(false);
      }
    };

    fetchUserDetails();
  }, [userId]);

  const handleSave = async () => {
    setFormSubmitted(true);
    if (!formValid) {
      return;
    }
    try {
      setLoading(true);
      const token = localStorage.getItem("token");
      if (!token) {
        throw new Error("No token found in localStorage.");
      }

      const payload = Object.entries({
        address_detail: addressInfo?.address_details || "",
        business_role_id: userInfo?.user_type?.value || "",
        city: "",
        first_activity: userInfo?.first_activity || "",
        firstname: userInfo?.firstname || "",
        id_card_image: mediaInfo?.id_card_image || "",
        lastname: userInfo?.lastname || "",
        latitude: addressInfo?.latitude || "",
        longitude: addressInfo?.longitude || "",
        mat_fiscale: userInfo?.mat_fiscale || "",
        password: userInfo?.password || "",
        password2: userInfo?.password2 || "",
        patent_image: mediaInfo?.patent_image || "",
        phone: userInfo?.phone || "",
        second_activity: userInfo?.second_activity.value || "",
        store_type: userInfo?.store_type.value || "",
        username: userInfo?.username || "",
        regime_forfaitaire: userInfo?.regime_forfaitaire || false,
      }).reduce((acc, [key, value]) => {
        if (value !== "") {
          acc[key] = value;
        }
        return acc;
      }, {});

      await CustomersApi.UpdateCustomer(userId, payload, token);
      navigate("/gestion-des-ventes/liste-des-clients");
    } catch (error) {
      console.error(error.response?.data?.msg || error.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      {loadingUser ? (
        <SoftBox
          display="flex"
          justifyContent="center"
          alignItems="center"
          p={35}
        >
          <CircularProgress sx={{ color: "#1F51FF" }} />
        </SoftBox>
      ) : (
        <SoftBox my={3}>
          <SoftBox mb={3}>
            <Grid container spacing={3} alignItems="center">
              <Grid item xs={12}>
                <SoftBox display="flex" justifyContent="flex-end">
                  <SoftButton
                    variant="gradient"
                    color="info"
                    onClick={handleSave}
                    disabled={loading}
                  >
                    {loading ? (
                      <CircularProgress size={24} color="inherit" />
                    ) : (
                      `${t("Enregistrer")}`
                    )}
                  </SoftButton>
                </SoftBox>
              </Grid>
            </Grid>
          </SoftBox>
          <Grid container columnSpacing={5}>
            <Grid item xs={12} lg={6}>
              <UserInfo
                formData={userInfo}
                onChange={(newInfo) => setUserInfo({ ...userInfo, ...newInfo })}
                validateFormCallback={setFormValid}
                formSubmitted={formSubmitted}
              />
            </Grid>
            <Grid item xs={12} lg={5}>
              <Address
                formData={{ values: addressInfo }}
                onChange={(newInfo) =>
                  setAddressInfo({ ...addressInfo, ...newInfo })
                }
              />
              <SoftBox mt={5}>
                <Media
                  onChange={(newInfo) =>
                    setMediaInfo({ ...mediaInfo, ...newInfo })
                  }
                  initialImages={mediaInfo}
                />
              </SoftBox>
            </Grid>
          </Grid>
        </SoftBox>
      )}
      <Footer />
    </DashboardLayout>
  );
}

export default EditUser;
