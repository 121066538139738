import React, { useEffect } from "react";
import { useNavigate, Outlet } from "react-router-dom";

export const ProtectedRoute = () => {
  const navigate = useNavigate();
  let token = localStorage.getItem("token");

  useEffect(() => {
    if (!token || token === "") {
      navigate("/authenticate/connexion");
    }
  });

  return <>{token || token !== "" ? <Outlet /> : null}</>;
};
