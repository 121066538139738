import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Card from "@mui/material/Card";
import CircularProgress from "@mui/material/CircularProgress";
import SoftBox from "components/SoftBox";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import SoftButton from "components/SoftButton";
import SoftTypography from "components/SoftTypography";
import FormField from "../ajouter-articles/components/FormField";
import SoftSelect from "components/SoftSelect";
import { Grid, Box, Typography, CardContent } from "@mui/material";
import WarehouseApi from "api/warehouse";
import TransferApi from "api/transfer";
import { useTranslation } from "react-i18next";

function StockRequest() {
  const navigate = useNavigate();
  const location = useLocation();
  const { selectedStock } = location.state || { selectedStock: [] };
  const { stockData } = location.state || { stockData: [] };
  const { t } = useTranslation();

  // Filter stock data to keep only items with quantity > 0
  const filteredStockData = stockData.filter((item) => item.quantity > 0);

  const [stock, setStock] = useState(selectedStock);
  const [loading, setLoading] = useState(true);
  const [warehouses, setWarehouses] = useState([]);
  const token = localStorage.getItem("token");
  const warehouseID = localStorage.getItem("warehouseID");

  useEffect(() => {
    const fetchData = async () => {
      try {
        const warehouseResponse = await WarehouseApi.GetWarehouseList(token);
        setWarehouses(warehouseResponse.data);
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [token]);

  const handleStockChange = (index, name, value) => {
    const updatedStock = [...stock];

    if (name === "warehouse_name") {
      const selectedWarehouse = warehouses.find(
        (warehouse) => warehouse.name === value
      );
      updatedStock[index].warehouse_name = selectedWarehouse
        ? selectedWarehouse.name
        : "";
      updatedStock[index].warehouse_id = selectedWarehouse
        ? selectedWarehouse.id
        : null;
    } else {
      updatedStock[index][name] = value;
    }

    setStock(updatedStock);
  };

  const handleAddRow = () => {
    setStock([
      ...stock,
      {
        product_id: "",
        warehouse_id: "",
        warehouse_name: "",
        quantity: 0,
      },
    ]);
  };

  const getWarehousesForProduct = (productId) => {
    const product = filteredStockData.find(
      (item) => item.product_id === productId
    );
    if (!product) return [];

    const warehousesData = product.warehouses;
    if (!warehousesData) return [];

    return Object.entries(warehousesData)
      .filter(([_, quantity]) => quantity > 0)
      .map(([warehouseName, quantity]) => ({
        value: warehouseName,
        label: `${warehouseName} (${t("Quantité")}: ${quantity})`,
      }));
  };

  const getQuantityForWarehouse = (productId, warehouseId) => {
    const product = filteredStockData.find(
      (item) => item.product_id === productId
    );

    if (product && product.warehouses && warehouseId in product.warehouses) {
      return product.warehouses[warehouseId];
    }

    return 0;
  };

  const handleSave = async () => {
    try {
      // Group products by warehouse_id
      const groupedPayloads = stock.reduce((acc, item) => {
        const product = filteredStockData.find(
          (prod) => prod.product_id === item.product_id
        );
        const productName = product ? product.product_name : "";

        // Check if the warehouse already exists in the accumulator
        if (!acc[item.warehouse_id]) {
          acc[item.warehouse_id] = {
            warehouse_requester: warehouseID,
            requested_prod_qts: {},
            warehouse_requested: item.warehouse_id,
          };
        }

        // Add the product and quantity in the tuple-like format ("prodname", quantity)
        acc[item.warehouse_id].requested_prod_qts[
          item.product_id
        ] = `(${productName}, ${Number(item.quantity)})`;
        return acc;
      }, {});

      // Convert the groupedPayloads object to an array
      const payloadsArray = Object.values(groupedPayloads);

      // Map over payloads and send requests
      const transferRequests = payloadsArray.map((payload) =>
        TransferApi.CreateTransferRequest(token, payload)
      );

      // Wait for all transfer requests to complete
      await Promise.all(transferRequests);

      // After all requests succeed, navigate to the stock requests page
      navigate("/gestion-de-stock/mes-demandes-de-stock");
    } catch (error) {
      console.error("Error creating transfer requests", error);
    }
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <SoftBox
        display="flex"
        flexDirection="column"
        justifyContent="space-between"
        minHeight="85vh"
      >
        <SoftBox py={3} px={14}>
          {loading ? (
            <SoftBox
              display="flex"
              justifyContent="center"
              alignItems="center"
              p={35}
            >
              <CircularProgress sx={{ color: "#1F51FF" }} />
            </SoftBox>
          ) : (
            <Card>
              <SoftBox py={3} px={5}>
                <Box>
                  <Typography variant="h6" fontWeight="bold" mb={2}>
                    {t("Détails de la demande de stock")}
                  </Typography>
                  <Card variant="outlined" sx={{ mb: 3 }}>
                    <CardContent>
                      {stock.map((item, index) => (
                        <Grid container spacing={3} key={index} sx={{ mb: 2 }}>
                          <Grid item xs={4}>
                            <SoftTypography
                              component="label"
                              variant="caption"
                              fontWeight="bold"
                              textTransform="capitalize"
                            >
                              {t("Produit")} {index + 1}
                            </SoftTypography>
                            <SoftSelect
                              label={`${t("Produit")} ${index + 1}`}
                              placeholder={t("Sélectionner un produit")}
                              value={
                                item.product_id
                                  ? {
                                      value: item.product_id,
                                      label: filteredStockData.find(
                                        (product) =>
                                          product.product_id === item.product_id
                                      )?.product_name,
                                    }
                                  : ""
                              }
                              onChange={(newValue) =>
                                handleStockChange(
                                  index,
                                  "product_id",
                                  newValue ? newValue.value : ""
                                )
                              }
                              options={
                                filteredStockData.length > 0
                                  ? filteredStockData.map((product) => ({
                                      value: product.product_id,
                                      label: product.product_name,
                                    }))
                                  : [
                                      {
                                        value: "",
                                        label: `${t("Aucun produit disponible")}`,
                                      },
                                    ]
                              }
                            />
                          </Grid>
                          <Grid item xs={4}>
                            <SoftTypography
                              component="label"
                              variant="caption"
                              fontWeight="bold"
                              textTransform="capitalize"
                            >
                              {t("Entrepôt")} {index + 1}
                            </SoftTypography>
                            <SoftSelect
                              label={`${t("Entrepôt")} ${index + 1}`}
                              placeholder={t("Sélectionner un entrepôt")}
                              value={
                                item.warehouse_name
                                  ? {
                                      value: item.warehouse_name,
                                      label:
                                        warehouses.find(
                                          (warehouse) =>
                                            warehouse.id === item.warehouse_id
                                        )?.name || "",
                                    }
                                  : null
                              }
                              onChange={(newValue) =>
                                handleStockChange(
                                  index,
                                  "warehouse_name",
                                  newValue ? newValue.value : ""
                                )
                              }
                              options={getWarehousesForProduct(item.product_id)}
                            />
                          </Grid>
                          <Grid item xs={4}>
                            <FormField
                              type="number"
                              label={`${t("Quantité")} ${index + 1}`}
                              placeholder={
                                item.warehouse_name
                                  ? `${t("Quantité restante en stock")}: ${getQuantityForWarehouse(
                                      item.product_id,
                                      item.warehouse_name
                                    )}`
                                  : `${t("Quantité")}`
                              }
                              value={item.quantity || ""}
                              onChange={(e) =>
                                handleStockChange(
                                  index,
                                  "quantity",
                                  e.target.value
                                )
                              }
                              inputProps={{ min: 0 }}
                            />
                          </Grid>
                        </Grid>
                      ))}
                      <SoftBox mt={3}>
                        <SoftButton
                          variant="outlined"
                          color="info"
                          onClick={handleAddRow}
                        >
                          {t("Ajouter un produit")}
                        </SoftButton>
                      </SoftBox>
                    </CardContent>
                  </Card>
                  <Box display="flex" justifyContent="flex-end">
                    <SoftButton
                      variant="contained"
                      color="info"
                      type="submit"
                      onClick={handleSave}
                    >
                      {t("Enregistrer les modifications")}
                    </SoftButton>
                  </Box>
                </Box>
              </SoftBox>
            </Card>
          )}
        </SoftBox>
        <Footer />
      </SoftBox>
    </DashboardLayout>
  );
}

export default StockRequest;
