function numberToWordsEnglish(num) {
  const ones = [
    "",
    "one",
    "two",
    "three",
    "four",
    "five",
    "six",
    "seven",
    "eight",
    "nine",
    "ten",
    "eleven",
    "twelve",
    "thirteen",
    "fourteen",
    "fifteen",
    "sixteen",
    "seventeen",
    "eighteen",
    "nineteen",
  ];

  const tens = [
    "",
    "",
    "twenty",
    "thirty",
    "forty",
    "fifty",
    "sixty",
    "seventy",
    "eighty",
    "ninety",
  ];

  const scales = ["", "thousand", "million", "billion", "trillion"];

  // Chunk the number into groups of three digits
  const chunkify = (n) => {
    let chunks = [];
    let numStr = String(n);
    while (numStr.length > 0) {
      chunks.unshift(numStr.slice(-3));
      numStr = numStr.slice(0, -3);
    }
    return chunks;
  };

  // Convert a three-digit chunk to words
  const convertChunk = (chunk) => {
    let number = parseInt(chunk, 10);
    if (number === 0) return "";

    let words = [];
    if (number >= 100) {
      let hundreds = Math.floor(number / 100);
      words.push(ones[hundreds] + " hundred");
      number = number % 100;
    }

    if (number >= 20) {
      let tensPart = Math.floor(number / 10);
      number = number % 10;
      words.push(tens[tensPart]);
      if (number > 0) {
        words.push(ones[number]);
      }
    } else if (number > 0) {
      words.push(ones[number]);
    }

    return words.join(" ");
  };

  let [integerPart, decimalPart] = String(num).split(".");

  let chunks = chunkify(integerPart);
  let words = [];

  // Handle each chunk of the integer part
  for (let i = 0; i < chunks.length; i++) {
    const chunkWords = convertChunk(chunks[i]);
    if (chunkWords) {
      let scale = scales[chunks.length - i - 1];
      words.push(chunkWords + (scale ? " " + scale : ""));
    }
  }

  if (words.length === 0) {
    words.push("zero");
  }

  words.push("dinars");

  // Handle decimal part (cents)
  if (decimalPart) {
    let decimalWords = convertChunk(decimalPart);
    words.push("and " + decimalWords + " millimes");
  }

  // Capitalize the first letter and return the sentence
  let result = words.join(" ").trim();
  return result.charAt(0).toUpperCase() + result.slice(1);
}

export default numberToWordsEnglish;
