// @mui material components
import Icon from "@mui/material/Icon";
import Tooltip from "@mui/material/Tooltip";
import { Link } from "react-router-dom";

// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button,
} from "@mui/material";
import { useState } from "react";
import CustomersApi from "api/customers";
import { useTranslation } from "react-i18next";

function ActionCell({ customerId, onDataUpdate }) {
  const [open, setOpen] = useState(false);
  const { t } = useTranslation();

  const handleDeleteClient = () => {
    const token = localStorage.getItem("token");
    if (!token) {
      console.error("No token found in localStorage.");
      return;
    }

    CustomersApi.DeleteCustomer(customerId, token)
      .then((response) => {
        setOpen(false);
        onDataUpdate();
      })
      .catch((error) => {
        console.error(error.response?.data?.msg || error.message);
        setOpen(false);
      });
  };

  return (
    <SoftBox display="flex" alignItems="center">
      {/* <SoftTypography
        variant="body1"
        color="secondary"
        sx={{ cursor: "pointer", lineHeight: 0 }}
      >
        <Tooltip title="Aperçu du client" placement="top">
          <Link to={`/pages/users/${userId}`}>
            <Icon>visibility</Icon>
          </Link>
        </Tooltip>
      </SoftTypography> */}
      <SoftBox mx={2}>
        <SoftTypography
          variant="body1"
          color="secondary"
          sx={{ cursor: "pointer", lineHeight: 0 }}
        >
          <Tooltip title={t("Modifier le client")} placement="top">
            <Link to={`/gestion-des-ventes/modifer-le-client/${customerId}`}>
              <Icon>edit</Icon>
            </Link>
          </Tooltip>
        </SoftTypography>
      </SoftBox>
      <SoftTypography
        variant="body1"
        color="secondary"
        sx={{ cursor: "pointer", lineHeight: 0 }}
        onClick={() => setOpen(true)}
      >
        <Tooltip title={t("Supprimer le client")} placement="left">
          <Icon>delete</Icon>
        </Tooltip>
      </SoftTypography>
      <Dialog
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="confirm-dialog-title"
        aria-describedby="confirm-dialog-description"
      >
        <DialogTitle id="confirm-dialog-title">
          {t("Supprimer le client")}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="confirm-dialog-description">
            {t("Êtes-vous sûr de vouloir supprimer ce client ?")}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpen(false)} color="primary">
            {t("Non")}
          </Button>
          <Button onClick={handleDeleteClient} color="primary" autoFocus>
            {t("Oui")}
          </Button>
        </DialogActions>
      </Dialog>
    </SoftBox>
  );
}

export default ActionCell;
