import { useState } from "react";
import { useNavigate } from "react-router-dom";
import Card from "@mui/material/Card";
import Switch from "@mui/material/Switch";
import CircularProgress from "@mui/material/CircularProgress";
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftInput from "components/SoftInput";
import SoftButton from "components/SoftButton";
import BasicLayout from "layouts/authentication/components/BasicLayout";
import Dialog from "@mui/material/Dialog";
import curved9 from "assets/images/curved-images/curved9.jpg";
import AuthApi from "../../../api/auth";
import { useAuth } from "../../../auth-context/auth.context";
import { useTranslation } from "react-i18next";
import {
  CheckCircle,
  Warehouse,
  Visibility,
  VisibilityOff,
} from "@mui/icons-material"; // Import icons
import { Box, IconButton, CardContent, Typography } from "@mui/material";
import WarehouseApi from "../../../api/warehouse"; // Import WarehouseApi

function Basic() {
  const [rememberMe, setRememberMe] = useState(false);
  const [formData, setFormData] = useState({ phone: "", password: "" });
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false); // State for password visibility
  const [openDialog, setOpenDialog] = useState(false);
  const [selectedWarehouse, setSelectedWarehouse] = useState(null);
  const [warehouses, setWarehouses] = useState([]);
  const [isConfirmed, setIsConfirmed] = useState(false);
  const navigate = useNavigate();
  const { setUser } = useAuth();
  const token = localStorage.getItem("token");
  const { t } = useTranslation();

  const handleSetRememberMe = () => setRememberMe(!rememberMe);
  const handleChange = (e) =>
    setFormData({ ...formData, [e.target.name]: e.target.value });

  const togglePasswordVisibility = () => setShowPassword(!showPassword);

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);
    AuthApi.Login(formData)
      .then((response) => {
        setLoading(false);
        if (response.data.msg === "Login Success") {
          setProfile(response);
        } else {
          setError(response.data.msg);
        }
      })
      .catch((error) => {
        setLoading(false);
        setError(
          error.response ? error.response.data.msg : "There has been an error."
        );
      });
  };

  const setProfile = (response) => {
    const user = { ...response.data.username, token: response.data.access };
    setUser(user);
    localStorage.setItem("user", JSON.stringify(response.data));
    localStorage.setItem("token", response.data.access);
    localStorage.setItem("manager", response.data.username);

    // Update the warehouses based on the response data
    setWarehouses(
      response.data.warehouse_name.map((name, index) => ({
        id: response.data.affected_warehouse[index],
        name: name,
      }))
    );

    setOpenDialog(true);
  };

  const handleWarehouseSelect = (warehouse) => {
    setSelectedWarehouse(warehouse);
    setIsConfirmed(false); // Reset confirmation
  };

  const handleConfirmSelection = () => {
    if (selectedWarehouse) {
      // Assign the selected warehouse to the manager via API
      WarehouseApi.assignDefaultWarehouseTOManager(token, selectedWarehouse.id)
        .then((response) => {
          if (
            response.data.message === "Default warehouse assigned successfully"
          ) {
            // If successful, store the selected warehouse in localStorage
            localStorage.setItem(
              "selectedWarehouse",
              JSON.stringify(selectedWarehouse)
            );
            localStorage.setItem("warehouseName", selectedWarehouse.name);
            setOpenDialog(false);
            navigate("/statistiques/statistiques-generales");
          } else {
            // Handle errors if the assignment fails
            console.error("Failed to assign warehouse:", response.data.msg);
          }
        })
        .catch((error) => {
          console.error("API call failed:", error);
        });
    }
  };

  return (
    <>
      <BasicLayout title={t("Welcome to Livrili")} image={curved9}>
        {!token ? (
          <Card>
            <SoftBox p={3} mb={1} textAlign="center">
              <SoftTypography variant="h5" fontWeight="medium">
                {t("login")}
              </SoftTypography>
            </SoftBox>
            <SoftBox textAlign="center">
              <SoftTypography
                variant="button"
                color="text"
                fontWeight="regular"
              >
                ({t("phone and password")})
              </SoftTypography>
            </SoftBox>
            <SoftBox p={3}>
              <SoftBox component="form" role="form" onSubmit={handleSubmit}>
                <SoftBox mb={2}>
                  <SoftInput
                    type="text"
                    placeholder="Téléphone"
                    value={formData.phone}
                    name="phone"
                    onChange={handleChange}
                  />
                </SoftBox>
                <SoftBox mb={2}>
                  <Box sx={{ position: "relative" }}>
                    <SoftInput
                      type={showPassword ? "text" : "password"}
                      placeholder="Mot de passe"
                      value={formData.password}
                      name="password"
                      onChange={handleChange}
                    />
                    <IconButton
                      onClick={togglePasswordVisibility}
                      sx={{
                        position: "absolute",
                        right: "10px",
                        top: "50%",
                        transform: "translateY(-50%)",
                      }}
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </Box>
                </SoftBox>
                <SoftBox display="flex" alignItems="center">
                  <Switch checked={rememberMe} onChange={handleSetRememberMe} />
                  <SoftTypography
                    variant="button"
                    fontWeight="regular"
                    onClick={handleSetRememberMe}
                    sx={{ cursor: "pointer", userSelect: "none" }}
                  >
                    &nbsp;&nbsp;{t("Remember me")}
                  </SoftTypography>
                </SoftBox>
                <SoftBox mt={2} mb={2} textAlign="center">
                  <h6
                    style={{
                      fontSize: ".8em",
                      color: "red",
                      textAlign: "center",
                      fontWeight: 400,
                    }}
                  >
                    {error}
                  </h6>
                </SoftBox>
                <SoftBox mt={4} mb={1}>
                  <SoftButton
                    variant="gradient"
                    color="info"
                    fullWidth
                    type="submit"
                    disabled={loading}
                  >
                    {loading ? (
                      <CircularProgress size={24} color="inherit" />
                    ) : (
                      t("login")
                    )}
                  </SoftButton>
                </SoftBox>
              </SoftBox>
            </SoftBox>
          </Card>
        ) : null}
      </BasicLayout>
      {/* Dialog for selecting warehouse */}
      <Dialog
        open={openDialog}
        onClose={() => setOpenDialog(false)}
        aria-labelledby="select-warehouse-dialog"
        aria-describedby="select-warehouse-description"
        maxWidth="sm"
        fullWidth
      >
        <SoftBox
          p={3}
          bgcolor="white"
          borderRadius="16px"
          sx={{
            boxShadow: "0px 12px 48px rgba(0, 0, 0, 0.2)",
            transition: "all 0.3s ease",
          }}
        >
          <SoftTypography
            variant="h5"
            fontWeight="bold"
            mb={3}
            color="textPrimary"
            textAlign="center"
          >
            {t("Sélectionner un entrepôt")}
          </SoftTypography>

          <Box display="flex" flexDirection="column" gap={2}>
            {warehouses.map((warehouse) => (
              <Card
                key={warehouse.id}
                variant="outlined"
                sx={{
                  justifyContent: "center",
                  cursor: "pointer",
                  boxShadow:
                    selectedWarehouse?.id === warehouse.id
                      ? "0px 4px 12px rgba(33, 150, 243, 0.2)"
                      : "0px 4px 12px rgba(0, 0, 0, 0.1)",
                  borderRadius: "12px",
                  transition: "all 0.3s ease",
                  "&:hover": {
                    transform: "scale(1.02)",
                    boxShadow: "0px 6px 18px rgba(33, 150, 243, 0.15)",
                  },
                }}
                onClick={() => handleWarehouseSelect(warehouse)}
              >
                <CardContent
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    paddingBottom: "13px !important",
                    paddingTop: "13px !important",
                  }}
                >
                  <Box display="flex" alignItems="center">
                    <Warehouse
                      sx={{
                        color:
                          selectedWarehouse?.id === warehouse.id
                            ? "#2196f3"
                            : "#757575",
                        mr: 2,
                        width: "30px",
                        height: "30px",
                      }}
                    />
                    <Typography
                      variant="subtitle1"
                      fontSize={16}
                      fontWeight={
                        selectedWarehouse?.id === warehouse.id
                          ? "bold"
                          : "regular"
                      }
                      color={
                        selectedWarehouse?.id === warehouse.id
                          ? "info.dark"
                          : "textPrimary"
                      }
                    >
                      {warehouse.name}
                    </Typography>
                  </Box>
                  {selectedWarehouse?.id === warehouse.id && (
                    <CheckCircle color="success" />
                  )}
                </CardContent>
              </Card>
            ))}
          </Box>

          <SoftBox display="flex" justifyContent="center" mt={4}>
            <SoftButton
              variant="gradient"
              color="info"
              onClick={handleConfirmSelection}
              disabled={!selectedWarehouse || isConfirmed}
            >
              {t("Confirmer")}
            </SoftButton>
          </SoftBox>
        </SoftBox>
      </Dialog>
    </>
  );
}

export default Basic;
