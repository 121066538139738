import React, { useEffect, useState } from "react";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import SoftBox from "components/SoftBox";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import SoftButton from "components/SoftButton";
import SoftTypography from "components/SoftTypography";
import { CircularProgress, Snackbar, Alert } from "@mui/material";
import SoftSelect from "components/SoftSelect";
import CustomersApi from "api/customers";
import OrdersApi from "api/orders";
import InvoicesApi from "api/invoices";
import { useNavigate } from "react-router-dom";
import Footer from "examples/Footer";
import { useTranslation } from "react-i18next";

function CreateInvoice() {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const [orders, setOrders] = useState([]);
  const [clients, setClients] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectedClient, setSelectedClient] = useState(null);
  const [selectedOrders, setSelectedOrders] = useState([]);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      const token = localStorage.getItem("token");
      if (!token) {
        setError("No token found in localStorage.");
        setLoading(false);
        return;
      }

      try {
        const customersResponse = await CustomersApi.GetCustomersoExtract(
          token
        );
        setClients(customersResponse.data);
      } catch (error) {
        setError("Error fetching clients.");
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    const fetchOrders = async () => {
      if (!selectedClient) return;

      setLoading(true);
      const token = localStorage.getItem("token");
      if (!token) {
        setError("No token found in localStorage.");
        setLoading(false);
        return;
      }

      try {
        const ordersResponse = await OrdersApi.GetDeliveredOrdersByClient(
          token,
          {
            customer_id: selectedClient.value,
          }
        );
        setOrders(ordersResponse.data);
      } catch (error) {
        setError("Error fetching orders for the selected client.");
      } finally {
        setLoading(false);
      }
    };

    fetchOrders();
  }, [selectedClient]);

  const handleClientChange = (selectedOption) => {
    setSelectedClient(selectedOption);
    setSelectedOrders([]);
  };

  const handleOrderChange = (selectedOptions) => {
    setSelectedOrders(selectedOptions || []);
  };

  const handleSubmit = async () => {
    const token = localStorage.getItem("token");
    if (!token) {
      setError("No token found in localStorage.");
      return;
    }

    if (!selectedClient || selectedOrders.length === 0) {
      setError("Please select a client and at least one order.");
      return;
    }

    const invoiceData = {
      client: selectedClient.label,
      // client_name: selectedClient.label,
      orders: selectedOrders.map((order) => order.value),
    };

    try {
      await InvoicesApi.CreateInvoices(invoiceData, token);
      setSuccess(true);
      navigate("/gestion-des-ventes/etat-de-facture");
    } catch (error) {
      setError("Error creating invoice.");
    }
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <SoftBox
        display="flex"
        flexDirection="column"
        justifyContent="space-between"
        minHeight="85vh"
      >
        <SoftBox py={3} mt={10}>
          <Grid container justifyContent="center" sx={{ height: "100%" }}>
            <Grid item xs={12} sm={10} md={8} lg={6}>
              <Card elevation={3}>
                <CardContent>
                  <SoftBox mt={3}>
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <SoftBox mb={2}>
                          <SoftTypography
                            component="label"
                            variant="caption"
                            fontWeight="bold"
                            textTransform="capitalize"
                          >
                            {t("Client")}
                          </SoftTypography>
                          <SoftSelect
                            label={t("Client")}
                            placeholder={t("Sélectionner un client")}
                            value={selectedClient}
                            onChange={handleClientChange}
                            options={
                              loading
                                ? [
                                    {
                                      value: "",
                                      label: (
                                        <SoftBox
                                          display="flex"
                                          justifyContent="center"
                                          alignItems="center"
                                          minWidth="100%"
                                          minHeight="48px"
                                        >
                                          <CircularProgress
                                            size={20}
                                            sx={{
                                              color: "#1F51FF",
                                              marginLeft: 7,
                                            }}
                                          />
                                        </SoftBox>
                                      ),
                                    },
                                  ]
                                : clients.length > 0
                                ? clients.map((client) => ({
                                    value: client.id,
                                    label:
                                      client.username ||
                                      `${t("Client")} ${client.id}`,
                                  }))
                                : [
                                    {
                                      value: "",
                                      label: `${t("Aucun client disponible")}`,
                                    },
                                  ]
                            }
                          />
                        </SoftBox>
                      </Grid>
                      <Grid item xs={12}>
                        <SoftBox mb={2}>
                          <SoftTypography
                            component="label"
                            variant="caption"
                            fontWeight="bold"
                            textTransform="capitalize"
                          >
                            {t("Commande")}
                          </SoftTypography>
                          <SoftSelect
                            label={t("Commande")}
                            placeholder={t("Sélectionner une commande")}
                            value={selectedOrders}
                            onChange={handleOrderChange}
                            isMulti
                            closeMenuOnSelect={false}
                            disabled={
                              orders.length === 0 && !loading && !selectedClient
                            }
                            options={
                              loading
                                ? [
                                    {
                                      value: "",
                                      label: (
                                        <SoftBox
                                          display="flex"
                                          justifyContent="center"
                                          alignItems="center"
                                          minWidth="100%"
                                          minHeight="48px"
                                        >
                                          <CircularProgress
                                            size={20}
                                            sx={{
                                              color: "#1F51FF",
                                              marginLeft: 7,
                                            }}
                                          />
                                        </SoftBox>
                                      ),
                                    },
                                  ]
                                : orders.length > 0
                                ? orders.map((order) => ({
                                    value: order.id,
                                    label: `${t("Commande")} ID ${order.id}`,
                                  }))
                                : [
                                    {
                                      value: "",
                                      label: `${t(
                                        "Aucune commande disponible"
                                      )}`,
                                    },
                                  ]
                            }
                          />
                        </SoftBox>
                      </Grid>
                    </Grid>
                  </SoftBox>
                  <SoftBox mt={2} display="flex" justifyContent="flex-end">
                    <SoftButton
                      onClick={handleSubmit}
                      variant="gradient"
                      color="dark"
                    >
                      {t("Enregistrer")}
                    </SoftButton>
                  </SoftBox>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </SoftBox>
        <Footer />
      </SoftBox>
      {/* Snackbar for error and success messages */}
      <Snackbar
        open={!!error}
        autoHideDuration={6000}
        onClose={() => setError(null)}
      >
        <Alert onClose={() => setError(null)} severity="error">
          {error}
        </Alert>
      </Snackbar>
      <Snackbar
        open={success}
        autoHideDuration={6000}
        onClose={() => setSuccess(false)}
      >
        <Alert onClose={() => setSuccess(false)} severity="success">
          {t("Invoice created successfully!")}
        </Alert>
      </Snackbar>
    </DashboardLayout>
  );
}

export default CreateInvoice;
