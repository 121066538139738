import React from "react";
import Icon from "@mui/material/Icon";
import Tooltip from "@mui/material/Tooltip";
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

function FactureCell({ delivery_note_id }) {
  const { t } = useTranslation();

  return (
    <SoftBox display="flex" alignItems="center">
      <SoftTypography
        variant="body1"
        color="grey-400"
        sx={{ cursor: "pointer", lineHeight: 0, marginRight: 2 }}
      >
        <Tooltip title={t("Aperçu Bon de livraison")} placement="top">
          <Link to={`/details-de-bon-de-livraison/${delivery_note_id}`}>
            <Icon>receipt</Icon>
          </Link>
        </Tooltip>
      </SoftTypography>
    </SoftBox>
  );
}

export default FactureCell;
