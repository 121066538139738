import React, { useState, useEffect } from "react";
import { Card, Grid, Typography, Box, CircularProgress } from "@mui/material";
import SoftBox from "components/SoftBox";
import Footer from "examples/Footer";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import SoftButton from "components/SoftButton";
import FormField from "../ajouter-articles/components/FormField";
import { useLocation, useNavigate } from "react-router-dom";
import SupplierApi from "api/supplier";
import SoftTypography from "components/SoftTypography";
import { useTranslation } from "react-i18next";

function EditSupplier() {
  const navigate = useNavigate();
  const location = useLocation();
  const { supplierID } = location.state || {};
  const { t } = useTranslation();

  const [supplierData, setSupplierData] = useState({
    name: "",
    phone: "",
    email: "",
  });
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(true);
  const [saving, setSaving] = useState(false);

  // Fetch supplier data by ID when the component mounts
  useEffect(() => {
    const fetchSuppliers = async () => {
      const token = localStorage.getItem("token");
      try {
        const supplierData = await SupplierApi.GetSupplierByID(
          token,
          supplierID
        );
        setSupplierData(supplierData.data);
      } catch (error) {
        console.error("Failed to fetch supplier:", error);
      } finally {
        setLoading(false);
      }
    };

    if (supplierID) fetchSuppliers();
  }, [supplierID]);

  // Validation function
  const validate = () => {
    const newErrors = {};
    if (!supplierData.name.trim()) {
      newErrors.name = t("Le nom est obligatoire.");
    }
    if (!supplierData.phone.trim()) {
      newErrors.phone = t("Le téléphone est obligatoire.");
    } else if (!/^\d+$/.test(supplierData.phone)) {
      newErrors.phone = t("Le téléphone doit contenir uniquement des chiffres.");
    }
    if (!supplierData.email.trim()) {
      newErrors.email = t("L'email est obligatoire.");
    } else if (!/^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/.test(supplierData.email)) {
      newErrors.email = t("L'email n'est pas valide.");
    }
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  // Save function with validation
  const handleSave = async () => {
    if (!validate()) return;

    setSaving(true);
    const token = localStorage.getItem("token");
    try {
      await SupplierApi.EditSupplier(token, supplierID, supplierData);
      navigate("/gestion-de-stock/listes-des-fournisseurs");
    } catch (error) {
      console.error("Failed to update supplier:", error);
      alert("Erreur lors de la mise à jour du fournisseur.");
    } finally {
      setSaving(false);
    }
  };

  if (loading) {
    return (
      <DashboardLayout>
        <DashboardNavbar />
        <SoftBox
          display="flex"
          justifyContent="center"
          alignItems="center"
          p={35}
        >
          <CircularProgress sx={{ color: "#1F51FF" }} />
        </SoftBox>
      </DashboardLayout>
    );
  }

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <SoftBox
        display="flex"
        flexDirection="column"
        justifyContent="space-between"
        minHeight="85vh"
      >
        <SoftBox py={3} px={14}>
          <Card>
            <SoftBox py={3} px={5}>
              <Box>
                <Typography variant="h6" fontWeight="bold" mb={2}>
                  {t("Modifier les informations du fournisseur")}
                </Typography>

                <Grid container spacing={3} mb={2}>
                  <Grid item xs={12} sm={4}>
                    <FormField
                      autoFocus
                      margin="dense"
                      label={t("Nom")}
                      fullWidth
                      value={supplierData.name}
                      onChange={(e) =>
                        setSupplierData({
                          ...supplierData,
                          name: e.target.value,
                        })
                      }
                      error={Boolean(errors.name)}
                    />
                    <SoftTypography
                      component="label"
                      variant="caption"
                      color="error"
                    >
                      {errors.name}
                    </SoftTypography>
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <FormField
                      margin="dense"
                      label={t("Téléphone")}
                      fullWidth
                      value={supplierData.phone}
                      onChange={(e) =>
                        setSupplierData({
                          ...supplierData,
                          phone: e.target.value,
                        })
                      }
                      error={Boolean(errors.phone)}
                    />
                    <SoftTypography
                      component="label"
                      variant="caption"
                      color="error"
                    >
                      {errors.phone}
                    </SoftTypography>
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <FormField
                      margin="dense"
                      label={t("Email")}
                      fullWidth
                      value={supplierData.email}
                      onChange={(e) =>
                        setSupplierData({
                          ...supplierData,
                          email: e.target.value,
                        })
                      }
                      error={Boolean(errors.email)}
                    />
                    <SoftTypography
                      component="label"
                      variant="caption"
                      color="error"
                    >
                      {errors.email}
                    </SoftTypography>
                  </Grid>
                </Grid>
              </Box>
              <Box display="flex" justifyContent="flex-end">
                <SoftButton
                  variant="contained"
                  color="info"
                  type="submit"
                  onClick={handleSave}
                  disabled={saving}
                >
                  {saving
                    ? `${t("Enregistrement")}...`
                    : t("Enregistrer les modifications")}
                </SoftButton>
              </Box>
            </SoftBox>
          </Card>
        </SoftBox>
        <Footer />
      </SoftBox>
    </DashboardLayout>
  );
}

export default EditSupplier;
