import React, { useEffect, useState } from "react";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import {
  Grid,
  Card,
  Divider,
  CircularProgress,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import OrdersApi from "api/orders";
import DynamicDataTable from "examples/Tables/DynamicTable";
import SoftButton from "components/SoftButton";
import { useTranslation } from "react-i18next";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

function OrderDetails() {
  const { t } = useTranslation();

  const navigate = useNavigate();
  const location = useLocation();
  const { orderId } = useParams();
  const [orderlines, setOrderlines] = useState([]);
  const [order, setOrder] = useState({});
  const [loading, setLoading] = useState(true);
  const [actionLoading, setActionLoading] = useState(false);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [dialogAction, setDialogAction] = useState(null);
  const [previousRoute, setPreviousRoute] = useState(null);

  useEffect(() => {
    const previousPath = location.state?.from;
    setPreviousRoute(previousPath);
  }, [location]);

  const handleGetOrderlines = () => {
    const token = localStorage.getItem("token");

    if (!token) {
      console.log("Jeton non trouvé. Veuillez vous reconnecter.");
      setLoading(false);
      return;
    }

    OrdersApi.GetOrderlines(orderId, token)
      .then((response) => {
        if (response.data) {
          setOrderlines(response.data.order_lines || []);
          setOrder(response.data);
        } else {
          console.log("Aucune donnée trouvée.");
        }
      })
      .catch((error) => {
        console.log(
          error.response?.data?.msg ||
            "Une erreur s'est produite lors de la récupération de la commande."
        );
      })
      .finally(() => setLoading(false));
  };

  const handleConfirmOrder = () => {
    setActionLoading(true);
    const token = localStorage.getItem("token");

    if (previousRoute === "/gestion-des-commandes/commandes-encours") {
      OrdersApi.ConfirmDraftOrders(token, orderId)
        .then(() => {
          navigate("/gestion-des-commandes/commandes-confirmees");
        })
        .catch((error) => {
          console.log(
            error.response?.data?.msg ||
              "Une erreur s'est produite lors de la confirmation de la commande."
          );
        })
        .finally(() => setActionLoading(false));
    } else if (
      previousRoute === "/gestion-des-commandes/commandes-confirmees"
    ) {
      OrdersApi.OnWayConfirmedOrders(token, orderId)
        .then(() => {
          navigate("/gestion-des-commandes/commandes-expediees");
        })
        .catch((error) => {
          console.log(
            error.response?.data?.msg ||
              "Une erreur s'est produite lors de la confirmation de la commande."
          );
        })
        .finally(() => setActionLoading(false));
    } else if (previousRoute === "/gestion-des-commandes/commandes-expediees") {
      OrdersApi.DeliverOnWayOrders(token, orderId)
        .then(() => {
          navigate("/gestion-des-commandes/commandes-livrees");
        })
        .catch((error) => {
          console.log(
            error.response?.data?.msg ||
              "Une erreur s'est produite lors de la confirmation de la commande."
          );
        })
        .finally(() => setActionLoading(false));
    }
  };

  const handleCancelOrder = () => {
    setActionLoading(true);
    const token = localStorage.getItem("token");
    if (previousRoute === "/gestion-des-commandes/commandes-encours") {
      OrdersApi.CancelDraftOrders(token, orderId)
        .then(() => {
          navigate("/gestion-des-commandes/commandes-annulees");
        })
        .catch((error) => {
          console.log(
            error.response?.data?.msg ||
              "Une erreur s'est produite lors de l'annulation de la commande."
          );
        })
        .finally(() => setActionLoading(false));
    } else if (
      previousRoute === "/gestion-des-commandes/commandes-confirmees"
    ) {
      OrdersApi.CancelConfirmedOrders(token, orderId)
        .then(() => {
          navigate("/gestion-des-commandes/commandes-annulees");
        })
        .catch((error) => {
          console.log(
            error.response?.data?.msg ||
              "Une erreur s'est produite lors de l'annulation de la commande."
          );
        })
        .finally(() => setActionLoading(false));
    } else if (previousRoute === "/gestion-des-commandes/commandes-expediees") {
      OrdersApi.CancelOnWayOrders(token, orderId)
        .then(() => {
          navigate("/gestion-des-commandes/commandes-annulees");
        })
        .catch((error) => {
          console.log(
            error.response?.data?.msg ||
              "Une erreur s'est produite lors de l'annulation de la commande."
          );
        })
        .finally(() => setActionLoading(false));
    }
  };

  const handleDialogOpen = (action) => {
    setDialogAction(action);
    setDialogOpen(true);
  };

  const handleDialogClose = (confirm) => {
    setDialogOpen(false);
    if (confirm) {
      if (dialogAction === "confirm") {
        handleConfirmOrder();
      } else if (dialogAction === "cancel") {
        handleCancelOrder();
      }
    }
  };

  useEffect(() => {
    handleGetOrderlines();
    // eslint-disable-next-line
  }, [orderId]);

  const dataTableDataOrders = {
    columns: [
      {
        Header: `${t("Nom du produit")}`,
        accessor: "product_name",
      },
      { Header: `${t("Quantité")}`, accessor: "quantity", width: 120 },
      { Header: `${t("Prix unitaire")}`, accessor: "unit_price", width: 150 },
      { Header: `${t("Prix de box")}`, accessor: "total_price", width: 120 },
      {
        Header: `${t("Articles par boîte")}`,
        accessor: "box_items_quantity",
        width: 150,
      },
      { Header: `${t("Catégorie")}`, accessor: "product_category", width: 150 },
    ],
    rows: orderlines.map((orderline) => ({
      product_name: orderline.product_name,
      quantity: orderline.quantity,
      unit_price: `${orderline.unit_price} TND`,
      total_price: `${orderline.total_price} TND`,
      box_items_quantity: orderline.box_items_quantity || 1,
      product_category: orderline.product_category,
    })),
  };

  const handleGoBack = () => navigate(-1);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <SoftBox
        display="flex"
        flexDirection="column"
        justifyContent="space-between"
        minHeight="85vh"
      >
        <SoftBox my={7}>
          <Grid container spacing={3} justifyContent="center">
            <Grid item xs={12}>
              {/* Go Back Button */}
              {!loading && (
                <SoftBox mb={2}>
                  <SoftButton
                    variant="outlined"
                    color="secondary"
                    onClick={handleGoBack}
                    startIcon={<ArrowBackIcon />}
                    sx={{
                      borderWidth: 2,
                      padding: "8px 16px",
                      textTransform: "none",
                      fontWeight: "bold",
                    }}
                  >
                    {t("Retour")}
                  </SoftButton>
                </SoftBox>
              )}

              {loading ? (
                <SoftBox
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  p={35}
                >
                  <CircularProgress sx={{ color: "#1F51FF" }} />
                </SoftBox>
              ) : (
                <Card>
                  <SoftBox pt={2} px={2}>
                    <SoftBox
                      display="flex"
                      justifyContent="space-between"
                      flexDirection={{ xs: "column", sm: "row" }} // Stack on small screens, row on medium+
                    >
                      <SoftBox mb={{ xs: 2, md: 0 }}>
                        {/* Add margin for spacing on mobile */}
                        <SoftBox mb={1}>
                          <SoftTypography variant="h6" fontWeight="medium">
                            {t("Détails de la commande")}
                          </SoftTypography>
                        </SoftBox>
                        <SoftTypography
                          component="p"
                          variant="button"
                          fontWeight="regular"
                          color="text"
                        >
                          {t("Commande")} #
                          <span style={{ fontWeight: "bold" }}>{order.id}</span>
                        </SoftTypography>
                        <SoftTypography
                          component="p"
                          variant="button"
                          fontWeight="regular"
                          color="text"
                        >
                          {t("Mode de paiement")} :{" "}
                          <span
                            style={{
                              fontWeight: "bold",
                              color: "blue",
                            }}
                          >
                            {order.payment_methode}
                          </span>
                        </SoftTypography>
                      </SoftBox>
                      <SoftTypography
                        component="p"
                        variant="button"
                        fontWeight="bold"
                        color="text"
                        style={{ marginRight: "10px" }}
                        textAlign={{ xs: "left", sm: "right" }} // Center text on mobile, align right on desktop
                      >
                        {t("Prix total")} :{" "}
                        <span
                          style={{
                            fontWeight: "bold",
                            color: "blue",
                          }}
                        >
                          {order.total_price} TND
                        </span>
                      </SoftTypography>
                    </SoftBox>
                  </SoftBox>
                  <Divider />
                  <SoftBox pb={3} px={2}>
                    <SoftBox>
                      <DynamicDataTable
                        columns={dataTableDataOrders.columns}
                        rows={dataTableDataOrders.rows}
                      />
                    </SoftBox>
                  </SoftBox>
                  {(previousRoute ===
                    "/gestion-des-commandes/commandes-encours" ||
                    previousRoute ===
                      "/gestion-des-commandes/commandes-confirmees" ||
                    previousRoute ===
                      "/gestion-des-commandes/commandes-expediees") && (
                    <SoftBox
                      p={2}
                      display="flex"
                      justifyContent="flex-end"
                      gap={2}
                    >
                      <SoftButton
                        variant="contained"
                        color="error"
                        disabled={actionLoading}
                        onClick={() => handleDialogOpen("cancel")}
                      >
                        {t("Annuler la commande")}
                      </SoftButton>
                      <SoftButton
                        variant="contained"
                        color="info"
                        disabled={actionLoading}
                        onClick={() => handleDialogOpen("confirm")}
                      >
                        {previousRoute ===
                        "/gestion-des-commandes/commandes-encours"
                          ? `${t("Confirmer la commande")}`
                          : previousRoute ===
                            "/gestion-des-commandes/commandes-confirmees"
                          ? `${t("Marquer comme en cours de livraison")}`
                          : `${t("Livrer la commande")}`}
                      </SoftButton>
                    </SoftBox>
                  )}
                </Card>
              )}
            </Grid>
          </Grid>
        </SoftBox>
        <Footer />
      </SoftBox>

      {/* Confirmation Dialog */}
      <Dialog open={dialogOpen} onClose={() => handleDialogClose(false)}>
        <DialogTitle>{t("Confirmation")}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            {dialogAction === "confirm"
              ? `${t("Êtes-vous sûr de vouloir confirmer cette commande ?")}`
              : `${t("Êtes-vous sûr de vouloir annuler cette commande ?")}`}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => handleDialogClose(false)} color="primary">
            {t("Non")}
          </Button>
          <Button
            onClick={() => handleDialogClose(true)}
            color="primary"
            autoFocus
          >
            {t("Oui")}
          </Button>
        </DialogActions>
      </Dialog>
    </DashboardLayout>
  );
}

export default OrderDetails;
