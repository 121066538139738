import { useRef, useEffect, useState, useMemo } from "react";
import PropTypes from "prop-types";
import { Line } from "react-chartjs-2";
import Card from "@mui/material/Card";
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import gradientChartLine from "assets/theme/functions/gradientChartLine";
import configs from "layouts/pages/widgets/components/Chart/configs";
import colors from "assets/theme/base/colors";

function Chart({ title, count, percentage, chart }) {
  const chartRef = useRef(null);
  const [chartData, setChartData] = useState({});

  useEffect(() => {
    if (chart && chart.labels && chart.datasets) {
      const chartDatasets = chart.datasets.map((dataset) => ({
        ...dataset,
        tension: 0.4,
        pointRadius: 0,
        borderWidth: 2,
        borderColor: colors[dataset.color].main,
        fill: true,
        maxBarThickness: 6,
        backgroundColor: gradientChartLine(
          chartRef.current.children[0],
          colors[dataset.color].main,
          0.075
        ),
      }));

      setChartData(configs(chart.labels, chartDatasets));
    }
  }, [chart]);

  const { data, options } = chartData;

  return (
    <Card>
      <SoftBox p={2} lineHeight={1}>
        <SoftTypography
          variant="button"
          textTransform="capitalize"
          fontWeight="medium"
          color="text"
        >
          {title}
        </SoftTypography>
        <SoftTypography variant="h5" fontWeight="bold" color="dark">
          {count}&nbsp;
          <SoftTypography
            variant="button"
            fontWeight="bold"
            color={percentage.color}
          >
            {percentage.label}
          </SoftTypography>
        </SoftTypography>
      </SoftBox>
      {useMemo(
        () => (
          <SoftBox ref={chartRef} sx={{ height: "6.25rem" }}>
            {data && options ? (
              <Line data={data} options={options} />
            ) : (
              <div>Loading...</div>
            )}
          </SoftBox>
        ),
        // eslint-disable-next-line
        [chartData]
      )}
    </Card>
  );
}

// Typechecking props for the Chart
Chart.propTypes = {
  title: PropTypes.string.isRequired,
  count: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  percentage: PropTypes.shape({
    color: PropTypes.oneOf([
      "primary",
      "secondary",
      "info",
      "success",
      "warning",
      "error",
      "dark",
    ]),
    label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  }).isRequired,
  chart: PropTypes.shape({
    labels: PropTypes.array.isRequired,
    datasets: PropTypes.arrayOf(
      PropTypes.shape({
        label: PropTypes.string,
        data: PropTypes.array,
        color: PropTypes.string,
      })
    ).isRequired,
  }).isRequired,
};

export default Chart;
