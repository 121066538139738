import React, { useState, useEffect, useRef } from "react";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import ProductsApi from "api/products";
import { useNavigate } from "react-router-dom";
import CircularProgress from "@mui/material/CircularProgress";
import SoftTypography from "components/SoftTypography";
import FormField from "./components/FormField";
import SoftBox from "components/SoftBox";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import SoftButton from "components/SoftButton";
import SoftSelect from "components/SoftSelect";
import OrdersApi from "api/orders";
import CustomersApi from "api/customers";
import Footer from "examples/Footer";
import { useTranslation } from "react-i18next";
import StockApi from "api/stock";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";

function NewOrder() {
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();

  const [orderInfo, setOrderInfo] = useState({
    customer_id: "",
    order_details: [
      { product_id: "", quantity: "", total_price: 0 },
      { product_id: "", quantity: "", total_price: 0 },
      { product_id: "", quantity: "", total_price: 0 },
    ],
    delivery_date: "",
    delivery_method: "transport",
    payment_methode: "cash",
    total_price: 0,
  });
  const [loading, setLoading] = useState(false);
  const [productsLoading, setProductsLoading] = useState(false);
  // eslint-disable-next-line
  const [loadingSave, setLoadingSave] = useState(false);
  const [products, setProducts] = useState([]);
  const [customers, setCustomers] = useState([]);
  const [errors, setErrors] = useState({});
  const [stock, setStock] = useState([]);
  const warehouseName = localStorage.getItem("warehouseName");
  const [dialogOpen, setDialogOpen] = useState(false);
  const [isConfirming, setIsConfirming] = useState(false);

  const isLargeScreen = window.innerWidth >= 700;
  const breakpoints = [
    { max: Infinity, min: 2100, value: "4%" },
    { max: 2100, min: 1900, value: "-3%" },
    { max: 1900, min: 1850, value: "-6%" },
    { max: 1850, min: 1800, value: "-6%" },
    { max: 1800, min: 1750, value: "-10%" },
    { max: 1750, min: 1700, value: "-13%" },
    { max: 1700, min: 1650, value: "-14%" },
    { max: 1650, min: 1600, value: "-18%" },
    { max: 1600, min: 1550, value: "-21%" },
    { max: 1550, min: 1500, value: "-24%" },
    { max: 1500, min: 1400, value: "25%" },
    { max: 1400, min: 1350, value: "23%" },
    { max: 1350, min: 1300, value: "22%" },
    { max: 1300, min: 1250, value: "20%" },
    { max: 1250, min: 1200, value: "19%" },
    { max: 1200, min: 1000, value: "53%" },
    { max: 1000, min: 950, value: "52%" },
    { max: 950, min: 900, value: "52.5%" },
    { max: 900, min: 769, value: "53%" },
  ];

  const rightPosition =
    breakpoints.find(
      ({ min, max }) => window.innerWidth >= min && window.innerWidth < max
    )?.value || 0;

  const paymentRightPosition =
    window.innerWidth >= 1900
      ? i18n.language === "ar"
        ? "-218%"
        : "0"
      : window.innerWidth >= 1800
      ? i18n.language === "ar"
        ? "-219%"
        : "0"
      : window.innerWidth >= 1700
      ? i18n.language === "ar"
        ? "-220%"
        : "0"
      : window.innerWidth >= 1600
      ? i18n.language === "ar"
        ? "-223%"
        : "0"
      : window.innerWidth >= 1500
      ? i18n.language === "ar"
        ? "-225%"
        : "0"
      : window.innerWidth >= 1400
      ? i18n.language === "ar"
        ? "-224%"
        : "0"
      : window.innerWidth >= 1300
      ? i18n.language === "ar"
        ? "-225%"
        : "0"
      : window.innerWidth >= 1200
      ? i18n.language === "ar"
        ? "-229%"
        : "0"
      : window.innerWidth >= 1100
      ? i18n.language === "ar"
        ? "-224%"
        : "0"
      : window.innerWidth >= 1000
      ? i18n.language === "ar"
        ? "-227%"
        : "0"
      : window.innerWidth >= 950
      ? i18n.language === "ar"
        ? "-218%"
        : "0"
      : window.innerWidth >= 900
      ? i18n.language === "ar"
        ? "-219%"
        : "0"
      : window.innerWidth >= 850
      ? i18n.language === "ar"
        ? "-221%"
        : "0"
      : window.innerWidth >= 800
      ? i18n.language === "ar"
        ? "-222%"
        : "0"
      : i18n.language === "ar"
      ? "-223%"
      : "0";

  const dropdownContainerRef = useRef(null);

  useEffect(() => {
    handleGetStockOfAllWarehouses();
  }, []);

  const isFormEmpty = () => {
    const hasValidProduct = orderInfo.order_details.some(
      (detail) => detail.product_id && detail.quantity > 0
    );

    return (
      !orderInfo.customer_id ||
      !hasValidProduct ||
      !orderInfo.delivery_date ||
      !orderInfo.delivery_method.value ||
      !orderInfo.payment_methode.value
    );
  };

  const handleGetStockOfAllWarehouses = () => {
    const token = localStorage.getItem("token");
    if (!token) {
      console.error("No token found in localStorage.");
      return;
    }
    setProductsLoading(true);
    StockApi.GetStockOfAllWarehouses(token)
      .then((response) => {
        if (response && response.data.data) {
          setStock(response.data.data);
        } else {
          console.warn("No data found:", response.data.msg);
        }
      })
      .catch((error) => {
        if (error.response) {
          console.error("Error response data:", error.response.data.msg);
        } else {
          console.error("There has been an error.");
        }
      })
      .finally(() => {
        setProductsLoading(false);
      });
  };

  const formatStockData = (warehouses) => {
    const stockData = [];

    warehouses.forEach((warehouse) => {
      const currentWarehouseName = Object.keys(warehouse)[0]; // Get warehouse name
      const products =
        warehouse[currentWarehouseName].remaining_stock_for_all_products;

      for (const productId in products) {
        const product = products[productId];

        if (currentWarehouseName === warehouseName) {
          // Filter products by warehouse name
          const newProduct = {
            product_id: product.product_id,
            product_name: product.product_name,
            remaining_stock: product.remaining_stock,
          };
          stockData.push(newProduct);
        }
      }
    });

    return stockData; // Return only filtered stock data
  };

  const stockData = formatStockData(stock); // Get filtered stock data
  const filteredStockData = stockData.filter(
    (item) => item.remaining_stock > 0
  );

  useEffect(() => {
    const token = localStorage.getItem("token");
    if (!token) {
      console.error("No token found in localStorage.");
      return;
    }

    setLoading(true);
    // Fetch products
    ProductsApi.GetProductsPrices(token)
      .then((response) => {
        setProducts(response.data);
      })
      .catch((error) => {
        console.error("Error fetching products:", error);
      });

    // Fetch customers
    CustomersApi.GetCustomersoExtract(token)
      .then((response) => {
        setCustomers(response.data);
      })
      .catch((error) => {
        console.error("Error fetching customers:", error);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  useEffect(() => {
    calculateTotalPrice();
    // eslint-disable-next-line
  }, [orderInfo.order_details]);

  const calculateTotalPrice = () => {
    let total = 0;
    const updatedOrderDetails = orderInfo.order_details.map((detail) => {
      const product = products.find((p) => p.id === detail.product_id.value);
      const quantity = Number(detail.quantity);

      if (product && quantity > 0) {
        const productTotalPrice = product.total_price * quantity;
        total += productTotalPrice;
        return { ...detail, total_price: productTotalPrice };
      }

      return detail;
    });

    setOrderInfo((prev) => ({
      ...prev,
      order_details: updatedOrderDetails,
      total_price: total.toFixed(3),
    }));
  };

  const handleDetailChange = (index, newInfo) => {
    const updatedOrderDetails = orderInfo.order_details.map((detail, i) =>
      i === index ? { ...detail, ...newInfo } : detail
    );
    setOrderInfo({ ...orderInfo, order_details: updatedOrderDetails });

    const errorKeys = Object.keys(newInfo).map((key) => `${key}_${index}`);
    if (errorKeys.some((key) => errors[key])) {
      const newErrors = { ...errors };
      errorKeys.forEach((key) => delete newErrors[key]);
      setErrors(newErrors);
    }
  };

  const getQuantityForProduct = (productId) => {
    const product = filteredStockData.find(
      (item) => item.product_id === productId
    );

    if (product) {
      return product.remaining_stock;
    }

    return 0;
  };

  const handleSave = () => {
    const token = localStorage.getItem("token");
    if (!token) {
      console.error("No token found in localStorage.");
      return;
    }
    setLoadingSave(true);

    const payload = {
      ...orderInfo,
      doc_type: "order_command",
      customer_id: orderInfo.customer_id.value,
      delivery_method: orderInfo.delivery_method.value,
      payment_methode: orderInfo.payment_methode.value,
      order_details: orderInfo.order_details
        .filter((detail) => detail.product_id.value)
        .map((detail) => ({
          product_id: detail.product_id.value,
          quantity: Number(detail.quantity),
          total_price: detail.total_price,
        })),
    };

    OrdersApi.CreateDraftOrders(token, payload)
      .then((response) => {
        console.log("Commande créée avec succès:", response.data);
        navigate("/gestion-des-commandes/commandes-encours");
      })
      .catch((error) => {
        console.error(error.response?.data?.msg || error.message);
      })
      .finally(() => {
        setLoadingSave(false);
      });
  };

  const handleOpenDialog = () => {
    setDialogOpen(true);
  };

  const handleCloseDialog = () => {
    setDialogOpen(false);
  };

  const handleConfirmSave = () => {
    setIsConfirming(true);
    handleSave();
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <SoftBox
        display="flex"
        flexDirection="column"
        justifyContent="space-between"
        minHeight="85vh"
      >
        {loading ? (
          <SoftBox
            display="flex"
            justifyContent="center"
            alignItems="center"
            p={35}
          >
            <CircularProgress sx={{ color: "#1F51FF" }} />
          </SoftBox>
        ) : (
          <SoftBox py={3} ref={dropdownContainerRef}>
            <Grid container justifyContent="center" sx={{ height: "100%" }}>
              <Grid item xs={12} lg={8} xl={7}>
                <Card elevation={3}>
                  <CardContent>
                    <SoftTypography variant="h5" gutterBottom>
                      {t("Informations sur la commande")}
                    </SoftTypography>

                    {/* Section 1: Choose Customer */}
                    <Card variant="outlined" sx={{ mb: 3 }}>
                      <CardContent>
                        <SoftTypography
                          component="label"
                          variant="caption"
                          fontWeight="bold"
                          textTransform="capitalize"
                        >
                          {t("Sélectionner un client")}
                        </SoftTypography>
                        <SoftSelect
                          label={t("Client")}
                          placeholder={t("Sélectionner un client")}
                          value={orderInfo.customer_id}
                          onChange={(newValue) =>
                            setOrderInfo({
                              ...orderInfo,
                              customer_id: newValue,
                            })
                          }
                          options={customers?.map((customer) => ({
                            value: customer.id,
                            label: customer.username,
                          }))}
                          errorLabel={errors["customer_id"]}
                          menuPortalTarget={
                            i18n.language === "ar"
                              ? dropdownContainerRef.current
                              : document.body
                          }
                        />
                      </CardContent>
                    </Card>

                    {/* Section 2: Choose Product and Quantity */}
                    <Card variant="outlined" sx={{ mb: 3 }}>
                      <CardContent>
                        {orderInfo.order_details.map((detail, index) => (
                          <Grid
                            container
                            spacing={3}
                            key={index}
                            sx={{ mb: 2 }}
                          >
                            <Grid item xs={12} md={8}>
                              <SoftTypography
                                component="label"
                                variant="caption"
                                fontWeight="bold"
                                textTransform="capitalize"
                              >
                                {t("Produit")} {index + 1}
                              </SoftTypography>
                              <SoftSelect
                                label={`${t("Produit")} ${index + 1}`}
                                placeholder={t("Sélectionner un produit")}
                                value={detail.product_id}
                                onChange={(newValue) =>
                                  handleDetailChange(index, {
                                    product_id: newValue,
                                  })
                                }
                                options={
                                  productsLoading
                                    ? [
                                        {
                                          value: "",
                                          label: (
                                            <CircularProgress
                                              size={20}
                                              sx={{
                                                color: "#1F51FF",
                                                marginLeft: "65%",
                                              }}
                                            />
                                          ),
                                        },
                                      ]
                                    : filteredStockData.length > 0
                                    ? filteredStockData.map((product) => ({
                                        value: product.product_id,
                                        label: product.product_name,
                                      }))
                                    : [
                                        {
                                          value: "",
                                          label: `${t(
                                            "Aucun produit disponible"
                                          )}`,
                                        },
                                      ]
                                }
                                errorLabel={errors[`product_id_${index}`]}
                                menuPortalTarget={document.body}
                                styles={{
                                  menu: (provided) => ({
                                    ...provided,
                                    right:
                                      i18n.language === "ar"
                                        ? isLargeScreen
                                          ? rightPosition
                                          : "0"
                                        : "0",
                                  }),
                                }}
                              />
                            </Grid>
                            <Grid item xs={12} md={4}>
                              <FormField
                                type="number"
                                label={`${t("Quantité")} ${index + 1}`}
                                placeholder={
                                  getQuantityForProduct(
                                    detail.product_id.value
                                  ) || t("Valeur de commande")
                                }
                                value={detail.quantity}
                                onChange={(e) =>
                                  handleDetailChange(index, {
                                    quantity: e.target.value,
                                  })
                                }
                                error={errors[`quantity_${index}`]}
                              />
                            </Grid>
                          </Grid>
                        ))}
                        <SoftBox mt={2}>
                          <SoftButton
                            variant="outlined"
                            color="info"
                            onClick={() =>
                              setOrderInfo({
                                ...orderInfo,
                                order_details: [
                                  ...orderInfo.order_details,
                                  {
                                    product_id: "",
                                    quantity: "",
                                    total_price: 0,
                                  },
                                ],
                              })
                            }
                          >
                            {t("Ajouter un produit")}
                          </SoftButton>
                        </SoftBox>
                      </CardContent>
                    </Card>

                    {/* Section 3: Delivery Date, Delivery Mode, and Payment Mode */}
                    <Card variant="outlined">
                      <CardContent>
                        <Grid container spacing={3}>
                          <Grid item xs={12} md={4}>
                            <FormField
                              type="date"
                              label={t("Date de livraison")}
                              value={orderInfo.delivery_date}
                              onChange={(e) =>
                                setOrderInfo({
                                  ...orderInfo,
                                  delivery_date: e.target.value,
                                })
                              }
                              error={errors["delivery_date"]}
                            />
                          </Grid>
                          <Grid item xs={12} md={4}>
                            <SoftTypography
                              component="label"
                              variant="caption"
                              fontWeight="bold"
                              textTransform="capitalize"
                            >
                              {t("Mode de livraison")}
                            </SoftTypography>
                            <SoftSelect
                              label={t("Mode de livraison")}
                              placeholder={t("Mode de livraison")}
                              value={orderInfo.delivery_method}
                              onChange={(newValue) =>
                                setOrderInfo({
                                  ...orderInfo,
                                  delivery_method: newValue,
                                })
                              }
                              options={[
                                {
                                  value: "transport",
                                  label: `${t("Transporteur")}`,
                                },
                                {
                                  value: "warehouse",
                                  label: `${t("Entrepôt")}`,
                                },
                              ]}
                              errorLabel={errors["delivery_method"]}
                              menuPortalTarget={dropdownContainerRef.current}
                            />
                          </Grid>
                          <Grid item xs={12} md={4}>
                            <SoftTypography
                              component="label"
                              variant="caption"
                              fontWeight="bold"
                              textTransform="capitalize"
                            >
                              {t("Mode de paiement")}
                            </SoftTypography>
                            <SoftSelect
                              label={t("Mode de paiement")}
                              placeholder={t("Mode de paiement")}
                              value={orderInfo.payment_methode}
                              onChange={(newValue) =>
                                setOrderInfo({
                                  ...orderInfo,
                                  payment_methode: newValue,
                                })
                              }
                              options={[
                                { value: "cash", label: "Cash" },
                                { value: "virement", label: "Virement" },
                                { value: "cheque", label: "Chèque" },
                              ]}
                              errorLabel={errors["payment_methode"]}
                              menuPortalTarget={dropdownContainerRef.current}
                              styles={{
                                menu: (provided) => ({
                                  ...provided,
                                  right: paymentRightPosition,
                                }),
                              }}
                            />
                          </Grid>
                        </Grid>
                      </CardContent>
                    </Card>
                  </CardContent>
                  <CardContent>
                    <SoftBox
                      sx={{ display: "flex", justifyContent: "space-between" }}
                    >
                      <SoftTypography variant="h6">
                        {t("Prix total")}: {orderInfo.total_price} TND
                      </SoftTypography>
                      <SoftButton
                        variant="contained"
                        color="info"
                        onClick={handleOpenDialog}
                        disabled={isFormEmpty()}
                      >
                        {`${t("Enregistrer")}`}
                      </SoftButton>
                    </SoftBox>
                  </CardContent>
                </Card>
              </Grid>
            </Grid>
            {/* Confirmation Dialog */}
            <Dialog
              open={dialogOpen}
              onClose={handleCloseDialog}
              aria-labelledby="confirm-dialog-title"
              aria-describedby="confirm-dialog-description"
            >
              <DialogTitle id="confirm-dialog-title">
                {t("Confirmer la création")}
              </DialogTitle>
              <DialogContent>
                <DialogContentText id="confirm-dialog-description">
                  {t("Voulez-vous vraiment créer cette commande ?")}
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button onClick={handleCloseDialog} color="primary">
                  {t("Non")}
                </Button>
                <Button onClick={handleConfirmSave} color="primary">
                  {isConfirming ? (
                    <CircularProgress size={24} sx={{ color: "#1F51FF" }} />
                  ) : (
                    t("Oui")
                  )}
                </Button>
              </DialogActions>
            </Dialog>
          </SoftBox>
        )}
        <Footer />
      </SoftBox>
    </DashboardLayout>
  );
}

export default NewOrder;
