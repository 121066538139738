import { useState, useEffect, useCallback } from "react";
import Grid from "@mui/material/Grid";
import Switch from "@mui/material/Switch";
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftEditor from "components/SoftEditor";
import SoftSelect from "components/SoftSelect";
import CategoriesApi from "api/categories";
import FormField from "../FormField";
import { useTranslation } from "react-i18next";

function ProductInfo({ onChange, defaultValues = {}, onValidation }) {
  const { t } = useTranslation();

  const [productName, setProductName] = useState(defaultValues.product_name);
  const [referenceCode, setReferenceCode] = useState(
    defaultValues.reference_code
  );
  const [description, setDescription] = useState(defaultValues.description);
  const [category, setCategory] = useState(defaultValues?.category_id);
  const [status, setStatus] = useState(defaultValues?.status === "active");
  const [isTaxed, setIsTaxed] = useState(!!defaultValues?.is_taxed);
  const [categories, setCategories] = useState([]);
  const [errors, setErrors] = useState({});

  useEffect(() => {
    setIsTaxed(!!defaultValues?.is_taxed);
    setProductName(defaultValues?.product_name);
    setReferenceCode(defaultValues?.reference_code);
    setDescription(defaultValues?.description);
    setCategory(defaultValues?.category_id);
    setStatus(defaultValues?.status);
  }, [defaultValues]);

  useEffect(() => {
    handleGetCategories();
    // eslint-disable-next-line
  }, []);

  console.log(defaultValues);
  const handleGetCategories = useCallback(() => {
    const token = localStorage.getItem("token");
    if (!token) {
      console.error("Aucun jeton trouvé dans localStorage.");
      return;
    }

    CategoriesApi.GetCategories(token)
      .then((response) => {
        setCategories(response.data.results);
      })
      .catch((error) => {
        console.error(error.response?.data?.msg || error.message);
      });
  }, []);

  const validateFields = useCallback(() => {
    const newErrors = {};

    if (!productName?.trim()) {
      newErrors.productName = t("Le nom du produit est obligatoire.");
    }
    if (!referenceCode?.trim()) {
      newErrors.referenceCode = t("Le code de référence est obligatoire.");
    } else if (referenceCode?.trim().length < 8) {
      newErrors.referenceCode = t(
        "Le code de référence doit contenir au moins 8 caractères."
      );
    }
    if (!category) {
      newErrors.category = t("La catégorie est obligatoire.");
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  }, [productName, referenceCode, category, t]);

  const handleInputChange = useCallback(
    (field, value) => {
      // Update the respective state based on field
      switch (field) {
        case "productName":
          setProductName(value);
          break;
        case "referenceCode":
          setReferenceCode(value);
          break;
        case "description":
          setDescription(value);
          break;
        case "category":
          setCategory(value);
          break;
        default:
          break;
      }
      // Trigger validation after each change
      validateFields();
    },
    [validateFields]
  );

  useEffect(() => {
    if (onValidation) {
      onValidation(validateFields());
    }
    // eslint-disable-next-line
  }, [errors, onValidation]);

  useEffect(() => {
    // Trigger onChange with all form fields when any field changes
    onChange({
      productName,
      referenceCode,
      description,
      category,
      status: status ? "active" : "not active",
      isTaxed,
    });
  }, [
    productName,
    referenceCode,
    description,
    category,
    status,
    isTaxed,
    onChange,
  ]);

  return (
    <SoftBox>
      <SoftTypography variant="h5">
        {t("Informations sur le produit")}
      </SoftTypography>
      <SoftBox mt={3}>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>
            <FormField
              type="text"
              label={t("Nom du produit")}
              placeholder={t("Nom du produit")}
              value={productName || defaultValues?.product_name}
              onChange={(e) => handleInputChange("productName", e.target.value)}
              error={!!errors.productName}
            />
            {errors.productName && (
              <SoftTypography component="label" variant="caption" color="error">
                {errors.productName}
              </SoftTypography>
            )}
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormField
              type="text"
              label={t("Code de référence")}
              placeholder="ex. 6191122554421"
              value={referenceCode || defaultValues?.reference_code}
              onChange={(e) =>
                handleInputChange("referenceCode", e.target.value)
              }
              error={!!errors.referenceCode}
            />
            {errors.referenceCode && (
              <SoftTypography component="label" variant="caption" color="error">
                {errors.referenceCode}
              </SoftTypography>
            )}
          </Grid>
        </Grid>
      </SoftBox>
      <SoftBox mt={2}>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>
            <SoftBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
              <SoftTypography
                component="label"
                variant="caption"
                fontWeight="bold"
              >
                {t("Description")} ({t("optionnel")})
              </SoftTypography>
            </SoftBox>
            <SoftEditor
              value={description || defaultValues?.description}
              onChange={(value) => handleInputChange("description", value)}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <SoftBox mb={3}>
              <SoftTypography
                component="label"
                variant="caption"
                fontWeight="bold"
              >
                {t("Catégorie")}
              </SoftTypography>
              <SoftSelect
                value={category || defaultValues.category_id}
                onChange={(value) => handleInputChange("category", value)}
                options={categories.map((cat) => ({
                  value: cat.id.toString(),
                  label: cat.category_name,
                }))}
              />
              {errors.category && (
                <SoftTypography
                  component="label"
                  variant="caption"
                  color="error"
                >
                  {errors.category}
                </SoftTypography>
              )}
            </SoftBox>
            <SoftBox mt={2}>
              <Grid
                item
                xs={12}
                sm={8}
                display="flex"
                justifyContent="space-between"
              >
                <SoftBox display="flex" alignItems="center" mb={2}>
                  <Switch
                    checked={status}
                    onChange={(e) => setStatus(e.target.checked)}
                  />
                  <SoftTypography variant="caption" fontWeight="bold" ml={1}>
                    {status ? t("Actif") : t("Inactif")}
                  </SoftTypography>
                </SoftBox>
                <SoftBox display="flex" alignItems="center" mb={2}>
                  <Switch
                    checked={isTaxed}
                    onChange={(e) => setIsTaxed(e.target.checked)}
                  />
                  <SoftTypography variant="caption" fontWeight="bold" ml={1}>
                    {isTaxed ? t("Taxé") : t("Non Taxé")}
                  </SoftTypography>
                </SoftBox>
              </Grid>
            </SoftBox>
          </Grid>
        </Grid>
      </SoftBox>
    </SoftBox>
  );
}

export default ProductInfo;
