import React, { useEffect, useState, useMemo } from "react";
import SoftBox from "components/SoftBox";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import { CircularProgress, Pagination, Stack } from "@mui/material";
import Footer from "examples/Footer";
import ActionCell from "./components/ActionCell";
import BalanceApi from "api/balance";
import DataTable from "examples/Tables/DataTable";
import { useTranslation } from "react-i18next";

function CompteClient() {
  const [orders, setOrders] = useState([]);
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const itemsPerPage = 10;

  const { t } = useTranslation();

  const handleGetOrders = (page) => {
    const token = localStorage.getItem("token");

    if (!token) {
      console.error("No token found in localStorage.");
      setLoading(false);
      return;
    }

    BalanceApi.GetClientHistory(token, page)
      .then((response) => {
        if (response.data && response.data.results) {
          setOrders(response.data.results);
          const totalCount = response.data.count;
          setTotalPages(Math.ceil(totalCount / itemsPerPage));
        } else {
          console.warn("No data found:", response.data.msg);
        }
      })
      .catch((error) => {
        if (error.response) {
          console.error("Error response data:", error.response.data.msg);
          console.error("Full error response:", error.response);
        } else {
          console.error("There has been an error.");
        }
      })
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    handleGetOrders(page); // Fetch data for the current page
  }, [page]);

  const formatPrice = (value) => {
    const price = parseFloat(value);
    return !isNaN(price) ? price.toFixed(3) : "0.000";
  };

  const calculateTotals = (data) => {
    const totals = data.reduce(
      (acc, order) => {
        acc.totalOrderPrice += parseFloat(order.total_order_price) || 0;
        acc.totalPaidAmount += parseFloat(order.total_paid_amount) || 0;
        acc.totalRestToPay += parseFloat(order.total_rest_to_pay) || 0;
        return acc;
      },
      { totalOrderPrice: 0, totalPaidAmount: 0, totalRestToPay: 0 }
    );

    return {
      totalOrderPrice: `${totals.totalOrderPrice.toFixed(3)} TND`,
      totalPaidAmount: `${totals.totalPaidAmount.toFixed(3)} TND`,
      totalRestToPay: `${totals.totalRestToPay.toFixed(3)} TND`,
    };
  };

  const rows = useMemo(() => {
    const totals = calculateTotals(orders);

    return [
      ...orders.map((order) => ({
        code: order.client_code || "-",
        user_id: order.user_id,
        client_name: order.user_name,
        total_order_price: `${formatPrice(order.total_order_price)} TND`,
        total_paid_amount: `${formatPrice(order.total_paid_amount)} TND`,
        total_rest_to_pay: `${formatPrice(order.total_rest_to_pay)} TND`,
        action: <ActionCell client_id={order.user_id} />,
      })),
      {
        code: "",
        client_name: <strong>{t("Total Mouvements")}</strong>,
        total_order_price: <strong>{totals.totalOrderPrice}</strong>,
        total_paid_amount: <strong>{totals.totalPaidAmount}</strong>,
        total_rest_to_pay: "",
        action: "",
      },
      {
        code: "",
        client_name: "",
        total_order_price: <strong>{t("Solde")}</strong>,
        total_paid_amount: "",
        total_rest_to_pay: <strong>{totals.totalRestToPay}</strong>,
        action: "",
      },
    ];
  }, [orders, t]);

  const columns = [
    { Header: `${t("Code")}`, accessor: "code", width: "10%" },
    { Header: `${t("Client")}`, accessor: "client_name", width: "20%" },
    {
      Header: `${t("Montant Débit")}`,
      accessor: "total_order_price",
      width: "20%",
    },
    {
      Header: `${t("Solde Débit")}`,
      accessor: "total_paid_amount",
      width: "20%",
    },
    {
      Header: `${t("Solde Crédit")}`,
      accessor: "total_rest_to_pay",
      width: "20%",
    },
    { Header: t("action"), accessor: "action", width: "20%" },
  ];

  const handlePageChange = (event, value) => {
    setPage(value); // Update the current page
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <SoftBox
        display="flex"
        flexDirection="column"
        justifyContent="space-between"
        minHeight="85vh"
      >
        <SoftBox
          sx={{
            maxWidth: { xl: "70%" },
            width: "100%",
            margin: "auto",
            marginTop: "5%",
            marginBottom: "5%",
          }}
        >
          {loading ? (
            <SoftBox
              display="flex"
              justifyContent="center"
              alignItems="center"
              p={35}
            >
              <CircularProgress sx={{ color: "#1F51FF" }} />
            </SoftBox>
          ) : (
            <SoftBox sx={{ position: "relative" }}>
              <DataTable
                table={{ columns, rows }}
                entriesPerPage={false}
                canSearch
              />
              <Stack
                spacing={2}
                sx={{
                  mt: 2,
                  position: "absolute",
                  bottom: 8,
                  left: 100,
                  transform: "translateX(-50%)",
                }}
              >
                <Pagination
                  count={totalPages}
                  page={page}
                  onChange={handlePageChange}
                  color="secondary"
                />
              </Stack>
            </SoftBox>
          )}
        </SoftBox>
        <Footer />
      </SoftBox>
    </DashboardLayout>
  );
}

export default CompteClient;
