import React, { useState, useEffect } from "react";
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import { handleUpload } from "utils/ImageUploader";
import SoftButton from "components/SoftButton";
import { useTranslation } from "react-i18next";

function Media({ onChange, initialImages }) {
  const [idCardImage, setIdCardImage] = useState("");
  const [patentImage, setPatentImage] = useState("");
  const { t } = useTranslation();

  useEffect(() => {
    if (initialImages.id_card_image) {
      setIdCardImage(initialImages.id_card_image);
    }
    if (initialImages.patent_image) {
      setPatentImage(initialImages.patent_image);
    }
  }, [initialImages]);

  const handleImageUpload = async (event, imageType) => {
    const file = event.target.files[0];

    try {
      const imageUrl = await handleUpload(file);
      if (imageType === "id_card") {
        setIdCardImage(imageUrl);
        onChange({ id_card_image: imageUrl });
      } else if (imageType === "patent") {
        setPatentImage(imageUrl);
        onChange({ patent_image: imageUrl });
      }
    } catch (error) {
      console.error("Error uploading image:", error);
    }
  };

  return (
    <SoftBox>
      <SoftTypography variant="h5">Média</SoftTypography>
      <SoftBox p={1} display="flex">
        {/* ID Card Image */}
        <div style={{ marginRight: 20, flex: 1 }}>
          <SoftBox mb={1}>
            <SoftTypography
              component="label"
              variant="caption"
              fontWeight="bold"
            >
              {t("Image du ID Card")}
            </SoftTypography>
            {idCardImage && (
              <img
                src={idCardImage}
                alt="ID Card"
                style={{ width: "90%", height: "150px", marginTop: 10 }}
              />
            )}
            <label htmlFor="idCardInput">
              <SoftButton variant="gradient" color="info" component="span">
                {t("Changer l'image")}
              </SoftButton>
            </label>
            <input
              id="idCardInput"
              type="file"
              style={{ display: "none" }}
              onChange={(event) => handleImageUpload(event, "id_card")}
              accept="image/*"
            />
          </SoftBox>
        </div>

        {/* Patent Image */}
        <div style={{ flex: 1 }}>
          <SoftBox mb={1}>
            <SoftTypography
              component="label"
              variant="caption"
              fontWeight="bold"
            >
              {t("Image du Patent")}
            </SoftTypography>
            {patentImage && (
              <img
                src={patentImage}
                alt="Patent"
                style={{ width: "90%", height: "150px", marginTop: 10 }}
              />
            )}
            <label htmlFor="patentInput">
              <SoftButton variant="gradient" color="info" component="span">
                {t("Changer l'image")}
              </SoftButton>
            </label>
            <input
              id="patentInput"
              type="file"
              style={{ display: "none" }}
              onChange={(event) => handleImageUpload(event, "patent")}
              accept="image/*"
            />
          </SoftBox>
        </div>
      </SoftBox>
    </SoftBox>
  );
}

export default Media;
