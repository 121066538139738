import axios from "./index";

// ReportsApi class handles operations related to reports
class ReportsApi {
  /**
   * Fetches a list of reports based on the specified filter.
   * @param {string} token - The authentication token for the user.
   * @param {string} filter - The filter criteria to apply to the reports.
   * @returns {Promise} - The response containing the list of reports that match the filter criteria.
   */
  static GetReports = (token, filter) => {
    return axios.get(`${base}/reports/?filter=${filter}`, {
      headers: { Authorization: `Bearer ${token}` },
    });
  };
}

// Base URL for the reports-related API
let base = "dashboard";

export default ReportsApi;
