import React from "react";
import Icon from "@mui/material/Icon";
import Tooltip from "@mui/material/Tooltip";
import { Link } from "react-router-dom";
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import { useTranslation } from "react-i18next";

function ActionCell({ client_id }) {
  const { t } = useTranslation();

  return (
    <SoftBox display="flex" alignItems="center">
      <SoftTypography
        variant="body1"
        color="grey-400"
        sx={{ cursor: "pointer", lineHeight: 0, margin: "auto" }}
      >
        <Tooltip title={t("Aperçu de la commande")} placement="top">
          <Link
            to={`/gestion-des-ventes/historique-de-client/${client_id}`}
            style={{ color: "inherit" }}
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              width: "40px",
              height: "40px",
              borderRadius: "50%",
              transition: "background-color 0.3s ease",
              "&:hover": {
                backgroundColor: "rgba(0, 0, 0, 0.1)",
              },
            }}
          >
            <Icon>visibility</Icon>
          </Link>
        </Tooltip>
      </SoftTypography>
    </SoftBox>
  );
}

export default ActionCell;
