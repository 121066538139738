import React, { useState, useEffect } from "react";
import { GoogleMap, Marker } from "@react-google-maps/api";
import Grid from "@mui/material/Grid";
import SoftBox from "components/SoftBox";

const containerStyle = {
  width: "100%",
  height: "400px",
};

function GoogleMaps({ onLocationChange, selectedLocation }) {
  const [pressedLocation, setPressedLocation] = useState(null);
  const [mapCenter, setMapCenter] = useState({
    lat: 36.8065,
    lng: 10.1815,
  });

  useEffect(() => {
    if (selectedLocation) {
      setMapCenter(selectedLocation);
    }
  }, [selectedLocation]);

  const handleMapClick = (event) => {
    const lat = event.latLng.lat();
    const lng = event.latLng.lng();
    setPressedLocation({ lat, lng });
    getAddressFromLatLng(lat, lng, (address) => {
      onLocationChange({
        latitude: lat,
        longitude: lng,
        address_details: address,
      });
    });
  };

  const handleMarkerDragEnd = (event) => {
    const lat = event.latLng.lat();
    const lng = event.latLng.lng();
    setPressedLocation({ lat, lng });
    getAddressFromLatLng(lat, lng, (address) => {
      onLocationChange({
        latitude: lat,
        longitude: lng,
        address_details: address,
      });
    });
  };

  const getAddressFromLatLng = (lat, lng, callback) => {
    const geocoder = new window.google.maps.Geocoder();
    const latlng = { lat: lat, lng: lng };

    geocoder.geocode({ location: latlng }, (results, status) => {
      if (status === "OK") {
        if (results[0]) {
          callback(results[0].formatted_address);
        } else {
          console.error("No results found");
        }
      } else {
        console.error("Geocoder failed due to: " + status);
      }
    });
  };

  return (
    <SoftBox>
      <Grid container spacing={3} mt={2}>
        <Grid item xs={12}>
          <GoogleMap
            mapContainerStyle={containerStyle}
            center={mapCenter}
            zoom={15}
            onClick={handleMapClick}
          >
            {pressedLocation && (
              <Marker
                position={{
                  lat: pressedLocation.lat,
                  lng: pressedLocation.lng,
                }}
                draggable={true}
                onDragEnd={handleMarkerDragEnd}
              />
            )}
            {selectedLocation && (
              <Marker
                position={{
                  lat: selectedLocation.lat,
                  lng: selectedLocation.lng,
                }}
              />
            )}
          </GoogleMap>
        </Grid>
      </Grid>
    </SoftBox>
  );
}

export default GoogleMaps;
