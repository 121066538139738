// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Icon from "@mui/material/Icon";

// Soft UI Dashboard PRO React components
import SoftTypography from "components/SoftTypography";
import SoftButton from "components/SoftButton";

function StatusCell({ icon, color, status }) {
  return (
    <SoftButton variant="outlined" color={color} circular sx={{ width: 140 }}>
      <Icon sx={{ fontWeight: "bold" }}>{icon}</Icon>
      <SoftTypography
        variant="caption"
        fontWeight="medium"
        color="text"
        sx={{ lineHeight: 0, marginLeft: 1 }}
      >
        {status}
      </SoftTypography>
    </SoftButton>
  );
}

// Typechecking props for the StatusCell
StatusCell.propTypes = {
  icon: PropTypes.string.isRequired,
  color: PropTypes.string.isRequired,
  status: PropTypes.string.isRequired,
};

export default StatusCell;
