import React from "react";
import {
  MenuItem,
  Checkbox,
  ListItemText,
  FormControl,
  InputLabel,
  Select,
  OutlinedInput,
  InputAdornment,
  IconButton,
} from "@mui/material";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";

// Constants to define the height and padding of the menu items
const ITEM_HEIGHT = 36;
const ITEM_PADDING_TOP = 4;

// Menu properties for dropdown styling
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP, // Maximum height for the dropdown menu
      width: 250, // Width of the dropdown menu
    },
  },
};

/**
 * MultiSelect component allows users to select multiple options from a dropdown list.
 *
 * @param {Object} props - Component properties
 * @param {Array} props.options - List of options to display in the dropdown. Each option should have a 'value' and 'label'.
 * @param {Array} props.value - Currently selected values, managed by the parent component.
 * @param {Function} props.onChange - Callback function to handle changes in selected values.
 * @param {string} props.label - Label for the dropdown, displayed above the select input.
 * @param {string} props.placeholder - Placeholder text displayed when no options are selected.
 * @returns {JSX.Element} - The rendered MultiSelect component.
 */
const MultiSelect = ({ options, value, onChange, label, placeholder }) => {
  /**
   * Handles the change event for the select component.
   *
   * @param {Object} event - The change event object from the select input.
   */
  const handleChange = (event) => {
    const { value } = event.target;
    // Converts the value to an array if it is a string, otherwise uses the array directly
    onChange(typeof value === "string" ? value.split(",") : value);
  };

  return (
    <FormControl fullWidth variant="outlined">
      <InputLabel>{label}</InputLabel>
      <Select
        multiple
        value={value}
        onChange={handleChange}
        input={
          <OutlinedInput
            label={label}
            endAdornment={
              // Dropdown arrow icon at the end of the input
              <InputAdornment position="end">
                <IconButton edge="end">
                  <ArrowDropDownIcon />
                </IconButton>
              </InputAdornment>
            }
          />
        }
        renderValue={(selected) => (
          <div
            style={{
              width: "190px", // Sets the width of the selected values display
              overflow: "hidden", // Hides overflowed text
              textOverflow: "ellipsis", // Adds ellipsis to overflowed text
              fontSize: "0.875rem", // Sets the font size
            }}
          >
            {selected.length === 0 ? (
              // Display placeholder if no options are selected
              <span style={{ color: "rgba(0, 0, 0, 0.6)" }}>{placeholder}</span>
            ) : (
              selected.map((val) => (
                <span key={val} style={{ marginRight: 8 }}>
                  {options.find((option) => option.value === val)?.label || val}
                </span>
              ))
            )}
          </div>
        )}
        MenuProps={MenuProps} // Applies the menu properties defined above
        displayEmpty
      >
        {options.map((option) => (
          <MenuItem
            key={option.value}
            value={option.value}
            style={{ fontSize: "0.875rem", padding: "4px 8px" }} // Styles for each menu item
          >
            <Checkbox
              checked={value.indexOf(option.value) > -1} // Checks if the option is selected
              style={{ padding: 4 }} // Padding for the checkbox
            />
            <ListItemText
              secondary={option.label} // Displays the option label
              style={{ fontSize: "0.75rem" }} // Font size for the label
            />
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default MultiSelect;
