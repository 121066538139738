import axios from "./index";

// BalanceApi class handles fetching client history and balance data
class BalanceApi {
  /**
   * Fetches the client's transaction history by sending a GET request.
   * The request includes an Authorization header with the Bearer token.
   * @param {string} token - The authentication token for the client.
   * @returns {Promise} - The response containing the client's transaction history.
   */
  static GetClientHistory = (token, page) => {
    return axios.get(`${base}/client_history?p=${page}&&page_size=10`, {
      headers: { Authorization: `Bearer ${token}` },
    });
  };

  static GetHistoryByClientID = (token, id) => {
    return axios.get(`${base}/client_history/${id}`, {
      headers: { Authorization: `Bearer ${token}` },
    });
  };

  /**
   * Fetches the client's balance details by sending a GET request.
   * The request includes an Authorization header with the Bearer token.
   * @param {string} token - The authentication token for the client.
   * @returns {Promise} - The response containing the client's balance information.
   */
  static GetClientBalance = (token, page) => {
    return axios.get(`${base}/client_balances?p=${page}&&page_size=10`, {
      headers: { Authorization: `Bearer ${token}` },
    });
  };
}

// Base URL for the client account-related API
let base = "accounts";

export default BalanceApi;
