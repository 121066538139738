import checkout from "./form";

const {
  formField: { firstName, lastName, company, email, password, password2, city },
} = checkout;

const initialValues = {
  [firstName]: "",
  [lastName]: "",
  [company]: "",
  [email]: "",
  [password]: "",
  [password2]: "",
  [city]: "",
};

export default initialValues;
