import React, { useState, useRef } from "react";
import { Button, Menu, MenuItem, Box, IconButton } from "@mui/material";
import { useNavigate } from "react-router-dom";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { useSoftUIController } from "context";
import { useTranslation } from "react-i18next";
import MenuIcon from "@mui/icons-material/Menu";

const Navbar = () => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [hoveredItem, setHoveredItem] = useState(null);
  const [dropdownVisible, setDropdownVisible] = useState(false);
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false); // State for mobile menu visibility
  const dropdownRef = useRef(null);
  const navigate = useNavigate();
  const [controller] = useSoftUIController();
  const { sidenavColor } = controller;
  const { t } = useTranslation();

  const handleMenuOpen = (event, item) => {
    setAnchorEl(event.currentTarget);
    setHoveredItem(item);
    setDropdownVisible(true);
  };

  const handleMenuClose = () => {
    setDropdownVisible(false);
    setAnchorEl(null);
    setHoveredItem(null);
  };

  const handleMouseEnter = (event, item) => {
    handleMenuOpen(event, item);
  };

  const handleMouseLeaveButton = () => {
    setTimeout(() => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(document.activeElement)
      ) {
        handleMenuClose();
      }
    }, 200);
  };

  const handleMouseLeaveMenu = () => {
    handleMenuClose();
  };

  const handleNavigation = (route) => {
    navigate(route);
    handleMenuClose();
  };

  const toggleMobileMenu = () => {
    setMobileMenuOpen(!mobileMenuOpen);
  };

  const menuItems = [
    {
      label: `${t("Company")}`,
      dropdown: [
        {
          label: `${t("Informations sur l'entreprise")}`,
          route: "/entreprise/informations-entreprise",
        },
        {
          label: `${t("Nouveau entrepôt")}`,
          route: "/entreprise/nouveau-entrepot",
        },
        {
          label: `${t("Informations sur les entrepôts")}`,
          route: "/entreprise/informations-entrepots",
        },
      ],
    },
    {
      label: `${t("Rapports")}`,
      dropdown: [
        {
          label: `${t("Rapports générales")}`,
          route: "/rapports/rapports-generales",
        },
      ],
    },

    {
      label: `${t("Statistiques")}`,
      dropdown: [
        {
          label: `${t("Statistiques générales")}`,
          route: "/statistiques/statistiques-generales",
        },
      ],
    },
    {
      label: `${t("Paniers ouverts")}`,
      dropdown: [
        {
          label: `${t("Panier d'achat en cours")}`,
          route: "/paniers-ouverts/paniers-en-cours",
        },
      ],
    },
  ];

  const colorMap = {
    primary: "#cb0c9f",
    dark: "#262B49",
    success: "#82d616",
    warning: "#F66437",
    error: "#ea0606",
    info: "#217cff",
  };

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        flexWrap: "wrap",
        py: 1.5,
        px: { xs: 1, md: 4 }, // Add responsive padding
        borderRadius: "10px",
        backgroundColor: colorMap[sidenavColor],
        mx: { xs: 0, md: -14 },
        mt: { xs: 0, md: -2 },
      }}
    >
      {/* Mobile menu icon */}
      <IconButton
        sx={{ display: { xs: "block", md: "none" }, color: "#fff" }}
        onClick={toggleMobileMenu}
      >
        <MenuIcon />
      </IconButton>

      {/* Menu items - Desktop version */}
      <Box
        sx={{
          display: { xs: "none", md: "flex" },
          width: "100%",
          justifyContent: "space-around",
          flexWrap: "wrap",
        }}
      >
        {menuItems.map((item) => (
          <Button
            key={item.label}
            onMouseEnter={(e) => handleMouseEnter(e, item.label)}
            onMouseLeave={handleMouseLeaveButton}
            sx={{
              margin:
                window.innerWidth < 770
                  ? "4px"
                  : window.innerWidth < 1000
                  ? "0px"
                  : window.innerWidth < 1300
                  ? "0px"
                  : window.innerWidth < 1500
                  ? "4px"
                  : "3px",

              fontWeight: "bold",
              fontSize:
                window.innerWidth < 770
                  ? "10px"
                  : window.innerWidth < 1000
                  ? "11px"
                  : window.innerWidth < 1300
                  ? "12px"
                  : window.innerWidth < 1500
                  ? "14px"
                  : "16px",

              display: "flex",
              alignItems: "center",
              color: "#fff",
            }}
          >
            {item.label}
            {item.dropdown.length > 0 && <ArrowDropDownIcon />}
          </Button>
        ))}
      </Box>

      {/* Mobile menu - Dropdown when mobileMenuOpen is true */}
      <Box
        sx={{
          display: { xs: mobileMenuOpen ? "block" : "none", md: "none" },
          position: "absolute",
          top: "60px",
          left: 0,
          right: 0,
          backgroundColor: colorMap[sidenavColor],
          zIndex: 9999,
          padding: "10px",
          borderRadius: "5px",
        }}
      >
        {menuItems.map((item) =>
          item.dropdown.length > 0 ? (
            <Box key={item.label}>
              <Button
                sx={{
                  width: "100%",
                  color: "#fff",
                  justifyContent: "flex-start",
                  padding: "10px",
                  fontSize: "14px",
                }}
                onClick={() => handleNavigation(item.dropdown[0].route)} // Default route click
              >
                {item.label}
              </Button>
              {item.dropdown.map((dropdownItem) => (
                <MenuItem
                  key={dropdownItem.label}
                  onClick={() => handleNavigation(dropdownItem.route)}
                  sx={{
                    paddingLeft: "30px",
                    color: "#fff",
                    fontSize: "12px",
                  }}
                >
                  {dropdownItem.label}
                </MenuItem>
              ))}
            </Box>
          ) : null
        )}
      </Box>

      {/* Dropdown Menus for desktop */}
      {menuItems.map((item) =>
        item.dropdown.length > 0 ? (
          <Menu
            key={item.label}
            anchorEl={anchorEl}
            open={dropdownVisible && hoveredItem === item.label}
            onClose={handleMenuClose}
            sx={{ zIndex: 9999999 }}
            ref={dropdownRef}
            MenuListProps={{
              onMouseLeave: handleMouseLeaveMenu,
            }}
          >
            {item.dropdown.map((dropdownItem) => (
              <MenuItem
                key={dropdownItem.label}
                onClick={() => handleNavigation(dropdownItem.route)}
              >
                {dropdownItem.label}
              </MenuItem>
            ))}
          </Menu>
        ) : null
      )}
    </Box>
  );
};

export default Navbar;
