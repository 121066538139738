import axios from "./index";

class ManagersApi {
  static GetManagersList = (token) => {
    return axios.get(`${base}/warehouse_managers`, {
      headers: { Authorization: `Bearer ${token}` },
    });
  };

  static CreateManager = (token, payload) => {
    return axios.post(`${base}/warehouse_managers/`, payload, {
      headers: { Authorization: `Bearer ${token}` },
    });
  };
}

let base = "accounts";

export default ManagersApi;
